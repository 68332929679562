export interface AnalysisTemplate {
  template_id: string;
  name: string;
  summary: string;
  analysis_type: string;
  sql: string;
  used_tables: string;
  use_cases: string;
  page_url?: string;
  relevance_score?: number;
  reason?: string;
}

export interface AnalysisReport {
  report_id: string;
  name: string;
  purpose: string;
  conclusion: string;
  key_findings: string;
  summary: string;
  analysis_type: string;
  client: string;
  date: string;
  page_url?: string;
  relevance_score?: number;
  reason?: string;
}

export interface SearchDebugInfo {
  prompt?: string;
  response?: string;
  error?: unknown;
  cost?: number;
}

export interface SearchHistoryItem {
  message: string;
  message_id: string;
  timestamp: string;
  analysis_options?: Record<string, any>;
}

export interface PromptContext {
  query: string;
  templates?: AnalysisTemplate[];
  reports?: AnalysisReport[];
  tables?: AnalyticsTable[];
}

export interface AnalyticsTable {
  name: string;
  japanese_name: string;
  summary: string;
  use_cases: string;
  column_descriptions: string;
}

export interface AnalysisDetail {
  analysis_id: string;
  name: string;
  purpose: string;
  phase_name: string;
  phase_description: string;
  target_metrics: string[];
  dimensions: string[];
  output_columns: {
    name: string;
    description: string;
  }[];
  aggregation?: string;
  filters?: string[];
  sample_data?: string;
  insights?: {
    result: string;
    interpretation: string;
    hypothesis: string;
  }[];
  next_analyses?: {
    analysis_id: string;
    reason: string;
  }[];
}

export interface AnalysisPlan {
  analyses: AnalysisDetail[];
}

export interface AnalysisVariable {
  key: string;
  name: string;
  value: string;
  message: string;
  message_id: string;
  created_at: string;
  updated_at: string;
}

export interface SaveAnalysisVariableRequest {
  message_id: string;
  name: string;
  value: string;
}

export interface DeleteAnalysisVariableRequest {
  message_id: string;
  name: string;
}

export interface GetAnalysisVariablesResponse {
  variables: AnalysisVariable[];
}

export interface GetAnalysisDetailsRequest {
  query: string;
  template_ids: string[];
  report_ids: string[];
  message_id: string;
  is_debug?: boolean;
}

export interface GetAnalysisDetailsResponse {
  analyses: AnalysisDetail[];
  debug: any;
}

export interface GenerateSqlFromAnalysisRequest {
  analysis_id: string;
  purpose: string;
  template_ids: string[];
  message_id: string;
  skip_cache?: boolean;
}

export interface FixSqlResponse {
  sql: string;
  explanation: string;
  prompt: string;
}

export interface VisualizationCode {
  code: string;
  library: 'recharts' | 'plotly';
  explanation: string;
  prompt?: string;
}

export interface GenerateVisualizationCodeRequest {
  message_id: string;
  analysis_id: string;
  sql: string;
  sql_result: {
    columns: string[];
    rows: Record<string, any>[];
  };
  purpose: string;
  target_metrics: string[];
  dimensions: string[];
  library: 'recharts' | 'plotly';
}

export interface FixVisualizationCodeRequest {
  message_id: string;
  analysis_id: string;
  code: string;
  library: 'recharts' | 'plotly';
  fix_request: string;
  sql_result: {
    columns: string[];
    rows: Record<string, any>[];
  };
  purpose: string;
  target_metrics: string[];
  dimensions: string[];
}

export enum LLMModel {
  DEEPSEEK_CHAT = 'deepseek-chat',
  CLAUDE_3_SONNET = 'claude-3-sonnet-20240229',
}

export interface AnalysisOptionItem {
  name: string;
  [key: string]: any;
}

export interface AnalysisOptions {
  分析タイプ: AnalysisOptionItem[];
  ページ: AnalysisOptionItem[];
}

export interface SelectedAnalysisOption {
  name: string;
  type?: string;
  children?: SelectedAnalysisOption[];
}

export interface SelectedAnalysisOptions {
  [category: string]: SelectedAnalysisOption[];
}
