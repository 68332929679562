import AssessmentIcon from '@mui/icons-material/Assessment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { PropertySection } from './PropertySection';
import type { AnalysisReport } from './types';

interface ReportCardProps {
  report: AnalysisReport;
}

export const ReportCard: React.FC<ReportCardProps> = ({ report }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center" gap={1}>
          <AssessmentIcon color="primary" />
          <Link
            href={report.page_url}
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            color="inherit"
          >
            <Typography variant="subtitle1">{report.name}</Typography>
          </Link>
          <Chip
            label={`関連度: ${report.relevance_score}%`}
            color="primary"
            variant="outlined"
            size="small"
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{
              backgroundColor: 'primary.50',
              p: 1.5,
              borderRadius: 1,
              fontStyle: 'italic',
            }}
          >
            {report.reason}
          </Typography>

          <Stack spacing={2}>
            <PropertySection label="目的" value={report.purpose} />
            <PropertySection label="結論" value={report.conclusion} />
            <PropertySection label="重要な発見" value={report.key_findings} />
            <PropertySection label="サマリ" value={report.summary} />
            <PropertySection label="分析タイプ" value={report.analysis_type} />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
