import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { RefreshCw, Search, User } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { ShelfTypeEnum } from '../../../enums/shelf-type.enum';
import { PageTypeEnum } from '../../../insightx-utils/enums/page-type.enum';
import { IShelf } from '../../../insightx-utils/types/shelf.type';
import { postRecommends } from '../../../services/shelf-previewer/post-recommends';
import { N1User, shelfService } from '../../../services/shelf-service';
import { useClient } from '../../../store/client.store';
import N1ShelfView from './N1ShelfView';

const N1Chart: React.FC = () => {
  const { client: clientState } = useClient();
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [currentUser, setCurrentUser] = useState<N1User | null>(null);
  const [userShelves, setUserShelves] = useState<IShelf[]>([]);
  const [isLoadingShelves, setIsLoadingShelves] = useState(false);

  // ランダムユーザーを取得
  const { data: randomUsers, isLoading: isLoadingUsers } = useQuery({
    queryKey: ['randomN1Users'],
    queryFn: () => shelfService.getRandomN1Users(),
  });

  // ユーザーのこだわりシェルフを取得
  const fetchUserShelves = async (user: N1User) => {
    setIsLoadingShelves(true);
    try {
      // デバッグログ
      console.log('Fetching shelves for user:', user);

      const shelfConfig = {
        enableCoVisForyou: true,
        enableColdStart: true,
        historyFilteredItemNum: 100,
        shelfOrders: [
          {
            shelfType: ShelfTypeEnum.perspective,
            shelfNum: 5,
            tagline2Template: '',
            maxItems: 50,
            maxConsecutiveShelfNum: 2,
            shelfOrders: [
              {
                shelfType: ShelfTypeEnum.search,
                shelfNum: 5,
                maxItems: 50,
                minItems: 4,
                tagline2Template: '',
                maxConsecutiveShelfNum: 2,
                shelfOrders: [],
              },
            ],
          },
        ],
      };

      const response = await postRecommends({
        clientId: clientState.selectedClient?.clientId,
        pageType: PageTypeEnum.Top,
        session: user.uu_id,
        shelfConfig,
      });

      // シェルフを設定（閲覧履歴は含めない）
      setUserShelves(response.shelves);
    } catch (error) {
      console.error('Error fetching user shelves:', error);
      console.error('Error details:', error.response?.data);
      console.error('Current user:', user);
    } finally {
      setIsLoadingShelves(false);
    }
  };

  // ユーザーを切り替える
  const handleNextUser = () => {
    if (randomUsers?.users) {
      const nextIndex = (currentUserIndex + 1) % randomUsers.users.length;
      setCurrentUserIndex(nextIndex);
      setCurrentUser(randomUsers.users[nextIndex]);
    }
  };

  // 初期ユーザーの設定
  useEffect(() => {
    if (randomUsers?.users && randomUsers.users.length > 0) {
      setCurrentUser(randomUsers.users[0]);
    }
  }, [randomUsers]);

  // ユーザーが変更されたらシェルフを取得
  useEffect(() => {
    if (currentUser) {
      fetchUserShelves(currentUser);
    }
  }, [currentUser]);

  if (isLoadingUsers) {
    return (
      <Box sx={{ width: '40%' }}>
        <Paper elevation={2} sx={{ borderRadius: 3, overflow: 'hidden', p: 3 }}>
          <CircularProgress />
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '40%' }}>
      <Paper elevation={2} sx={{ borderRadius: 3, overflow: 'hidden' }}>
        <Box sx={{ p: 3 }}>
          {/* タイトル部分 */}
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h6"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontSize: '1.2rem',
                fontWeight: 'bold',
                mb: 2,
              }}
            >
              <Search size={20} />
              こだわりニーズシェルフのN1可視化
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                mb: 3,
              }}
            >
              <Button
                variant="outlined"
                onClick={handleNextUser}
                disabled={isLoadingShelves}
                size="small"
                startIcon={<RefreshCw size={16} />}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontSize: '0.8rem',
                  px: 2,
                  py: 0.5,
                  borderColor: '#e0e0e0',
                  color: '#444',
                  fontWeight: 500,
                  '&:hover': {
                    borderColor: '#bdbdbd',
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                ユーザーを切り替える
              </Button>
            </Box>
          </Box>

          {/* ユーザー情報とPV商品 */}
          {currentUser && (
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 2,
                  mb: 3,
                }}
              >
                {/* ユーザーアイコンとID */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: 56,
                      height: 56,
                      borderRadius: '50%',
                      bgcolor: '#f5f5f5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 0.5,
                    }}
                  >
                    <User size={28} color="#666" />
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#666',
                      fontSize: '0.6rem',
                      fontFamily: 'monospace',
                      maxWidth: 80,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                    }}
                  >
                    {currentUser.uu_id}
                  </Typography>
                </Box>

                {/* 最近閲覧した商品 */}
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#666',
                      fontSize: '0.75rem',
                      display: 'block',
                      mb: 1,
                    }}
                  >
                    このユーザーが最近閲覧した5商品
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    {currentUser.pv_items?.pv_items
                      .slice(0, 5)
                      .map((item, index) => (
                        <img
                          key={`${item.aid}-${index}`}
                          src={item.image_url}
                          alt={item.item_title}
                          loading="lazy"
                          style={{
                            width: 58,
                            height: 64,
                            objectFit: 'cover',
                            borderRadius: 4,
                          }}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {/* シェルフ表示部分 */}
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: '#444',
                fontSize: '0.9rem',
                mb: 2,
                fontWeight: 500,
              }}
            >
              このユーザーに表示されるシェルフ
            </Typography>

            {/* iPhoneフレーム */}
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                maxWidth: 400,
                margin: '0 auto',
                borderWidth: '10px 10px 0',
                borderStyle: 'solid',
                borderColor: '#000',
                borderTopLeftRadius: '32px',
                borderTopRightRadius: '32px',
                overflow: 'hidden',
                bgcolor: '#000',
                '&::before': {
                  content: '""',
                  display: 'block',
                  width: '45%',
                  height: 20,
                  backgroundColor: '#000',
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  zIndex: 1,
                },
              }}
            >
              {isLoadingShelves ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 3,
                    bgcolor: '#fff',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    maxHeight: '600px',
                    overflowY: 'auto',
                    bgcolor: '#fff',
                    p: 1.5,
                    pt: 3,
                  }}
                >
                  {userShelves.map((shelf, index) => (
                    <N1ShelfView
                      key={`${shelf.shelfId}-${index}`}
                      shelf={shelf}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default N1Chart;
