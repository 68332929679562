import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';

interface SearchDebugInfoProps {
  prompt?: string;
  response?: string;
  error?: unknown | string;
}

export const SearchDebugInfo: React.FC<SearchDebugInfoProps> = ({
  prompt,
  response,
  error,
}) => {
  if (!prompt && !response && !error) return null;

  const errorMessage = error ? String(error) : '';

  return (
    <Accordion
      sx={{
        bgcolor: 'grey.50',
        '&.MuiAccordion-root': {
          '&:before': {
            display: 'none',
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: '40px !important',
          '& .MuiAccordionSummary-content': {
            margin: '8px 0 !important',
          },
        }}
      >
        <Typography variant="caption" color="text.secondary">
          デバッグ情報
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {prompt && (
            <Paper
              variant="outlined"
              sx={{ p: 2, bgcolor: 'background.paper' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontWeight: 500 }}
                >
                  Prompt
                </Typography>
                <Tooltip title="プロンプトをコピー">
                  <IconButton
                    size="small"
                    onClick={() => navigator.clipboard.writeText(prompt)}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography
                variant="body2"
                component="pre"
                sx={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  fontFamily: 'monospace',
                  m: 0,
                  fontSize: '0.75rem',
                }}
              >
                {prompt}
              </Typography>
            </Paper>
          )}

          {response && (
            <Paper
              variant="outlined"
              sx={{ p: 2, bgcolor: 'background.paper' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontWeight: 500 }}
                >
                  Response
                </Typography>
                <Tooltip title="レスポンスをコピー">
                  <IconButton
                    size="small"
                    onClick={() => navigator.clipboard.writeText(response)}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography
                variant="body2"
                component="pre"
                sx={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  fontFamily: 'monospace',
                  m: 0,
                  fontSize: '0.75rem',
                }}
              >
                {response}
              </Typography>
            </Paper>
          )}

          {error && (
            <Paper
              variant="outlined"
              sx={{ p: 2, bgcolor: 'error.light', color: 'error.contrastText' }}
            >
              <Typography
                variant="caption"
                sx={{ display: 'block', mb: 1, fontWeight: 500 }}
              >
                Error
              </Typography>
              <Typography
                variant="body2"
                component="pre"
                sx={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  fontFamily: 'monospace',
                  m: 0,
                  fontSize: '0.75rem',
                }}
              >
                {errorMessage}
              </Typography>
            </Paper>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
