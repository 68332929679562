import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  alpha,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  AnalysisOptionItem,
  AnalysisOptions,
  SelectedAnalysisOptions,
} from './types';

interface AnalysisOptionsSelectorProps {
  onOptionsChange: (options: SelectedAnalysisOptions) => void;
  selectedOptions?: SelectedAnalysisOptions;
}

// 分析オプションのデータ
const analysisOptionsData: AnalysisOptions = {
  分析タイプ: [
    {
      name: '事前分析',
      事前分析種類: [
        {
          name: '施策内容決め',
        },
        {
          name: '施策ページ決め',
          ステップ: [
            {
              name: '全体データ分析',
            },
            {
              name: 'ページごとの指標分析',
              指標: [
                {
                  name: 'SS数',
                },
                {
                  name: 'PV数',
                },
                {
                  name: '経由CVR',
                },
              ],
            },
            {
              name: 'ページ内分析',
              ページ内分析種類: [
                {
                  name: 'コンポーネント分析',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'AB分析',
      AB分析種類: [
        {
          name: '全体指標',
          指標: [
            {
              name: 'CVR',
            },
            {
              name: 'カート率',
            },
            {
              name: 'PDP率',
            },
          ],
        },
      ],
    },
    {
      name: 'アドホック分析',
      アドホック分析種類: [
        {
          name: '商品分析',
        },
        {
          name: 'ユーザー分析',
        },
        {
          name: 'ページ分析',
        },
        {
          name: 'シェルフ分析',
        },
        {
          name: 'シェルフ表示状況分析',
        },
      ],
    },
  ],
  ページ: [
    {
      name: 'トップ',
    },
    {
      name: '商品詳細',
    },
    {
      name: '検索一覧',
    },
    {
      name: 'カート一覧',
    },
  ],
};

interface FlatOption {
  value: string;
  label: string;
  level: number;
  isHeader?: boolean;
  category: string;
  path: string[];
  type?: string;
}

const AnalysisOptionsSelector: React.FC<AnalysisOptionsSelectorProps> = ({
  onOptionsChange,
  selectedOptions,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [expandedPaths, setExpandedPaths] = useState<Set<string>>(new Set());

  // オプションを再帰的にフラット化する関数
  const flattenOptions = (
    items: AnalysisOptionItem[],
    category: string,
    level: number = 0,
    parentPath: string[] = [],
    parentType?: string,
  ): FlatOption[] => {
    const result: FlatOption[] = [];

    items.forEach((item) => {
      const currentPath = [...parentPath, item.name];
      result.push({
        value: `${category}:${currentPath.join('/')}`,
        label: item.name,
        level,
        category,
        path: currentPath,
        type: parentType,
      });

      // 子要素を探索
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key]) && key !== 'name') {
          // サブヘッダーを追加
          result.push({
            value: `header:${category}:${key}:${currentPath.join('/')}`,
            label: key,
            level: level + 1,
            isHeader: true,
            category,
            path: currentPath,
            type: key,
          });
          // 子要素を再帰的に処理
          result.push(
            ...flattenOptions(item[key], category, level + 2, currentPath, key),
          );
        }
      });
    });

    return result;
  };

  // すべてのオプションをフラット化
  const allOptions = Object.entries(analysisOptionsData).flatMap(
    ([category, items]) => [
      {
        value: `header:${category}`,
        label: category,
        level: 0,
        isHeader: true,
        category,
        path: [],
      },
      ...flattenOptions(items, category),
    ],
  );

  // 選択が変更されたときのハンドラ
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    // ヘッダーは選択から除外
    const filteredValues = value.filter((v) => !v.startsWith('header:'));
    setSelectedValues(filteredValues);

    // 選択された項目のパスを展開
    const newExpandedPaths = new Set(expandedPaths);
    filteredValues.forEach((value) => {
      const option = allOptions.find((opt) => opt.value === value);
      if (option) {
        // 親パスを全て展開
        let currentPath = '';
        option.path.forEach((pathPart) => {
          currentPath = currentPath ? `${currentPath}/${pathPart}` : pathPart;
          newExpandedPaths.add(`${option.category}:${currentPath}`);
        });
      }
    });
    setExpandedPaths(newExpandedPaths);
  };

  // 項目がクリックされたときのハンドラ
  const handleItemClick = (option: FlatOption) => {
    const pathKey = option.value.split(':')[1]; // カテゴリを除いたパス部分
    const fullPath = `${option.category}:${pathKey}`;

    setExpandedPaths((prev) => {
      const newPaths = new Set(prev);
      if (newPaths.has(fullPath)) {
        newPaths.delete(fullPath);
      } else {
        newPaths.add(fullPath);
      }
      return newPaths;
    });
  };

  // パスが展開されているかチェック
  const isExpanded = (option: FlatOption): boolean => {
    const pathKey = option.value.split(':')[1]; // カテゴリを除いたパス部分
    return expandedPaths.has(`${option.category}:${pathKey}`);
  };

  // 項目が表示されるべきかチェック
  const shouldShowOption = (option: FlatOption): boolean => {
    if (option.level === 0) return true;

    // 親パスを構築
    const parentPath = option.path.slice(0, -1);
    const parentKey = `${option.category}:${parentPath.join('/')}`;

    return expandedPaths.has(parentKey);
  };

  // 選択された値から階層構造を構築する関数
  const buildHierarchy = (values: string[]): SelectedAnalysisOptions => {
    const result: SelectedAnalysisOptions = {};

    values.forEach((value) => {
      const option = allOptions.find((opt) => opt.value === value);
      if (!option) return;

      const { category, path, type } = option;
      if (!result[category]) {
        result[category] = [];
      }

      // パスに基づいて階層構造を構築
      let current = result[category];
      for (let i = 0; i < path.length; i++) {
        const name = path[i];
        let node = current.find((n) => n.name === name);

        if (!node) {
          node = { name };
          if (type) {
            node.type = type;
          }
          current.push(node);
        }

        if (i < path.length - 1) {
          if (!node.children) {
            node.children = [];
          }
          current = node.children;
        }
      }
    });

    return result;
  };

  // 選択されたオプションが変更されたときに初期化
  useEffect(() => {
    if (selectedOptions && Object.keys(selectedOptions).length > 0) {
      // 選択されたオプションから値の配列を生成
      const values = Object.entries(selectedOptions).flatMap(
        ([category, items]) => {
          const result: string[] = [];
          const processItem = (item: any, parentPath: string[] = []) => {
            const currentPath = [...parentPath, item.name];
            result.push(`${category}:${currentPath.join('/')}`);

            // 子要素が存在する場合、再帰的に処理
            if (item.children && item.children.length > 0) {
              item.children.forEach((child: any) => {
                processItem(child, currentPath);
              });
            }
          };

          items.forEach((item) => processItem(item));
          return result;
        },
      );

      // 現在の選択値と比較して、変更がある場合のみ更新
      if (JSON.stringify(selectedValues) !== JSON.stringify(values)) {
        setSelectedValues(values);

        // 選択された項目のパスを展開
        const newExpandedPaths = new Set<string>();
        values.forEach((value) => {
          const [category, pathString] = value.split(':');
          const pathParts = pathString.split('/');
          let currentPath = pathParts[0];
          newExpandedPaths.add(`${category}:${currentPath}`);

          for (let i = 1; i < pathParts.length; i++) {
            currentPath = `${currentPath}/${pathParts[i]}`;
            newExpandedPaths.add(`${category}:${currentPath}`);
          }
        });

        setExpandedPaths(newExpandedPaths);
      }
    }
  }, [selectedOptions]);

  // 選択肢が変更されたときに親コンポーネントに通知
  useEffect(() => {
    const hierarchy = buildHierarchy(selectedValues);
    // 現在のselectedOptionsと比較して、変更がある場合のみ通知
    if (JSON.stringify(hierarchy) !== JSON.stringify(selectedOptions)) {
      onOptionsChange(hierarchy);
    }
  }, [selectedValues, onOptionsChange, selectedOptions]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel>分析オプション</InputLabel>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label="分析オプション" />}
          renderValue={(selected) => {
            return selected
              .map((value) => {
                const option = allOptions.find((opt) => opt.value === value);
                return option?.label || '';
              })
              .join(', ');
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 500,
                '& .MuiListSubheader-root': {
                  backgroundColor: 'background.paper',
                  lineHeight: '24px',
                  fontSize: '0.75rem',
                  color: 'text.secondary',
                  userSelect: 'none',
                  py: 0.5,
                },
              },
            },
          }}
        >
          {allOptions.map((option) => {
            if (!shouldShowOption(option)) return null;

            if (option.isHeader) {
              const hasChildren = allOptions.some(
                (opt) =>
                  !opt.isHeader &&
                  opt.path.length > option.path.length &&
                  opt.path.slice(0, option.path.length).join('/') ===
                    option.path.join('/'),
              );

              if (!hasChildren) return null;

              return (
                <ListSubheader
                  key={option.value}
                  onClick={() => handleItemClick(option)}
                  sx={{
                    ml: option.level * 2,
                    fontWeight: option.level === 0 ? 600 : 500,
                    color: 'text.secondary',
                    borderBottom: option.level === 0 ? 1 : 0,
                    borderColor: 'divider',
                    backgroundColor: 'background.paper',
                    cursor: 'pointer',
                    fontSize: option.level === 0 ? '0.75rem' : '0.7rem',
                    textTransform: option.level === 0 ? 'uppercase' : 'none',
                    letterSpacing: option.level === 0 ? '0.05em' : 'normal',
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: alpha('#000', 0.02),
                    },
                  }}
                >
                  <ArrowRightIcon
                    sx={{
                      fontSize: 14,
                      mr: 0.5,
                      opacity: 0.5,
                      transform: isExpanded(option) ? 'rotate(90deg)' : 'none',
                      transition: 'transform 0.2s',
                    }}
                  />
                  {option.label}
                </ListSubheader>
              );
            }

            return (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  pl: (option.level + 1) * 2.5,
                  py: 1,
                  minHeight: 'auto',
                  fontSize: '0.875rem',
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: option.level * 20 + 12,
                    top: '50%',
                    width: 3,
                    height: 3,
                    borderRadius: '50%',
                    backgroundColor: 'text.disabled',
                    transform: 'translateY(-50%)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: alpha('#000', 0.04),
                    '&::before': {
                      backgroundColor: 'primary.main',
                      width: 4,
                      height: 4,
                    },
                  },
                  '&:hover': {
                    backgroundColor: alpha('#000', 0.06),
                  },
                }}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AnalysisOptionsSelector;
