import Editor from '@monaco-editor/react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CodeIcon from '@mui/icons-material/Code';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { analysisService } from '../../services/analysis-service';

// JSXをコンパイルするためのヘルパー関数
const compileJSX = (code: string) => {
  try {
    // コードを実行する関数を作成
    return new Function(
      'React',
      'Plot',
      'Bar',
      'CartesianGrid',
      'ComposedChart',
      'Legend',
      'Line',
      'Tooltip',
      'XAxis',
      'YAxis',
      'ScatterChart',
      'Scatter',
      'Cell',
      'data',
      `${code}`,
    );
  } catch (err) {
    console.error('コードのコンパイルに失敗:', err);
    throw err;
  }
};

interface VisualizationEditorProps {
  code: string;
  library: string;
  analysisId: string;
  message_id: string;
  purpose: string;
  target_metrics: string[];
  dimensions: string[];
  sql_result: {
    columns: string[];
    rows: Record<string, any>[];
  };
  onSave?: () => void;
  showCodeEditor?: boolean;
}

export const VisualizationEditor: React.FC<VisualizationEditorProps> = ({
  code: initialCode,
  library: initialLibrary,
  analysisId,
  message_id,
  purpose,
  target_metrics,
  dimensions,
  sql_result,
  onSave,
  showCodeEditor = true,
}) => {
  const defaultCode = `/* 選択したライブラリ: recharts
理由: シンプルなデータの可視化に適しており、React要素として直接レンダリングできるため

可視化の説明:
- シンプルな棒グラフでデータを表示
- 各項目の値を直感的に比較可能
- ツールチップで詳細情報を表示
*/

// データの準備
const processedData = sql_result_rows.map(item => ({
  name: item.name || 'Unknown',
  value: Number(item.value) || 0
}));

// グラフを作成
const element = React.createElement(
  'div',
  { style: { width: '100%', height: 300 } },
  [
    React.createElement(
      'h2',
      { style: { textAlign: 'center' }, key: 'title' },
      'シンプルな棒グラフ'
    ),
    React.createElement(
      ComposedChart,
      {
        width: 600,
        height: 200,
        data: processedData,
        margin: { top: 20, right: 20, left: 20, bottom: 20 },
        key: 'chart'
      },
      [
        React.createElement(CartesianGrid, { strokeDasharray: '3 3', key: 'grid' }),
        React.createElement(XAxis, { dataKey: 'name', key: 'xAxis' }),
        React.createElement(YAxis, { key: 'yAxis' }),
        React.createElement(Tooltip, { key: 'tooltip' }),
        React.createElement(Legend, { key: 'legend' }),
        React.createElement(Bar, {
          dataKey: 'value',
          fill: '#8884d8',
          key: 'bar'
        })
      ]
    )
  ]
);

return element;`;

  const [code, setCode] = useState(initialCode || defaultCode);
  const [library, setLibrary] = useState(initialLibrary);
  const [isModified, setIsModified] = useState(false);
  const [fixRequest, setFixRequest] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [Preview, setPreview] = useState<React.ReactNode | null>(null);

  // コードを実行してプレビューを更新
  const updatePreview = useCallback(() => {
    try {
      // コードをコンパイル
      const compiledCode = compileJSX(code);

      // コンポーネントを取得
      const result = compiledCode(
        React,
        Plot,
        Bar,
        CartesianGrid,
        ComposedChart,
        Legend,
        Line,
        Tooltip,
        XAxis,
        YAxis,
        ScatterChart,
        Scatter,
        Cell,
        sql_result.rows,
      );

      setPreview(result);
      setError(null);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : '不明なエラーが発生しました',
      );
      setPreview(null);
    }
  }, [code, sql_result.rows]);

  // コードが変更されたらプレビューを更新
  useEffect(() => {
    updatePreview();
  }, [updatePreview]);

  useEffect(() => {
    setCode(initialCode);
    setLibrary(initialLibrary);
    setIsModified(false);
  }, [initialCode, initialLibrary]);

  const { mutate: saveCode, isPending: isSaving } = useMutation({
    mutationFn: analysisService.updateVisualizationCode,
    onSuccess: () => {
      setIsModified(false);
      onSave?.();
    },
  });

  const { mutate: fixCode, isPending: isFixing } = useMutation({
    mutationFn: analysisService.fixVisualizationCode,
    onSuccess: (data) => {
      setCode(data.code);
      setIsModified(true);
      setFixRequest('');
    },
  });

  const handleSave = () => {
    if (!message_id) return;
    saveCode({
      message_id,
      analysis_id: analysisId,
      code,
      library,
    });
  };

  const handleFix = () => {
    const request = fixRequest.trim();
    if (!request) return;
    fixCode({
      message_id,
      analysis_id: analysisId,
      code,
      library,
      fix_request: request,
      sql_result,
      purpose,
      target_metrics,
      dimensions,
    });
  };

  const handleLibraryChange = (
    _event: React.MouseEvent<HTMLElement>,
    newLibrary: string,
  ) => {
    if (newLibrary !== null) {
      setLibrary(newLibrary);
      setIsModified(true);
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      {showCodeEditor && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mr: 1,
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CodeIcon color="primary" />
              <Typography>可視化コード {isModified && '※'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <ToggleButtonGroup
                value={library}
                exclusive
                onChange={handleLibraryChange}
                size="small"
              >
                <ToggleButton value="recharts">Recharts</ToggleButton>
                <ToggleButton value="plotly">Plotly</ToggleButton>
              </ToggleButtonGroup>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // アコーディオンの開閉を防ぐ
                  handleSave();
                }}
                disabled={!isModified || isSaving}
                color="primary"
                size="small"
                title="コードを保存"
              >
                {isSaving ? <CircularProgress size={20} /> : <SaveIcon />}
              </IconButton>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Editor
              height="400px"
              defaultLanguage="javascript"
              value={code}
              onChange={(newCode) => {
                if (newCode) {
                  setCode(newCode);
                  setIsModified(true);
                }
              }}
              options={{
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                wordWrap: 'on',
                wrappingIndent: 'indent',
              }}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {error && (
        <Box
          sx={{
            backgroundColor: '#fee',
            color: '#a00',
            p: 2,
            my: 2,
            borderRadius: 1,
            whiteSpace: 'pre-wrap',
            fontFamily: 'monospace',
          }}
        >
          {error}
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          可視化結果
        </Typography>
        <Box sx={{ border: '1px solid #ccc', p: 2, mb: 2, borderRadius: 1 }}>
          {Preview}
        </Box>
      </Box>

      {showCodeEditor && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            可視化の改善
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <TextField
              fullWidth
              size="small"
              placeholder="カスタムの修正内容を入力してください"
              value={fixRequest}
              onChange={(e) => setFixRequest(e.target.value)}
              disabled={isFixing}
            />
            <IconButton
              onClick={handleFix}
              disabled={!fixRequest.trim() || isFixing}
              color="primary"
              title="コードを修正"
            >
              {isFixing ? <CircularProgress size={24} /> : <AutoFixHighIcon />}
            </IconButton>
          </Box>
        </Box>
      )}
    </Paper>
  );
};
