import { Box, Card, CardContent, Typography } from '@mui/material';
import { format, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DailyShelfMetrics } from '../../../services/shelf-service';
import {
  getPageTypeColor,
  getPageTypeLabel,
} from '../../../utils/page-type-utils';
import { TimeUnit } from './TimeUnitSelector';

interface PageTypeMetricsChartsProps {
  data: DailyShelfMetrics[];
  timeUnit: TimeUnit;
}

const PageTypeMetricsCharts: React.FC<PageTypeMetricsChartsProps> = ({
  data,
  timeUnit,
}) => {
  const sortedPageTypes = useMemo(() => {
    const pageTypeShownTotals = data.reduce(
      (acc, curr) => {
        acc[curr.url_page_type] = (acc[curr.url_page_type] || 0) + curr.shown;
        return acc;
      },
      {} as { [key: string]: number },
    );

    return Object.entries(pageTypeShownTotals)
      .sort(([, a], [, b]) => b - a)
      .map(([pageType]) => pageType);
  }, [data]);

  const charts = [
    {
      title: 'ページ別シェルフIMP SS数',
      dataKey: 'shown',
    },
    {
      title: 'ページ別シェルフクリックSS数',
      dataKey: 'click',
    },
    {
      title: 'ページ別シェルフ経由CV SS数',
      dataKey: 'cv',
    },
  ];

  const getTimeUnitStartDate = (date: string) => {
    const dateObj = parseISO(date);
    switch (timeUnit) {
      case 'week':
        return format(startOfWeek(dateObj, { locale: ja }), 'yyyy-MM-dd');
      case 'month':
        return format(startOfMonth(dateObj), 'yyyy-MM-dd');
      default:
        return date;
    }
  };

  const processData = (metrics: DailyShelfMetrics[], metric: string) => {
    const groupedData = metrics.reduce(
      (acc, curr) => {
        const date = curr.date;
        const key = getTimeUnitStartDate(date);

        if (!acc[key]) {
          acc[key] = { date: key };
          sortedPageTypes.forEach((pageType) => {
            acc[key][getPageTypeLabel(pageType)] = 0;
          });
        }

        const pageTypeLabel = getPageTypeLabel(curr.url_page_type);
        acc[key][pageTypeLabel] = (acc[key][pageTypeLabel] || 0) + curr[metric];

        return acc;
      },
      {} as { [key: string]: any },
    );

    return Object.values(groupedData).sort((a, b) =>
      a.date.localeCompare(b.date),
    );
  };

  const formatXAxisTick = (date: string) => {
    switch (timeUnit) {
      case 'week':
        return format(parseISO(date), 'M/d週', { locale: ja });
      case 'month':
        return format(parseISO(date), 'yyyy/M月', { locale: ja });
      default:
        return format(parseISO(date), 'M/d', { locale: ja });
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: 2,
      }}
    >
      {charts.map((chart) => {
        const chartData = processData(data, chart.dataKey);

        return (
          <Card
            key={chart.title}
            sx={{
              height: '100%',
              borderRadius: 2,
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
              bgcolor: '#FFFFFF',
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  mb: 2,
                  textAlign: 'left',
                }}
              >
                {chart.title}
              </Typography>
              <Box sx={{ width: '100%', height: 280 }}>
                <ResponsiveContainer>
                  <AreaChart
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 0,
                      bottom: 60,
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      vertical={false}
                      stroke="rgba(0, 0, 0, 0.1)"
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={formatXAxisTick}
                      tick={{ fontSize: 12, fill: '#666' }}
                      tickLine={{ stroke: '#ccc' }}
                      axisLine={{ stroke: '#ccc' }}
                    />
                    <YAxis
                      tick={{ fontSize: 12, fill: '#666' }}
                      tickLine={{ stroke: '#ccc' }}
                      axisLine={{ stroke: '#ccc' }}
                    />
                    <Tooltip
                      formatter={(value: number) => value.toLocaleString()}
                      labelFormatter={(label: string) =>
                        format(parseISO(label), 'yyyy/M/d (E)', {
                          locale: ja,
                        })
                      }
                    />
                    <Legend
                      verticalAlign="bottom"
                      height={36}
                      iconType="circle"
                      iconSize={8}
                      wrapperStyle={{
                        fontSize: '11px',
                      }}
                    />
                    {sortedPageTypes.map((pageType) => {
                      const label = getPageTypeLabel(pageType);
                      const color = getPageTypeColor(pageType);
                      return (
                        <Area
                          key={pageType}
                          type="monotone"
                          dataKey={label}
                          name={label}
                          stackId="1"
                          stroke={color}
                          fill={color}
                          fillOpacity={0.3}
                        />
                      );
                    })}
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default PageTypeMetricsCharts;
