import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { ComponentMetrics } from '../../../services/component-service';
import {
  DailyShelfMetricsV2,
  DailySSMetricsV2,
} from '../../../services/shelf-service';
import MetricsSummaryCards from '../ix-summary-table/MetricsSummaryCards';
import OverallMetricsCharts from '../ix-summary-table/OverallMetricsCharts';
import PageTypeMetricsCharts from '../ix-summary-table/PageTypeMetricsCharts';

interface IXSummarySectionProps {
  metrics: ComponentMetrics[];
  dailyMetrics: DailyShelfMetricsV2[];
  dailySSMetrics: DailySSMetricsV2[];
  startDate: string;
  endDate: string;
  unitPrice: number;
  isLoading: boolean;
}

const IXSummarySection: React.FC<IXSummarySectionProps> = ({
  dailyMetrics,
  dailySSMetrics,
  unitPrice,
  isLoading,
}) => {
  return (
    <Box
      sx={{
        mb: 6,
        bgcolor: 'background.paper',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
        minHeight: '200px',
      }}
    >
      <Box
        sx={{
          p: '8px 16px',
          bgcolor: '#1a1a1a',
          color: 'white',
          mx: '2px',
          borderRadius: '3px 3px 0 0',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', fontSize: '1.8rem' }}
        >
          InsightXのページ横断パフォーマンス
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <MetricsSummaryCards data={dailySSMetrics} unitPrice={unitPrice} />
            <OverallMetricsCharts data={dailySSMetrics} timeUnit="week" />
            <PageTypeMetricsCharts data={dailyMetrics} timeUnit="week" />
          </>
        )}
      </Box>
    </Box>
  );
};

export default IXSummarySection;
