interface PageTypeInfo {
  label: string;
  description: string;
  color: string;
}

export const getPageTypeInfo = (pageType: string): PageTypeInfo => {
  const pageTypeMap: Record<string, PageTypeInfo> = {
    トップ: {
      label: 'トップ',
      description: 'ECサイトのメインページ',
      color: '#4ECDC4',
    },
    商品詳細: {
      label: '商品詳細',
      description: '商品の詳細情報ページ',
      color: '#FF6B6B',
    },
    商品一覧: {
      label: '商品一覧',
      description: '商品検索・一覧ページ',
      color: '#FFE66D',
    },
    ブランド: {
      label: 'ブランド',
      description: 'ブランドページ',
      color: '#6C5CE7',
    },
    カート: {
      label: 'カート',
      description: 'ショッピングカートページ',
      color: '#E66161',
    },
    お気に入り: {
      label: 'お気に入り',
      description: 'お気に入り商品一覧ページ',
      color: '#CCB857',
    },
    マイページ: {
      label: 'マイページ',
      description: '会員情報・各種設定ページ',
      color: '#8480EC',
    },
    注文完了: {
      label: '注文完了',
      description: '注文完了・サンクスページ',
      color: '#3CA29A',
    },
    注文履歴: {
      label: '注文履歴',
      description: '過去の注文履歴一覧ページ',
      color: '#CC5757',
    },
    お知らせ: {
      label: 'お知らせ',
      description: 'お知らせ・ヘルプページ',
      color: '#998A41',
    },
    ニュース: {
      label: 'ニュース',
      description: 'ニュース・プレスリリースページ',
      color: '#5550BF',
    },
    ショップ: {
      label: 'ショップ',
      description: 'ブランド・ショップ別商品一覧ページ',
      color: '#2A7871',
    },
    コンテンツ: {
      label: 'コンテンツ',
      description: 'セールLPページ・キャンペーンページ等',
      color: '#9E8E3C',
    },
    ランキング: {
      label: 'ランキング',
      description: '人気商品ランキングページ',
      color: '#4D48A6',
    },
    カテゴリ検索: {
      label: 'カテゴリ検索',
      description: 'カテゴリ商品検索ページ',
      color: '#226159',
    },
    閲覧履歴: {
      label: '閲覧履歴',
      description: '閲覧履歴ページ',
      color: '#803939',
    },
    その他: {
      label: 'その他',
      description: 'その他のページタイプ',
      color: '#A8A4F0',
    },
  };

  return (
    pageTypeMap[pageType] ?? {
      label: pageType,
      description: '',
      color: '#999999', // デフォルトの色
    }
  );
};

// ラベルのみを取得する便利関数
export const getPageTypeLabel = (pageType: string): string => {
  return getPageTypeInfo(pageType).label;
};

// 説明のみを取得する便利関数
export const getPageTypeDescription = (pageType: string): string => {
  return getPageTypeInfo(pageType).description;
};

// 色を取得する便利関数
export const getPageTypeColor = (pageType: string): string => {
  return getPageTypeInfo(pageType).color;
};
