import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import { Eye, Heart, MousePointerClick, ShoppingCart } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { ComponentMetricsV2 } from '../../../services/component-service';

interface MetricsTableProps {
  metrics: ComponentMetricsV2[];
  startDate: string;
  endDate: string;
  unitPrice: number;
}

const safeNumber = (value: number | null | undefined): number => {
  if (value === null || value === undefined || isNaN(value)) {
    return 0;
  }
  return value;
};

const safeDivide = (numerator: number, denominator: number): number => {
  if (denominator === 0 || !denominator) return 0;
  const result = numerator / denominator;
  return isFinite(result) ? result : 0;
};

type ColumnDefinition = {
  id: string;
  label: string;
  icon?: React.ElementType;
  iconColor?: string;
  subLabel: string;
  format: (value: any) => string;
  getValue:
    | ((metric: any) => number)
    | ((
        metric: any,
        startDate: string,
        endDate: string,
        unitPrice: number,
      ) => number);
  width: string;
  group: string;
};

const columns: ColumnDefinition[] = [
  {
    id: 'shown',
    label: '見た',
    icon: Eye,
    iconColor: '#2196F3',
    subLabel: 'IMP数',
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.shown),
    width: '140px',
    group: 'impression',
  },
  {
    id: 'click',
    label: '使った',
    icon: MousePointerClick,
    iconColor: '#4CAF50',
    subLabel: 'クリック数',
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.click),
    width: '120px',
    group: 'click',
  },
  {
    id: 'clickr',
    label: '使った',
    subLabel: 'クリック率',
    format: (value: any) => `${(safeNumber(value) * 100).toFixed(3)}%`,
    getValue: (metric: any) =>
      safeDivide(safeNumber(metric.click), safeNumber(metric.shown)),
    width: '100px',
    group: 'click',
  },
  {
    id: 'favorite',
    label: 'お気に入りした',
    icon: Heart,
    iconColor: '#F44336',
    subLabel: 'お気に入り数',
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.favorite),
    width: '120px',
    group: 'favorite',
  },
  {
    id: 'favoriter',
    label: 'お気に入りした',
    subLabel: 'お気に入り率',
    format: (value: any) => `${(safeNumber(value) * 100).toFixed(3)}%`,
    getValue: (metric: any) =>
      safeDivide(safeNumber(metric.favorite), safeNumber(metric.shown)),
    width: '120px',
    group: 'favorite',
  },
  {
    id: 'cv',
    label: '買った',
    icon: ShoppingCart,
    iconColor: '#FF9800',
    subLabel: 'CV数',
    format: (value: any) => safeNumber(value).toLocaleString(),
    getValue: (metric: any) => safeNumber(metric.cv),
    width: '100px',
    group: 'cv',
  },
  {
    id: 'cvr',
    label: '買った',
    subLabel: 'CV率',
    format: (value: any) => `${(safeNumber(value) * 100).toFixed(3)}%`,
    getValue: (metric: any) =>
      safeDivide(safeNumber(metric.cv), safeNumber(metric.shown)),
    width: '100px',
    group: 'cv',
  },
  {
    id: 'revenue',
    label: '買った',
    subLabel: '経由CV金額',
    format: (value: any) =>
      `¥${Math.round(safeNumber(value)).toLocaleString()}`,
    getValue: (
      metric: any,
      startDate: string,
      endDate: string,
      unitPrice: number,
    ) => {
      const daysDiff =
        differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;
      const cv = safeNumber(metric.cv);
      const dailyRevenue = (cv * unitPrice) / daysDiff;
      const monthlyRevenue = dailyRevenue * 30;
      return monthlyRevenue;
    },
    width: '140px',
    group: 'cv',
  },
];

const MetricsTable: React.FC<MetricsTableProps> = ({
  metrics = [],
  startDate,
  endDate,
  unitPrice,
}) => {
  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [maxValues, setMaxValues] = useState<Record<string, number>>({});
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);

  const ixSummary = React.useMemo(() => {
    // IXコンポーネントのみをフィルタリング
    const ixMetrics = metrics.filter((m) =>
      m.component_name?.startsWith('InsightX'),
    );

    // コンポーネント名でグループ化して重複を排除
    const uniqueIxMetrics = ixMetrics.reduce(
      (acc, metric) => {
        const key = metric.component_name;
        if (!acc[key]) {
          acc[key] = metric;
        } else if (metric.shown > acc[key].shown) {
          // 表示数が多い方を採用
          acc[key] = metric;
        }
        return acc;
      },
      {} as Record<string, ComponentMetricsV2>,
    );

    const uniqueMetrics = Object.values(uniqueIxMetrics);

    return {
      component_name: 'InsightX 全体',
      shown: uniqueMetrics.reduce((sum, m) => sum + safeNumber(m.shown), 0),
      click: uniqueMetrics.reduce((sum, m) => sum + safeNumber(m.click), 0),
      favorite: uniqueMetrics.reduce(
        (sum, m) => sum + safeNumber(m.favorite),
        0,
      ),
      cv: uniqueMetrics.reduce((sum, m) => sum + safeNumber(m.cv), 0),
    };
  }, [metrics]);

  useEffect(() => {
    const calculateMaxValues = () => {
      const validMetricsArray = Array.isArray(metrics) ? metrics : [];
      const validMetrics = validMetricsArray.filter((m) => m);

      const newMaxValues = columns.reduce(
        (acc, column) => {
          const values = validMetrics.map((m) => {
            if (column.id === 'revenue') {
              return (column.getValue as any)(m, startDate, endDate, unitPrice);
            }
            return (column.getValue as any)(m);
          });
          acc[column.id] = Math.max(0, ...values);
          return acc;
        },
        {} as Record<string, number>,
      );

      setMaxValues(newMaxValues);
    };

    calculateMaxValues();
  }, [metrics, startDate, endDate, unitPrice]);

  const sortedMetrics = React.useMemo(() => {
    const validMetricsArray = Array.isArray(metrics) ? metrics : [];

    // コンポーネント名でグループ化して重複を排除
    const uniqueMetrics = validMetricsArray.reduce(
      (acc, metric) => {
        if (!metric) return acc;

        const key = metric.component_name;
        if (!acc[key]) {
          acc[key] = metric;
        } else if (metric.shown > acc[key].shown) {
          // 表示数が多い方を採用
          acc[key] = metric;
        }
        return acc;
      },
      {} as Record<string, ComponentMetricsV2>,
    );

    return Object.values(uniqueMetrics)
      .filter((m) => m && !m.component_name?.startsWith('IX')) // IXで始まるコンポーネントを除外
      .sort((a, b) => safeNumber(b.shown) - safeNumber(a.shown));
  }, [metrics]);

  const STICKY_COLUMN_STYLE = {
    position: 'sticky',
    left: 0,
    zIndex: 3,
    bgcolor: 'background.paper',
    borderRight: '1px solid',
    borderRightColor: 'divider',
    boxShadow: '2px 0 4px rgba(0,0,0,0.1)',
    minWidth: '200px',
    maxWidth: '200px',
    width: '200px',
  } as const;

  const getColumnStyle = (columnId: string, isHeader: boolean = false) => ({
    position: 'relative',
    textAlign: 'right' as const,
    py: 1.5,
    px: 2,
    whiteSpace: 'nowrap' as const,
    width: columns.find((col) => col.id === columnId)?.width,
    minWidth: columns.find((col) => col.id === columnId)?.width,
    maxWidth: columns.find((col) => col.id === columnId)?.width,
    ...(isHeader && {
      bgcolor: 'background.paper',
      position: 'sticky' as const,
      top: 0,
      zIndex: 1200,
      borderBottom: 'none',
    }),
    ...(hoveredColumn === columnId && {
      '& .metrics-bar': {
        opacity: 0.3,
      },
    }),
    borderRight: (theme) => {
      const column = columns.find((col) => col.id === columnId);
      const nextColumn =
        columns[columns.findIndex((col) => col.id === columnId) + 1];
      if (nextColumn && column?.group !== nextColumn.group) {
        return `1px solid ${theme.palette.divider}`;
      }
      return 'none';
    },
  });

  const renderMetricsBar = (
    value: number,
    maxValue: number,
    isIXComponent: boolean,
  ) => {
    const percentage = maxValue > 0 ? (value / maxValue) * 100 : 0;
    const limitedPercentage = Math.min(percentage, 99);

    return (
      <Box
        className="metrics-bar"
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: `${limitedPercentage}%`,
          bgcolor: isIXComponent ? '#1976d2' : 'grey.600',
          opacity: isIXComponent ? 0.12 : 0.15,
          transition: 'opacity 0.2s',
        }}
      />
    );
  };

  const renderCell = (
    metric: any,
    column: ColumnDefinition,
    maxValue: number,
  ) => {
    const value =
      column.id === 'revenue'
        ? (column.getValue as any)(metric, startDate, endDate, unitPrice)
        : (column.getValue as any)(metric);

    const isIXComponent = metric.component_name?.startsWith('InsightX');

    return (
      <TableCell
        key={column.id}
        sx={{
          ...getColumnStyle(column.id),
          bgcolor: 'background.paper',
          ...(isIXComponent && {
            '& .metrics-value': {
              color: '#1976d2',
              fontWeight: 'bold',
            },
          }),
          ...(hoveredColumn === column.id && {
            '& .metrics-value': {
              color: isIXComponent ? '#1976d2' : 'text.primary',
              fontWeight: 'bold',
            },
          }),
        }}
        onMouseEnter={() => setHoveredColumn(column.id)}
        onMouseLeave={() => setHoveredColumn(null)}
      >
        {renderMetricsBar(value, maxValue, isIXComponent)}
        <Typography
          variant="body2"
          className="metrics-value"
          sx={{
            position: 'relative',
            fontWeight: 'bold',
            color: isIXComponent ? '#1976d2' : 'text.primary',
          }}
        >
          {column.format(value)}
        </Typography>
      </TableCell>
    );
  };

  if (!metrics || metrics.length === 0) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
                データがありません
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Paper
        elevation={2}
        sx={{
          borderRadius: 3,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
          >
            📱 コンポーネント別のパフォーマンス比較
          </Typography>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 1000,
              borderRadius: 2,
              boxShadow: 'none',
              position: 'relative',
              '& .MuiTableHead-root': {
                position: 'sticky',
                top: 0,
                zIndex: 1300,
                bgcolor: 'background.paper',
              },
              '& .scrollable-body': {
                maxHeight: 'calc(1000px - 140px)',
                overflowY: 'auto',
              },
            }}
          >
            <Table stickyHeader size="small">
              <TableHead ref={headerRef}>
                <TableRow
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: 'divider',
                  }}
                >
                  <TableCell
                    sx={{
                      ...STICKY_COLUMN_STYLE,
                      zIndex: 1301,
                      top: 0,
                      position: 'sticky',
                      bgcolor: 'background.paper',
                      borderBottom: 'none',
                    }}
                    rowSpan={2}
                  >
                    コンポーネント
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={1}
                    sx={{
                      borderBottom: 'none',
                      height: '40px',
                      verticalAlign: 'bottom',
                      pb: 1,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        right: '-1rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 0,
                        height: 0,
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderLeft: '10px solid rgba(0, 0, 0, 0.1)',
                      },
                      borderRight: '1px solid',
                      borderRightColor: 'divider',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                        }}
                      >
                        <Eye size={16} style={{ color: '#2196F3' }} />
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            color: 'text.primary',
                          }}
                        >
                          見た
                        </Typography>
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'text.secondary',
                          display: 'block',
                        }}
                      >
                        露出度
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{
                      borderBottom: 'none',
                      height: '40px',
                      verticalAlign: 'bottom',
                      pb: 1,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        right: '-1rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 0,
                        height: 0,
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderLeft: '10px solid rgba(0, 0, 0, 0.1)',
                      },
                      borderRight: '1px solid',
                      borderRightColor: 'divider',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                        }}
                      >
                        <MousePointerClick
                          size={16}
                          style={{ color: '#4CAF50' }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            color: 'text.primary',
                          }}
                        >
                          使った
                        </Typography>
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'text.secondary',
                          display: 'block',
                        }}
                      >
                        魅力度
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{
                      borderBottom: 'none',
                      height: '40px',
                      verticalAlign: 'bottom',
                      pb: 1,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        right: '-1rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 0,
                        height: 0,
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderLeft: '10px solid rgba(0, 0, 0, 0.1)',
                      },
                      borderRight: '1px solid',
                      borderRightColor: 'divider',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                        }}
                      >
                        <Heart size={16} style={{ color: '#F44336' }} />
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            color: 'text.primary',
                          }}
                        >
                          お気に入りした
                        </Typography>
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'text.secondary',
                          display: 'block',
                        }}
                      >
                        CVアシスト力
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    sx={{
                      borderBottom: 'none',
                      height: '40px',
                      verticalAlign: 'bottom',
                      pb: 1,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        right: '-1rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 0,
                        height: 0,
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderLeft: '10px solid rgba(0, 0, 0, 0.1)',
                      },
                      borderRight: '1px solid',
                      borderRightColor: 'divider',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                        }}
                      >
                        <ShoppingCart size={16} style={{ color: '#FF9800' }} />
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            color: 'text.primary',
                          }}
                        >
                          買った
                        </Typography>
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'text.secondary',
                          display: 'block',
                        }}
                      >
                        CV決定力
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={`${column.id}-sublabel`}
                      align="center"
                      sx={{
                        ...getColumnStyle(column.id, true),
                        textAlign: 'center',
                        borderBottom: '2px solid',
                        borderBottomColor: 'divider',
                        color: 'text.secondary',
                        height: '40px',
                        verticalAlign: 'top',
                        pt: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          textAlign: 'center',
                        }}
                      >
                        {column.subLabel}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="scrollable-body">
                {sortedMetrics.map((metric) => {
                  const isIXComponent =
                    metric.component_name?.startsWith('InsightX');
                  return (
                    <TableRow
                      key={metric.component_name}
                      sx={{
                        '&:hover': { bgcolor: 'action.hover' },
                        position: 'relative',
                        zIndex: 1,
                      }}
                    >
                      <TableCell
                        sx={{
                          ...STICKY_COLUMN_STYLE,
                          bgcolor: 'background.paper',
                          zIndex: 2,
                          '& .MuiTypography-root': {
                            fontWeight: 'bold',
                            color: isIXComponent ? '#1976d2' : 'text.primary',
                            ...(isIXComponent && {
                              fontSize: '1.1rem',
                            }),
                          },
                        }}
                      >
                        <Typography variant="body2">
                          {metric.component_name?.startsWith('InsightX') ? (
                            <>
                              InsightX
                              <br />
                              {metric.component_name.replace('InsightX', '')}
                            </>
                          ) : (
                            metric.component_name
                          )}
                        </Typography>
                      </TableCell>
                      {columns.map((column) =>
                        renderCell(metric, column, maxValues[column.id]),
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableBody>
                <TableRow
                  sx={{
                    borderTop: '2px solid',
                    borderTopColor: 'divider',
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: 'action.hover' },
                    height: '60px',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                  }}
                >
                  <TableCell
                    sx={{
                      ...STICKY_COLUMN_STYLE,
                      bgcolor: 'background.paper',
                      zIndex: 2,
                      py: 2,
                      '& .MuiTypography-root': {
                        color: '#1976d2',
                        fontWeight: 900,
                        fontSize: '1.1rem',
                      },
                    }}
                  >
                    <Typography variant="body2">
                      {ixSummary.component_name}
                    </Typography>
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        ...getColumnStyle(column.id),
                        bgcolor: 'background.paper',
                        py: 2,
                        '& .metrics-value': {
                          color: '#1976d2',
                          fontWeight: 900,
                          fontSize: '1.1rem',
                        },
                        ...(hoveredColumn === column.id && {
                          '& .metrics-value': {
                            color: '#1976d2',
                            fontWeight: 900,
                            fontSize: '1.1rem',
                          },
                        }),
                      }}
                      onMouseEnter={() => setHoveredColumn(column.id)}
                      onMouseLeave={() => setHoveredColumn(null)}
                    >
                      {renderMetricsBar(
                        (column.getValue as any)(
                          ixSummary,
                          startDate,
                          endDate,
                          unitPrice,
                        ),
                        maxValues[column.id],
                        true,
                      )}
                      <Typography
                        variant="body2"
                        className="metrics-value"
                        sx={{
                          position: 'relative',
                          fontWeight: 900,
                          fontSize: '1.1rem',
                          color: '#1976d2',
                        }}
                      >
                        {column.format(
                          (column.getValue as any)(
                            ixSummary,
                            startDate,
                            endDate,
                            unitPrice,
                          ),
                        )}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default MetricsTable;
