import { Box, Paper, Typography } from '@mui/material';
import { TrendingUp } from 'lucide-react';
import React from 'react';
import { TrendSearchShelf } from '../../../services/shelf-service';

interface TrendShelfRankingProps {
  shelves: TrendSearchShelf[];
}

const getRankColor = (rank: number): { bg: string; text: string } => {
  switch (rank) {
    case 1:
      return { bg: '#FFD700', text: '#000000' }; // 金色
    case 2:
      return { bg: '#C0C0C0', text: '#000000' }; // 銀色
    case 3:
      return { bg: '#CD7F32', text: '#FFFFFF' }; // 銅色
    default:
      return { bg: 'action.selected', text: 'text.primary' };
  }
};

const TrendShelfRanking: React.FC<TrendShelfRankingProps> = ({ shelves }) => {
  return (
    <Box sx={{ width: '60%' }}>
      <Paper
        elevation={2}
        sx={{
          borderRadius: 3,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
          >
            <TrendingUp size={20} style={{ color: '#FF4B4B' }} />
            🔥 今週の急上昇こだわりニーズシェルフ TOP10
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {shelves.map((shelf) => {
              const rankColor = getRankColor(shelf.rank);
              const isTopThree = shelf.rank <= 3;
              return (
                <Box
                  key={shelf.shelf_id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: isTopThree ? 1.5 : 1,
                    p: isTopThree ? 2 : 1,
                    bgcolor: 'background.default',
                    borderRadius: 2,
                    ...(isTopThree && { mb: 1 }),
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography
                      sx={{
                        width: isTopThree ? 28 : 24,
                        height: isTopThree ? 28 : 24,
                        borderRadius: '50%',
                        bgcolor: rankColor.bg,
                        color: rankColor.text,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        flexShrink: 0,
                        fontSize: isTopThree ? '0.9rem' : '0.8rem',
                      }}
                    >
                      {shelf.rank}
                    </Typography>
                    <Box sx={{ flex: 1, minWidth: 0 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          fontSize: isTopThree ? '0.8rem' : '0.75rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          display: isTopThree ? 'block' : 'none',
                        }}
                      >
                        {shelf.gray_tagline}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          fontSize: isTopThree ? '0.95rem' : '0.85rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {shelf.bold_tagline}
                      </Typography>
                    </Box>
                  </Box>

                  {isTopThree &&
                    shelf.top_items &&
                    shelf.top_items.length > 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          ml: 4.5,
                        }}
                      >
                        {shelf.top_items.slice(0, 10).map((item) => (
                          <Box
                            key={item.aid}
                            component="a"
                            href={item.item_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              textDecoration: 'none',
                              color: 'inherit',
                              width: '50px',
                            }}
                          >
                            <img
                              src={item.image_url}
                              alt={item.item_title}
                              loading="lazy"
                              style={{
                                width: '100%',
                                height: '65px',
                                objectFit: 'cover',
                                borderRadius: '4px',
                              }}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default TrendShelfRanking;
