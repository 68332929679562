import { Box, Typography } from '@mui/material';
import React from 'react';

interface PropertySectionProps {
  label: string;
  value: string;
}

export const PropertySection: React.FC<PropertySectionProps> = ({
  label,
  value,
}) => (
  <Box>
    <Typography
      variant="subtitle2"
      gutterBottom
      sx={{ color: 'text.secondary', fontWeight: 600 }}
    >
      {label}
    </Typography>
    <Typography
      variant="body2"
      sx={{
        whiteSpace: 'pre-wrap',
        backgroundColor: 'grey.50',
        p: 1.5,
        borderRadius: 1,
        minHeight: '2.5em',
        wordBreak: 'break-word',
      }}
    >
      {value}
    </Typography>
  </Box>
);
