import { Box, Grid, TextField, Typography } from '@mui/material';
import { Calendar } from 'lucide-react';
import React from 'react';
import DateRangePicker from './DateRangePicker';

interface MetricsHeaderProps {
  startDate: string;
  endDate: string;
  unitPrice: number;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  onUnitPriceChange: (price: number) => void;
}

const MetricsHeader: React.FC<MetricsHeaderProps> = ({
  startDate,
  endDate,
  unitPrice,
  onStartDateChange,
  onEndDateChange,
  onUnitPriceChange,
}) => {
  const handleUnitPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/,/g, '')) || 0;
    onUnitPriceChange(value);
  };

  return (
    <Grid container spacing={3} alignItems="center" sx={{ mb: 3 }}>
      <Grid item xs={12} md={4}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <img
            src="https://storage.googleapis.com/insightx-images/dashboard/public/assets/insightx_logo_black.png"
            alt="InsightX Logo"
            style={{ height: '80px', width: 'auto' }}
          />
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#1a1a1a',
              fontSize: '50px',
              lineHeight: 1,
              fontFamily: 'Helvetica Neue, Arial, sans-serif',
            }}
          >
            InsightX
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={12} md={8} spacing={3}>
        <Grid item xs={12} md={6}>
          <Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}
            >
              <Calendar size={24} color="#666666" />
              <Typography
                sx={{
                  color: '#666666',
                  fontSize: '1.25rem',
                  fontWeight: 600,
                }}
              >
                集計期間
              </Typography>
            </Box>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={onStartDateChange}
              onEndDateChange={onEndDateChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}
            >
              <Typography
                sx={{
                  color: '#666666',
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                ¥ CVセッション単価
              </Typography>
            </Box>
            <TextField
              type="text"
              value={unitPrice.toLocaleString()}
              onChange={handleUnitPriceChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <Typography
                    sx={{
                      color: '#666666',
                      fontSize: '0.875rem',
                      pl: 1,
                    }}
                  >
                    円
                  </Typography>
                ),
                sx: {
                  '& input': {
                    textAlign: 'right',
                    pr: 1,
                    fontSize: '0.875rem',
                  },
                  height: '42px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
            />
            <Typography
              variant="caption"
              sx={{
                color: '#666666',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                mt: 0.5,
                fontSize: '0.75rem',
              }}
            >
              ※ 指定期間の平均CV単価を入力ください
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MetricsHeader;
