import Editor from '@monaco-editor/react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BarChartIcon from '@mui/icons-material/BarChart';
import CodeIcon from '@mui/icons-material/Code';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import TableChartIcon from '@mui/icons-material/TableChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { analysisService } from '../../services/analysis-service';
import { SearchDebugInfo } from './SearchDebugInfo';
import { SqlEditor, formatBytes } from './SqlEditor';
import { TemplateSearchResults } from './TemplateSearchResults';
import { VisualizationEditor } from './VisualizationEditor';
import {
  AnalysisDetail,
  AnalysisVariable,
  GetAnalysisDetailsResponse,
  SelectedAnalysisOptions,
} from './types';

interface AnalysisDetailsProps {
  data: GetAnalysisDetailsResponse | null;
  isLoading: boolean;
  error: Error | null;
  message_id: string;
  message: string;
  analysis_options: SelectedAnalysisOptions;
  onCostUpdate: (newCost: number) => void;
  variables: AnalysisVariable[];
  onAnalysisAdd?: (analysis: any) => void;
}

const SectionTitle: React.FC<{ icon: React.ReactNode; title: string }> = ({
  icon,
  title,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
    {icon}
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: 'bold',
        color: 'primary.main',
      }}
    >
      {title}
    </Typography>
  </Box>
);

const formatCostInJPY = (costInUSD?: number) => {
  if (!costInUSD) return '0円';
  return `${(Math.round(costInUSD * 150 * 100) / 100).toFixed(2)}円`;
};

export const AnalysisDetails: React.FC<AnalysisDetailsProps> = ({
  data,
  isLoading,
  error,
  message_id,
  message,
  analysis_options,
  onCostUpdate,
  variables,
  onAnalysisAdd,
}) => {
  const queryClient = useQueryClient();
  const [searchResults, setSearchResults] = useState<{ [key: string]: any }>(
    {},
  );
  const [isSearching, setIsSearching] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [sqlResults, setSqlResults] = useState<{ [key: string]: string }>({});
  const [isGeneratingSql, setIsGeneratingSql] = useState<{
    [key: string]: boolean;
  }>({});
  const [sqlDebugInfo, setSqlDebugInfo] = useState<{
    [key: string]: { prompt: string; response: string };
  }>({});
  const [sqlGenerationCosts, setSqlGenerationCosts] = useState<{
    [key: string]: number;
  }>({});
  const [visualizationResults, setVisualizationResults] = useState<{
    [key: string]: {
      code: string;
      library: string;
      fixRequest?: string;
    };
  }>({});
  const [isGeneratingVisualization, setIsGeneratingVisualization] = useState<{
    [key: string]: boolean;
  }>({});
  const [executionResults, setExecutionResults] = useState<{
    [key: string]: {
      columns: string[];
      rows: Record<string, any>[];
      scan_bytes: number;
      execution_time: number;
      is_cached: boolean;
    };
  }>({});
  const [modifyDialogOpen, setModifyDialogOpen] = useState<{
    [key: string]: boolean;
  }>({});
  const [modifyQuery, setModifyQuery] = useState<{ [key: string]: string }>({});
  const [isModifying, setIsModifying] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [modificationCosts, setModificationCosts] = useState<{
    [key: string]: number;
  }>({});
  const [addAnalysisQuery, setAddAnalysisQuery] = useState<string>('');
  const [isAddingAnalysis, setIsAddingAnalysis] = useState<boolean>(false);
  const [searchProgress, setSearchProgress] = useState<{
    [key: string]: number;
  }>({});
  const [sqlProgress, setSqlProgress] = useState<{ [key: string]: number }>({});
  const [discussionResults, setDiscussionResults] = useState<{
    [key: string]: {
      discussion: string;
      fixRequest: string;
    };
  }>({});

  // 進捗バーの状態管理
  useEffect(() => {
    Object.entries(isSearching).forEach(([analysisId, isSearchingValue]) => {
      if (!isSearchingValue) {
        setSearchProgress((prev) => ({
          ...prev,
          [analysisId]: 100,
        }));
        return;
      }

      setSearchProgress((prev) => ({
        ...prev,
        [analysisId]: 0,
      }));

      const startTime = Date.now();
      const timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newProgress = Math.min((elapsedTime / 20000) * 95, 95); // 95%まで
        setSearchProgress((prev) => ({
          ...prev,
          [analysisId]: newProgress,
        }));
      }, 100);

      return () => {
        clearInterval(timer);
      };
    });
  }, [isSearching]);

  // 進捗バーの状態管理
  useEffect(() => {
    Object.entries(isGeneratingSql).forEach(
      ([analysisId, isGeneratingValue]) => {
        if (!isGeneratingValue) {
          setSqlProgress((prev) => ({
            ...prev,
            [analysisId]: 100,
          }));
          return;
        }

        setSqlProgress((prev) => ({
          ...prev,
          [analysisId]: 0,
        }));

        const startTime = Date.now();
        const timer = setInterval(() => {
          const elapsedTime = Date.now() - startTime;
          const newProgress = Math.min((elapsedTime / 20000) * 95, 95); // 95%まで
          setSqlProgress((prev) => ({
            ...prev,
            [analysisId]: newProgress,
          }));
        }, 100);

        return () => {
          clearInterval(timer);
        };
      },
    );
  }, [isGeneratingSql]);

  // コストを更新する関数
  const updateTotalCost = (newCost: number) => {
    onCostUpdate(newCost);
  };

  // 初期コストを設定
  useEffect(() => {
    if (data?.debug?.cost) {
      updateTotalCost(data.debug.cost);
    }
  }, [data?.debug?.cost]);

  // 初期化時にキャッシュされた結果を読み込む
  useEffect(() => {
    const loadCachedResults = async () => {
      if (!data?.analyses) return;

      for (const analysis of data.analyses) {
        try {
          // テンプレート検索結果を読み込む
          const searchQuery = [
            analysis.name,
            analysis.purpose,
            `フェーズ: ${analysis.phase_name}`,
            `フェーズ説明: ${analysis.phase_description}`,
            `指標: ${analysis.target_metrics.join(', ')}`,
            `分析軸: ${analysis.dimensions.join(', ')}`,
            analysis.aggregation ? `集計方法: ${analysis.aggregation}` : '',
            analysis.filters
              ? `フィルター条件: ${analysis.filters.join(', ')}`
              : '',
            `出力項目: ${analysis.output_columns.map((col) => `${col.name}(${col.description})`).join(', ')}`,
          ]
            .filter(Boolean)
            .join('\n');

          const searchResult =
            await analysisService.searchTemplatesFromAnalysis({
              message_id,
              analysis_id: analysis.analysis_id,
              query: searchQuery,
              skip_cache: false,
            });

          if (searchResult.templates) {
            setSearchResults((prev) => ({
              ...prev,
              [analysis.analysis_id]: searchResult,
            }));
          }

          // SQLの結果を読み込む
          const sqlResult = await analysisService.generateSqlFromAnalysis({
            message_id,
            analysis_id: analysis.analysis_id,
            purpose: analysis.purpose,
            template_ids:
              searchResult.templates?.map((t: any) => t.template_id) || [],
            skip_cache: false,
          });

          if (sqlResult.sql) {
            setSqlResults((prev) => ({
              ...prev,
              [analysis.analysis_id]: sqlResult.sql,
            }));

            // SQLのコストを更新
            if (sqlResult.debug?.cost) {
              setSqlGenerationCosts((prev) => ({
                ...prev,
                [analysis.analysis_id]: sqlResult.debug.cost,
              }));
              updateTotalCost(sqlResult.debug.cost);
            }

            const variableMap =
              variables?.reduce(
                (acc, v) => {
                  acc[v.name] = v.value;
                  return acc;
                },
                {} as { [key: string]: string },
              ) || {};

            // 実行結果も取得
            const executionResult = await analysisService.executeSql({
              message_id,
              analysis_id: analysis.analysis_id,
              sql: sqlResult.sql,
              variables: variableMap,
              skip_cache: false,
            });

            setExecutionResults((prev) => ({
              ...prev,
              [analysis.analysis_id]: executionResult,
            }));

            // 実行結果がある場合は可視化コードも生成
            const visualizationResult =
              await analysisService.generateVisualizationCode({
                message_id,
                analysis_id: analysis.analysis_id,
                sql: sqlResult.sql,
                sql_result: executionResult,
                purpose: analysis.purpose,
                target_metrics: analysis.target_metrics,
                dimensions: analysis.dimensions,
              });

            setVisualizationResults((prev) => ({
              ...prev,
              [analysis.analysis_id]: {
                code: visualizationResult.code,
                library: visualizationResult.library,
              },
            }));

            // ディスカッションも読み込む
            try {
              const discussionResult = await analysisService.getDiscussion(
                message_id,
                analysis.analysis_id,
              );

              if (discussionResult.discussion) {
                setDiscussionResults((prev) => ({
                  ...prev,
                  [analysis.analysis_id]: {
                    discussion: discussionResult.discussion,
                    fixRequest: '',
                  },
                }));
              }
            } catch (error) {
              console.error(
                'ディスカッションの読み込み中にエラーが発生:',
                error,
              );
            }
          }
        } catch (error) {
          console.error('キャッシュの読み込み中にエラーが発生:', error);
        }
      }
    };

    loadCachedResults();
  }, [data?.analyses]);

  const handleSearchTemplates = async (analysis: AnalysisDetail) => {
    const analysisId = analysis.analysis_id;
    setIsSearching({ ...isSearching, [analysisId]: true });
    try {
      const searchQuery = [
        analysis.name,
        analysis.purpose,
        `フェーズ: ${analysis.phase_name}`,
        `フェーズ説明: ${analysis.phase_description}`,
        `指標: ${analysis.target_metrics.join(', ')}`,
        `分析軸: ${analysis.dimensions.join(', ')}`,
        analysis.aggregation ? `集計方法: ${analysis.aggregation}` : '',
        analysis.filters
          ? `フィルター条件: ${analysis.filters.join(', ')}`
          : '',
        `出力項目: ${analysis.output_columns.map((col) => `${col.name}(${col.description})`).join(', ')}`,
      ]
        .filter(Boolean)
        .join('\n');

      const result = await analysisService.searchTemplatesFromAnalysis({
        message_id,
        analysis_id: analysisId,
        query: searchQuery,
        skip_cache: true, // キャッシュを使用しない
      });
      setSearchResults({ ...searchResults, [analysisId]: result });
    } catch (error) {
      console.error('テンプレート検索中にエラーが発生:', error);
    } finally {
      setIsSearching({ ...isSearching, [analysisId]: false });
    }
  };

  const handleGenerateSql = async (analysis: AnalysisDetail) => {
    const analysisId = analysis.analysis_id;
    setIsGeneratingSql({ ...isGeneratingSql, [analysisId]: true });
    try {
      // 選択されたテンプレートのIDを取得
      const selectedTemplates = searchResults[analysisId]?.templates || [];
      const templateIds = selectedTemplates.map((t: any) => t.template_id);

      const result = await analysisService.generateSqlFromAnalysis({
        message_id,
        analysis_id: analysisId,
        purpose: analysis.purpose,
        template_ids: templateIds,
        skip_cache: true, // キャッシュを使用しない
      });
      setSqlResults({ ...sqlResults, [analysisId]: result.sql });

      // デバッグ情報とコストを保存
      if (result.debug) {
        setSqlDebugInfo({
          ...sqlDebugInfo,
          [analysisId]: {
            prompt: result.debug.prompt,
            response: result.debug.response,
          },
        });
        if (result.debug.cost) {
          setSqlGenerationCosts({
            ...sqlGenerationCosts,
            [analysisId]: result.debug.cost,
          });
          updateTotalCost(result.debug.cost);
        }
      }
    } catch (error) {
      console.error('SQL生成中にエラーが発生:', error);
    } finally {
      setIsGeneratingSql({ ...isGeneratingSql, [analysisId]: false });
    }
  };

  const handleGenerateVisualization = async (analysis: AnalysisDetail) => {
    const analysisId = analysis.analysis_id;
    setIsGeneratingVisualization({
      ...isGeneratingVisualization,
      [analysisId]: true,
    });
    try {
      const result = await analysisService.generateVisualizationCode({
        message_id,
        analysis_id: analysisId,
        sql: sqlResults[analysisId],
        sql_result: executionResults[analysisId],
        purpose: analysis.purpose,
        target_metrics: analysis.target_metrics,
        dimensions: analysis.dimensions,
      });
      setVisualizationResults({
        ...visualizationResults,
        [analysisId]: { code: result.code, library: result.library },
      });
    } catch (error) {
      console.error('可視化コード生成中にエラーが発生:', error);
    } finally {
      setIsGeneratingVisualization({
        ...isGeneratingVisualization,
        [analysisId]: false,
      });
    }
  };

  const handleModifyAnalysis = async (analysis: AnalysisDetail) => {
    const analysisId = analysis.analysis_id;
    setIsModifying({ ...isModifying, [analysisId]: true });
    try {
      const result = await analysisService.modifyAnalysis({
        message_id,
        analysis_id: analysisId,
        query: modifyQuery[analysisId],
        message,
        analysis_options,
        current_analysis: analysis,
        other_analyses: data.analyses.filter(
          (a: any) => a.analysis_id !== analysisId,
        ),
      });

      // 分析詳細を更新
      const updatedAnalyses = data.analyses.map((a: any) =>
        a.analysis_id === analysisId ? result.analysis : a,
      );
      // データを更新
      data.analyses = updatedAnalyses;

      // コストを更新
      if (result.debug?.cost) {
        setModificationCosts((prev) => ({
          ...prev,
          [analysisId]: result.debug.cost,
        }));
        updateTotalCost(result.debug.cost);
      }
    } catch (error) {
      console.error('分析修正中にエラーが発生:', error);
    } finally {
      setIsModifying({ ...isModifying, [analysisId]: false });
    }
  };

  const handleAddAnalysis = async () => {
    setIsAddingAnalysis(true);
    try {
      const result = await analysisService.addAnalysis({
        message_id,
        query: addAnalysisQuery,
        message,
        analysis_options,
        existing_analyses: data.analyses,
      });

      // コストを更新
      if (result.debug?.cost) {
        updateTotalCost(result.debug.cost);
      }

      // 親コンポーネントに新しい分析を通知
      if (onAnalysisAdd) {
        onAnalysisAdd(result.analysis);
      }

      // 入力をクリア
      setAddAnalysisQuery('');
    } catch (error) {
      console.error('分析の追加中にエラーが発生:', error);
    } finally {
      setIsAddingAnalysis(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <FormatListBulletedIcon color="primary" />
          <Typography variant="h6">分析計画を生成中...</Typography>
        </Box>
        <LinearProgress
          sx={{
            height: 8,
            borderRadius: 4,
            mb: 3,
            bgcolor: 'rgba(0, 0, 0, 0.1)',
            '& .MuiLinearProgress-bar': {
              borderRadius: 4,
            },
          }}
        />
        <Stack spacing={3}>
          {[...Array(3)].map((_, index) => (
            <Paper key={index} sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Skeleton variant="text" width="60%" height={32} />
                <Skeleton variant="text" width="40%" />
                <Box sx={{ pl: 2 }}>
                  <Stack spacing={1}>
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="70%" />
                    <Skeleton variant="text" width="75%" />
                  </Stack>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Skeleton variant="rounded" width={80} height={24} />
                  <Skeleton variant="rounded" width={80} height={24} />
                </Box>
              </Stack>
            </Paper>
          ))}
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        分析計画の生成中にエラーが発生しました: {error.message}
      </Alert>
    );
  }

  if (!data?.analyses) return null;

  return (
    <>
      {data.analyses.map((analysis, analysisIndex) => {
        const analysisId = analysis.analysis_id;
        return (
          <Accordion key={analysisId}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  width: '100%',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', flexGrow: 1 }}
                >
                  {analysisIndex + 1}. {analysis.name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {modificationCosts[analysisId] && (
                    <Typography variant="caption" color="text.secondary">
                      修正コスト:{' '}
                      {formatCostInJPY(modificationCosts[analysisId])}
                    </Typography>
                  )}
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setModifyDialogOpen({
                        ...modifyDialogOpen,
                        [analysisId]: true,
                      });
                    }}
                    sx={{ mr: 2 }}
                  >
                    <AutoFixHighIcon />
                  </IconButton>
                  <Chip
                    label={analysis.phase_name}
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ fontWeight: 'medium' }}
                  />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{ display: 'flex', gap: 3, mb: 3, overflow: 'auto', pb: 2 }}
              >
                {/* 目的 */}
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    bgcolor: 'grey.50',
                    minWidth: 300,
                    flex: 1,
                  }}
                >
                  <SectionTitle
                    icon={<AssignmentIcon color="primary" />}
                    title="目的"
                  />
                  <Typography variant="body1">{analysis.purpose}</Typography>
                </Paper>

                {/* フェーズ */}
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    bgcolor: 'grey.50',
                    minWidth: 300,
                    flex: 1,
                  }}
                >
                  <SectionTitle
                    icon={<TimelineIcon color="primary" />}
                    title="フェーズ"
                  />
                  <Typography variant="body1">
                    {analysis.phase_description}
                  </Typography>
                </Paper>

                {/* 分析内容 */}
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    bgcolor: 'grey.50',
                    minWidth: 300,
                    flex: 1,
                  }}
                >
                  <SectionTitle
                    icon={<BarChartIcon color="primary" />}
                    title="分析内容"
                  />
                  <Box sx={{ pl: 2 }}>
                    <Typography variant="body1" gutterBottom>
                      対象指標: {analysis.target_metrics.join(', ')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      分析軸: {analysis.dimensions.join(', ')}
                    </Typography>
                    {analysis.aggregation && (
                      <Typography variant="body1" gutterBottom>
                        集計方法: {analysis.aggregation}
                      </Typography>
                    )}
                    {analysis.filters && (
                      <Typography variant="body1" gutterBottom>
                        フィルター条件: {analysis.filters.join(', ')}
                      </Typography>
                    )}
                  </Box>
                </Paper>

                {/* 出力項目 */}
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    bgcolor: 'grey.50',
                    minWidth: 300,
                    flex: 1,
                  }}
                >
                  <SectionTitle
                    icon={<TableChartIcon color="primary" />}
                    title="出力項目"
                  />
                  <Box sx={{ pl: 2 }}>
                    {analysis.output_columns.map((col, idx) => (
                      <Typography key={idx} variant="body1" gutterBottom>
                        {col.name}: {col.description}
                      </Typography>
                    ))}
                  </Box>
                </Paper>

                {/* 想定される洞察 */}
                {analysis.insights && analysis.insights.length > 0 && (
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      bgcolor: 'grey.50',
                      minWidth: 300,
                      flex: 1,
                    }}
                  >
                    <SectionTitle
                      icon={<LightbulbIcon color="primary" />}
                      title="想定される洞察"
                    />
                    {analysis.insights.map((insight, idx) => (
                      <Box key={idx} sx={{ pl: 2, mb: 2 }}>
                        <Typography variant="body1" gutterBottom>
                          結果: {insight.result}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          解釈: {insight.interpretation}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          仮説: {insight.hypothesis}
                        </Typography>
                      </Box>
                    ))}
                  </Paper>
                )}
              </Box>

              {/* 次のステップ（既存のまま） */}
              {analysis.next_analyses && analysis.next_analyses.length > 0 && (
                <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.50' }}>
                  <SectionTitle
                    icon={<ArrowForwardIcon color="primary" />}
                    title="次のステップ"
                  />
                  {analysis.next_analyses.map((next, idx) => (
                    <Typography
                      key={idx}
                      variant="body1"
                      gutterBottom
                      sx={{ pl: 2 }}
                    >
                      分析 {next.analysis_id}: {next.reason}
                    </Typography>
                  ))}
                </Paper>
              )}

              {/* 関連テンプレート */}
              <Box sx={{ mt: 2 }}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'bold', flexGrow: 1 }}
                      >
                        関連テンプレート
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        {searchResults[analysisId]?.debug?.cost && (
                          <Typography variant="caption" color="text.secondary">
                            検索コスト:{' '}
                            {formatCostInJPY(
                              searchResults[analysisId].debug.cost,
                            )}
                          </Typography>
                        )}
                        <Button
                          variant="outlined"
                          startIcon={<SearchIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSearchTemplates(analysis);
                          }}
                          disabled={isSearching[analysisId]}
                          sx={{ mr: 2 }}
                        >
                          {isSearching[analysisId]
                            ? '検索中...'
                            : 'テンプレートを検索'}
                        </Button>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isSearching[analysisId] && (
                      <Box sx={{ width: '100%', mb: 2 }}>
                        <LinearProgress
                          variant="determinate"
                          value={searchProgress[analysisId] || 0}
                          sx={{
                            height: 4,
                            borderRadius: 2,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            '& .MuiLinearProgress-bar': {
                              borderRadius: 2,
                            },
                          }}
                        />
                      </Box>
                    )}
                    {searchResults[analysisId] && (
                      <TemplateSearchResults
                        data={searchResults[analysisId]}
                        isLoading={false}
                        error={null}
                        hideRelevanceScore={false}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>

              {/* SQL */}
              <Box sx={{ mt: 2 }}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'bold', flexGrow: 1 }}
                      >
                        SQL
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        {sqlGenerationCosts[analysisId] && (
                          <Typography variant="caption" color="text.secondary">
                            生成コスト:{' '}
                            {formatCostInJPY(sqlGenerationCosts[analysisId])}
                          </Typography>
                        )}
                        <Button
                          variant="outlined"
                          startIcon={<CodeIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleGenerateSql(analysis);
                          }}
                          disabled={
                            isGeneratingSql[analysisId] ||
                            !searchResults[analysisId]?.templates.length
                          }
                          size="small"
                          sx={{ color: 'primary.main' }}
                        >
                          {isGeneratingSql[analysisId]
                            ? 'SQL生成中...'
                            : 'SQLを生成'}
                        </Button>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isGeneratingSql[analysisId] && (
                      <Box sx={{ width: '100%', mb: 2 }}>
                        <LinearProgress
                          variant="determinate"
                          value={sqlProgress[analysisId] || 0}
                          sx={{
                            height: 4,
                            borderRadius: 2,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            '& .MuiLinearProgress-bar': {
                              borderRadius: 2,
                            },
                          }}
                        />
                      </Box>
                    )}
                    {sqlDebugInfo[analysisId] && (
                      <Box sx={{ mb: 2 }}>
                        <SearchDebugInfo
                          prompt={sqlDebugInfo[analysisId].prompt}
                          response={sqlDebugInfo[analysisId].response}
                        />
                      </Box>
                    )}
                    <SqlEditor
                      sql={sqlResults[analysisId] || ''}
                      analysisId={analysisId}
                      message_id={message_id}
                      templateIds={searchResults[analysisId]?.templates.map(
                        (t) => t.template_id,
                      )}
                      analysis={{
                        purpose: analysis.purpose,
                        target_metrics: analysis.target_metrics,
                        dimensions: analysis.dimensions,
                      }}
                      onSave={() => {
                        queryClient.invalidateQueries({
                          queryKey: ['sql', message_id, analysisId],
                        });
                      }}
                      onExecutionResult={(result) => {
                        setExecutionResults((prev) => ({
                          ...prev,
                          [analysisId]: result,
                        }));
                      }}
                      variables={variables}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>

              {/* 実行結果の表示 */}
              {executionResults[analysisId] && (
                <Box sx={{ mt: 2 }}>
                  <SectionTitle
                    icon={<TableChartIcon sx={{ color: 'primary.main' }} />}
                    title="実行結果"
                  />
                  <Alert severity="info">
                    <Stack spacing={1}>
                      <Typography>
                        実行時間:{' '}
                        {executionResults[analysisId].execution_time.toFixed(2)}
                        秒
                      </Typography>
                      <Typography>
                        スキャン量:{' '}
                        {formatBytes(executionResults[analysisId].scan_bytes)}
                      </Typography>
                      {executionResults[analysisId].is_cached && (
                        <Typography>※ キャッシュされた結果です</Typography>
                      )}
                    </Stack>
                  </Alert>
                  <TableContainer sx={{ mt: 2, maxHeight: 400 }}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          {executionResults[analysisId].columns.map(
                            (column) => (
                              <TableCell key={column}>{column}</TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {executionResults[analysisId].rows.map((row, i) => (
                          <TableRow key={i}>
                            {executionResults[analysisId].columns.map(
                              (column) => (
                                <TableCell key={column}>
                                  {(() => {
                                    const value = row[column];
                                    if (typeof value === 'number') {
                                      return value.toLocaleString();
                                    }
                                    return value;
                                  })()}
                                </TableCell>
                              ),
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              {/* 可視化 */}
              <Box sx={{ mt: 2 }}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          flexGrow: 1,
                        }}
                      >
                        <CodeIcon color="primary" />
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 'bold' }}
                        >
                          可視化コード
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<BarChartIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleGenerateVisualization(analysis);
                          }}
                          disabled={
                            isGeneratingVisualization[analysisId] ||
                            !executionResults[analysisId]
                          }
                        >
                          {isGeneratingVisualization[analysisId]
                            ? '生成中...'
                            : '可視化を生成'}
                        </Button>
                        {visualizationResults[analysisId] && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              analysisService.updateVisualizationCode({
                                message_id,
                                analysis_id: analysisId,
                                code: visualizationResults[analysisId].code,
                                library:
                                  visualizationResults[analysisId].library,
                              });
                            }}
                            color="primary"
                            size="small"
                            title="コードを保存"
                          >
                            <SaveIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {visualizationResults[analysisId] && (
                      <Editor
                        height="400px"
                        defaultLanguage="javascript"
                        value={visualizationResults[analysisId].code}
                        options={{
                          minimap: { enabled: false },
                          scrollBeyondLastLine: false,
                          wordWrap: 'on',
                          wrappingIndent: 'indent',
                        }}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>

                {/* 可視化結果とコントロール */}
                {visualizationResults[analysisId] && (
                  <Box sx={{ mt: 2 }}>
                    <Paper sx={{ p: 2 }}>
                      <VisualizationEditor
                        code={visualizationResults[analysisId].code}
                        library={visualizationResults[analysisId].library}
                        analysisId={analysisId}
                        message_id={message_id}
                        purpose={analysis.purpose}
                        target_metrics={analysis.target_metrics}
                        dimensions={analysis.dimensions}
                        sql_result={executionResults[analysisId]}
                        showCodeEditor={false}
                      />

                      <Box
                        sx={{
                          mt: 1,
                          display: 'flex',
                          gap: 2,
                          alignItems: 'center',
                        }}
                      >
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="カスタムの修正内容を入力してください"
                          value={
                            visualizationResults[analysisId]?.fixRequest || ''
                          }
                          onChange={(e) => {
                            setVisualizationResults((prev) => ({
                              ...prev,
                              [analysisId]: {
                                ...prev[analysisId],
                                fixRequest: e.target.value,
                              },
                            }));
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            const request =
                              visualizationResults[
                                analysisId
                              ]?.fixRequest?.trim();
                            if (!request) return;
                            analysisService
                              .fixVisualizationCode({
                                message_id,
                                analysis_id: analysisId,
                                code: visualizationResults[analysisId].code,
                                library:
                                  visualizationResults[analysisId].library,
                                fix_request: request,
                                sql_result: executionResults[analysisId],
                                purpose: analysis.purpose,
                                target_metrics: analysis.target_metrics,
                                dimensions: analysis.dimensions,
                              })
                              .then((result) => {
                                setVisualizationResults((prev) => ({
                                  ...prev,
                                  [analysisId]: {
                                    ...result,
                                    fixRequest: '',
                                  },
                                }));
                              });
                          }}
                          color="primary"
                          title="コードを修正"
                        >
                          <AutoFixHighIcon />
                        </IconButton>
                      </Box>
                    </Paper>
                  </Box>
                )}
              </Box>

              {/* ディスカッション */}
              {executionResults[analysisId] && (
                <Box sx={{ mt: 2 }}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          width: '100%',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 'bold', flexGrow: 1 }}
                        >
                          ディスカッション編集
                        </Typography>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                        >
                          <Button
                            variant="outlined"
                            startIcon={<AutoFixHighIcon />}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!executionResults[analysisId]) return;
                              analysisService
                                .generateDiscussion({
                                  message_id,
                                  analysis_id: analysisId,
                                  sql_result: executionResults[analysisId],
                                })
                                .then((result) => {
                                  setDiscussionResults((prev) => ({
                                    ...prev,
                                    [analysisId]: {
                                      discussion: result.discussion,
                                      fixRequest: '',
                                    },
                                  }));
                                });
                            }}
                            disabled={!executionResults[analysisId]}
                          >
                            ディスカッションを生成
                          </Button>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ mb: 2 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={8}
                          value={
                            discussionResults[analysisId]?.discussion || ''
                          }
                          onChange={(e) => {
                            setDiscussionResults((prev) => ({
                              ...prev,
                              [analysisId]: {
                                ...prev[analysisId],
                                discussion: e.target.value,
                              },
                            }));
                          }}
                          placeholder="ディスカッションを入力してください"
                        />
                      </Box>

                      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="カスタムの修正内容を入力してください"
                          value={
                            discussionResults[analysisId]?.fixRequest || ''
                          }
                          onChange={(e) => {
                            setDiscussionResults((prev) => ({
                              ...prev,
                              [analysisId]: {
                                ...prev[analysisId],
                                fixRequest: e.target.value,
                              },
                            }));
                          }}
                        />
                        <IconButton
                          onClick={async () => {
                            const fixRequest =
                              discussionResults[analysisId]?.fixRequest?.trim();
                            if (!fixRequest || !executionResults[analysisId])
                              return;

                            try {
                              const result =
                                await analysisService.fixDiscussion({
                                  message_id,
                                  analysis_id: analysisId,
                                  discussion:
                                    discussionResults[analysisId].discussion,
                                  fix_request: fixRequest,
                                  sql_result: executionResults[analysisId],
                                });

                              setDiscussionResults((prev) => ({
                                ...prev,
                                [analysisId]: {
                                  discussion: result.discussion,
                                  fixRequest: '',
                                },
                              }));
                            } catch (error) {
                              console.error(
                                'ディスカッションの修正中にエラーが発生:',
                                error,
                              );
                            }
                          }}
                          disabled={
                            !discussionResults[analysisId]?.fixRequest?.trim()
                          }
                          color="primary"
                          title="ディスカッションを修正"
                        >
                          <AutoFixHighIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (!discussionResults[analysisId]?.discussion)
                              return;
                            analysisService.updateDiscussion({
                              message_id,
                              analysis_id: analysisId,
                              discussion:
                                discussionResults[analysisId].discussion,
                            });
                          }}
                          disabled={!discussionResults[analysisId]?.discussion}
                          color="primary"
                          title="ディスカッションを保存"
                        >
                          <SaveIcon />
                        </IconButton>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  {/* ディスカッションのプレビュー */}
                  {discussionResults[analysisId]?.discussion && (
                    <Box sx={{ mt: 2 }}>
                      <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                          ディスカッション
                        </Typography>
                        <Box
                          sx={{
                            '& > *': { mb: 2 },
                            '& h1': { fontSize: '1.5rem', fontWeight: 'bold' },
                            '& h2': { fontSize: '1.25rem', fontWeight: 'bold' },
                            '& h3': { fontSize: '1.1rem', fontWeight: 'bold' },
                            '& ul, & ol': { pl: 3 },
                            '& code': {
                              bgcolor: 'grey.100',
                              p: 0.5,
                              borderRadius: 1,
                              fontFamily: 'monospace',
                            },
                          }}
                        >
                          <ReactMarkdown>
                            {discussionResults[analysisId].discussion}
                          </ReactMarkdown>
                        </Box>
                      </Paper>
                    </Box>
                  )}
                </Box>
              )}

              {/* 分析の修正ダイアログ */}
              <Dialog
                open={modifyDialogOpen[analysisId] || false}
                onClose={() =>
                  setModifyDialogOpen({
                    ...modifyDialogOpen,
                    [analysisId]: false,
                  })
                }
                maxWidth="md"
                fullWidth
              >
                <DialogTitle>分析の修正</DialogTitle>
                <DialogContent>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={modifyQuery[analysisId] || ''}
                    onChange={(e) =>
                      setModifyQuery({
                        ...modifyQuery,
                        [analysisId]: e.target.value,
                      })
                    }
                    placeholder="分析の修正内容を入力してください"
                    sx={{ mt: 2 }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      setModifyDialogOpen({
                        ...modifyDialogOpen,
                        [analysisId]: false,
                      })
                    }
                  >
                    キャンセル
                  </Button>
                  <Button
                    onClick={() => handleModifyAnalysis(analysis)}
                    variant="contained"
                    disabled={isModifying[analysisId]}
                  >
                    {isModifying[analysisId] ? '修正中...' : '修正'}
                  </Button>
                </DialogActions>
              </Dialog>
            </AccordionDetails>
          </Accordion>
        );
      })}

      {/* 分析の追加 */}
      <Box sx={{ mt: 3, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <AddCircleOutlineIcon color="primary" />
          <Typography variant="h6">分析の追加</Typography>
        </Box>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={addAnalysisQuery}
          onChange={(e) => setAddAnalysisQuery(e.target.value)}
          placeholder="追加したい分析の内容を入力してください"
        />
        <Button
          variant="contained"
          onClick={handleAddAnalysis}
          disabled={isAddingAnalysis || !addAnalysisQuery.trim()}
          sx={{ mt: 2 }}
        >
          {isAddingAnalysis ? '追加中...' : '分析を追加'}
        </Button>
      </Box>
    </>
  );
};
