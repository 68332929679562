import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { GptContextService } from '../services/gptContext.service';
import { SearchType } from '../services/types';

const INITIAL_DISPLAY_COUNT = 10;
const LOAD_MORE_COUNT = 30;
const NOTION_BASE_URL = 'https://www.notion.so/insightx-tech/';

interface FreeWordSearchProps {
  onSaveSession: (sessionName: string, selectedResults: any[]) => void;
}

export const FreeWordSearch: React.FC<FreeWordSearchProps> = ({
  onSaveSession,
}) => {
  const [searchType, setSearchType] = useState<SearchType>(
    SearchType.DISCUSSION,
  );
  const [keyword, setKeyword] = useState('');
  const [allResults, setAllResults] = useState<any[]>([]);
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [displayCount, setDisplayCount] = useState(INITIAL_DISPLAY_COUNT);
  const [selectedResults, setSelectedResults] = useState<any[]>([]);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [sessionName, setSessionName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // 検索タイプが変更されたときに全件取得
  useEffect(() => {
    const fetchAllResults = async () => {
      setIsLoading(true);
      try {
        const results = await GptContextService.getAllByType(searchType);
        setAllResults(results);
        setFilteredResults(results);
        setDisplayCount(INITIAL_DISPLAY_COUNT);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllResults();
  }, [searchType]);

  // キーワード検索の最適化
  const debouncedSearch = useCallback(
    debounce((searchKeyword: string, results: any[]) => {
      if (!searchKeyword) {
        setFilteredResults(results);
        return;
      }

      const searchInProperties = (result: any) => {
        const searchTarget = JSON.stringify(result).toLowerCase();
        return searchTarget.includes(searchKeyword.toLowerCase());
      };

      const filtered = results.filter(searchInProperties);
      setFilteredResults(filtered);
      setDisplayCount(INITIAL_DISPLAY_COUNT);
    }, 500),
    [],
  );

  // キーワードが変更されたときの処理
  useEffect(() => {
    debouncedSearch(keyword, allResults);
    return () => {
      debouncedSearch.cancel();
    };
  }, [keyword, allResults, debouncedSearch]);

  // 結果の選択を処理
  const handleResultSelect = (result: any) => {
    setSelectedResults((prev) => {
      if (prev.some((r) => r.id === result.id)) {
        return prev.filter((r) => r.id !== result.id);
      }
      return [...prev, result];
    });
  };

  // セッションの保存を処理
  const handleSave = () => {
    if (sessionName && selectedResults.length > 0) {
      onSaveSession(sessionName, selectedResults);
      setSaveDialogOpen(false);
      setSessionName('');
      setSelectedResults([]);
    }
  };

  // テキスト内のキーワードをハイライト
  const highlightText = (text: string) => {
    if (!keyword || !text) return text;
    const regex = new RegExp(`(${keyword})`, 'gi');
    return text.split(regex).map((part, i) =>
      regex.test(part) ? (
        <span key={i} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  // さらに読み込むボタンの表示制御
  const hasMore = filteredResults.length > displayCount;

  // さらに読み込むボタンのクリックハンドラ
  const handleLoadMore = () => {
    setDisplayCount((prev) => prev + LOAD_MORE_COUNT);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        フリーワード検索
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>検索タイプ</InputLabel>
          <Select
            value={searchType}
            label="検索タイプ"
            onChange={(e) => setSearchType(e.target.value as SearchType)}
          >
            <MenuItem value={SearchType.DISCUSSION}>ディスカッション</MenuItem>
            <MenuItem value={SearchType.ANALYSIS}>分析</MenuItem>
            <MenuItem value={SearchType.MEETING}>ミーティング</MenuItem>
            <MenuItem value={SearchType.SLACK}>Slack</MenuItem>
            <MenuItem value={SearchType.SQL_TEMPLATE}>SQLテンプレート</MenuItem>
            <MenuItem value={SearchType.ANALYSIS_TABLE}>分析テーブル</MenuItem>
            <MenuItem value={SearchType.ANALYSIS_TEMPLATE}>
              分析テンプレート
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="キーワード"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <Button
          variant="contained"
          disabled={selectedResults.length === 0}
          onClick={() => setSaveDialogOpen(true)}
        >
          選択項目を保存
        </Button>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          {filteredResults.slice(0, displayCount).map((result) => (
            <Box
              key={result.id}
              sx={{
                p: 2,
                mb: 2,
                border: '1px solid #e0e0e0',
                borderRadius: 1,
                display: 'flex',
                alignItems: 'flex-start',
                backgroundColor: '#fff',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                transition: 'box-shadow 0.2s',
                '&:hover': {
                  boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                },
              }}
            >
              <Checkbox
                checked={selectedResults.some((r) => r.id === result.id)}
                onChange={() => handleResultSelect(result)}
              />
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <DescriptionIcon
                    sx={{ mr: 1, color: 'primary.main', fontSize: 20 }}
                  />
                  <Link
                    href={`${NOTION_BASE_URL}${result.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'primary.main',
                      textDecoration: 'none',
                      fontWeight: 600,
                      fontSize: '1.1rem',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {highlightText(result.title || '無題')}
                  </Link>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                    color: 'text.secondary',
                  }}
                >
                  <EventIcon sx={{ mr: 0.5, fontSize: 16 }} />
                  <Typography variant="body2">
                    作成日: {result.created_at}
                  </Typography>
                </Box>
                {Object.entries(result.properties || {}).map(([key, value]) => (
                  <Typography
                    key={key}
                    variant="body2"
                    sx={{ color: 'text.secondary', mb: 0.5 }}
                  >
                    {key}: {highlightText(value as string)}
                  </Typography>
                ))}
                {result.summary && (
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      p: 1,
                      backgroundColor: 'grey.50',
                      borderRadius: 1,
                    }}
                  >
                    サマリー: {highlightText(result.summary)}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
          {hasMore && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="outlined"
                onClick={handleLoadMore}
                startIcon={<ExpandMoreIcon />}
              >
                さらに{LOAD_MORE_COUNT}件を表示
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
        <DialogTitle>検索結果の保存</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="セッション名"
            fullWidth
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleSave} variant="contained">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FreeWordSearch;
