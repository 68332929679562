import AnalyticsIcon from '@mui/icons-material/Analytics';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { analysisService } from '../../services/analysis-service';
import { AnalysisDetails } from './AnalysisDetails';
import AnalysisOptionsSelector from './AnalysisOptionsSelector';
import { AnalysisVariables } from './AnalysisVariables';
import { ReportSearchResults } from './ReportSearchResults';
import { TemplateSearchResults } from './TemplateSearchResults';
import { LLMModel, SelectedAnalysisOptions } from './types';

// 進捗バーのカスタムフック
const useProgressBar = (isLoading: boolean, duration: number = 20000) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isLoading) {
      setProgress(100);
      return;
    }

    setProgress(0);
    const startTime = Date.now();
    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const newProgress = Math.min((elapsedTime / duration) * 95, 95); // 95%まで
      setProgress(newProgress);
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [isLoading, duration]);

  return progress;
};

const AnalysisPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputQuery, setInputQuery] = useState('');
  const [confirmedMessage, setConfirmedMessage] = useState('');
  const [messageId, setMessageId] = useState(
    searchParams.get('message_id') || '',
  );
  const [isSearching, setIsSearching] = useState(
    !!searchParams.get('message_id'),
  );
  const [selectedModel, setSelectedModel] = useState<LLMModel>(
    LLMModel.DEEPSEEK_CHAT,
  );
  const [selectedAnalysisOptions, setSelectedAnalysisOptions] =
    useState<SelectedAnalysisOptions>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [analysisModificationCosts, setAnalysisModificationCosts] =
    useState<number>(0);

  const queryClient = useQueryClient();

  // URLパラメータが変更された時の処理
  useEffect(() => {
    const messageIdParam = searchParams.get('message_id');
    if (messageIdParam !== null) {
      setMessageId(messageIdParam);
      setIsSearching(true);

      // message_idからメッセージを取得
      const fetchMessage = async () => {
        try {
          const { message, analysis_options } =
            await analysisService.getMessage(messageIdParam);
          setInputQuery(message);
          setConfirmedMessage(message);
          if (analysis_options) {
            setSelectedAnalysisOptions(analysis_options);
          }
        } catch (error) {
          console.error('メッセージの取得に失敗:', error);
          setIsSearching(false);
        }
      };
      fetchMessage();
    }
  }, [searchParams]);

  // 検索履歴の取得
  const { data: searchHistory = [] } = useQuery({
    queryKey: ['search-history'],
    queryFn: analysisService.getSearchHistory,
  });

  // テンプレート検索のmutation
  const searchTemplatesMutation = useMutation({
    mutationFn: ({
      message_id,
      model_name,
      skip_cache,
    }: {
      message_id: string;
      model_name?: string;
      skip_cache?: boolean;
    }) =>
      analysisService.searchAnalysisTemplates(
        message_id,
        model_name,
        skip_cache,
      ),
  });

  // レポート検索のmutation
  const searchReportsMutation = useMutation({
    mutationFn: ({
      message_id,
      model_name,
      skip_cache,
    }: {
      message_id: string;
      model_name?: string;
      skip_cache?: boolean;
    }) =>
      analysisService.searchAnalysisReports(message_id, model_name, skip_cache),
  });

  // テンプレート検索のクエリ（リロード時用）
  const {
    data: templateData,
    isLoading: isTemplateLoading,
    error: templateError,
  } = useQuery({
    queryKey: ['templates', messageId, selectedModel],
    queryFn: () =>
      analysisService.searchAnalysisTemplates(messageId, selectedModel),
    enabled: !!messageId && isSearching,
    retry: false,
  });

  // レポート検索のクエリ（リロード時用）
  const {
    data: reportData,
    isLoading: isReportLoading,
    error: reportError,
  } = useQuery({
    queryKey: ['reports', messageId, selectedModel],
    queryFn: () =>
      analysisService.searchAnalysisReports(messageId, selectedModel),
    enabled: !!messageId && isSearching,
    retry: false,
  });

  // 分析詳細の取得
  const { mutateAsync: getAnalysisDetails } = useMutation({
    mutationFn: analysisService.getAnalysisDetails,
  });

  // 分析詳細の取得
  const {
    data: analysisData,
    isLoading: isAnalysisLoading,
    error: analysisError,
  } = useQuery({
    queryKey: [
      'analysis-details',
      confirmedMessage,
      templateData?.templates,
      reportData?.reports,
    ],
    queryFn: () => {
      if (!templateData || !reportData) return null;
      return getAnalysisDetails({
        query: confirmedMessage,
        template_ids: templateData.templates.map((t) => t.template_id),
        report_ids: reportData.reports.map((r) => r.report_id),
        message_id: messageId,
      });
    },
    enabled:
      !!confirmedMessage && isSearching && !!templateData && !!reportData,
    retry: false,
  });

  // 変数の状態管理
  const { data: variables = [], isLoading: isVariablesLoading } = useQuery({
    queryKey: ['analysis-variables', messageId],
    queryFn: () => analysisService.getAnalysisVariables(messageId),
    enabled: !!messageId,
  });

  // 変数の保存
  const { mutate: saveVariable } = useMutation({
    mutationFn: analysisService.saveAnalysisVariable,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['analysis-variables', messageId],
      });
    },
  });

  // 変数の更新
  const { mutate: updateVariable } = useMutation({
    mutationFn: analysisService.updateAnalysisVariable,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['analysis-variables', messageId],
      });
    },
  });

  // 変数の削除
  const { mutate: deleteVariable } = useMutation({
    mutationFn: analysisService.deleteAnalysisVariable,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['analysis-variables', messageId],
      });
    },
  });

  const handleSaveVariable = (name: string, value: string) => {
    if (!messageId) return;
    saveVariable({
      message_id: messageId,
      name,
      value,
    });
  };

  const handleUpdateVariable = (name: string, value: string) => {
    if (!messageId) return;
    updateVariable({
      message_id: messageId,
      name,
      value,
    });
  };

  const handleDeleteVariable = (name: string) => {
    if (!messageId) return;
    deleteVariable({
      message_id: messageId,
      name,
    });
  };

  // 分析オプションが変更されたときのハンドラ
  const handleAnalysisOptionsChange = (options: SelectedAnalysisOptions) => {
    setSelectedAnalysisOptions(options);
  };

  const showErrorMessage = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputQuery.trim()) return;

    setIsSearching(true);
    try {
      // メッセージIDを取得
      const messageId = await analysisService.createMessage(
        inputQuery.trim(),
        selectedAnalysisOptions,
        true, // 検索ボタン押下時は常にキャッシュをスキップ
      );
      setConfirmedMessage(inputQuery.trim());
      setMessageId(messageId);

      // URLパラメータを更新
      setSearchParams({ message_id: messageId });

      // テンプレートを検索
      await searchTemplatesMutation.mutateAsync({
        message_id: messageId,
        model_name: selectedModel,
        skip_cache: false,
      });

      // レポートを検索
      await searchReportsMutation.mutateAsync({
        message_id: messageId,
        model_name: selectedModel,
        skip_cache: false,
      });
    } catch (error) {
      console.error('検索中にエラーが発生しました:', error);
      showErrorMessage('検索中にエラーが発生しました');
    } finally {
      setIsSearching(false);
    }
  };

  // 円表示用のヘルパー関数
  const formatCostInJPY = (costInUSD?: number) => {
    if (!costInUSD) return '0円';
    return `${(Math.round(costInUSD * 150 * 100) / 100).toFixed(2)}円`;
  };

  const handleAnalysisCostUpdate = (newCost: number) => {
    setAnalysisModificationCosts((prev) => prev + newCost);
  };

  // トータルコストの計算
  const totalCost = useMemo(() => {
    const total =
      (templateData?.cost || 0) +
      (reportData?.cost || 0) +
      (analysisData?.debug?.cost || 0) +
      analysisModificationCosts;
    return total > 0 ? total : undefined;
  }, [
    templateData?.cost,
    reportData?.cost,
    analysisData?.debug?.cost,
    analysisModificationCosts,
  ]);

  // 進捗バーの状態管理
  const templateProgress = useProgressBar(isTemplateLoading);
  const reportProgress = useProgressBar(isReportLoading);

  const handleHistorySelect = async (message_id: string) => {
    try {
      const { message, analysis_options } =
        await analysisService.getMessage(message_id);
      setInputQuery(message);
      setConfirmedMessage(message);
      setMessageId(message_id);
      setIsSearching(true);
      if (analysis_options) {
        setSelectedAnalysisOptions(analysis_options);
      }
      // URLパラメータを更新
      setSearchParams({ message_id });
    } catch (error) {
      console.error('検索履歴の取得中にエラーが発生しました:', error);
      showErrorMessage('検索履歴の取得中にエラーが発生しました');
    }
  };

  return (
    <Box
      sx={{ width: '100%', minHeight: '100vh', bgcolor: 'background.default' }}
    >
      <Box sx={{ p: 4 }}>
        <Paper
          component="form"
          onSubmit={handleSearch}
          sx={{ p: 2, mb: 4, bgcolor: '#fff' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <AnalyticsIcon sx={{ color: 'primary.main', fontSize: 28 }} />
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ fontWeight: 700 }}
            >
              やりたい分析を入力
            </Typography>
          </Box>

          <AnalysisOptionsSelector
            onOptionsChange={handleAnalysisOptionsChange}
            selectedOptions={selectedAnalysisOptions}
          />

          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              fullWidth
              value={inputQuery}
              onChange={(e) => {
                setInputQuery(e.target.value);
                // 検索状態をリセット
                setIsSearching(false);
              }}
              placeholder="例: 商品カテゴリ別の売上分析をしたい"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      disabled={!inputQuery.trim()}
                      color="primary"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="model-select-label">LLMモデル</InputLabel>
              <Select
                labelId="model-select-label"
                value={selectedModel}
                label="LLMモデル"
                onChange={(e) => setSelectedModel(e.target.value as LLMModel)}
              >
                <MenuItem value={LLMModel.DEEPSEEK_CHAT}>
                  DeepSeek (デフォルト)
                </MenuItem>
                <MenuItem value={LLMModel.CLAUDE_3_SONNET}>
                  Claude 3 Sonnet
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {searchHistory.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <HistoryIcon fontSize="small" color="action" sx={{ ml: 1 }} />
                <Typography variant="subtitle2" color="text.secondary">
                  検索履歴
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  overflowX: 'auto',
                  pb: 2,
                  '&::-webkit-scrollbar': {
                    height: 8,
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: 4,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: 4,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    },
                  },
                }}
              >
                {searchHistory.map((item) => (
                  <MenuItem
                    key={item.message_id}
                    onClick={() => handleHistorySelect(item.message_id)}
                    sx={{ whiteSpace: 'normal', minHeight: 'auto', py: 1 }}
                  >
                    <Box>
                      <Typography variant="body2" sx={{ mb: 0.5 }}>
                        {item.message}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {new Date(item.timestamp).toLocaleString()}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Box>
            </Box>
          )}
        </Paper>

        <AnalysisVariables
          message_id={messageId}
          variables={variables}
          isLoading={isVariablesLoading}
          onSave={handleSaveVariable}
          onUpdate={handleUpdateVariable}
          onDelete={handleDeleteVariable}
        />

        {isSearching && (
          <>
            {totalCost !== undefined && (
              <Box sx={{ mb: 2 }}>
                <Paper
                  sx={{
                    p: 2,
                    bgcolor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ mb: 0.5 }}
                    >
                      LLMコールにかかったコスト
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      sx={{ fontWeight: 700 }}
                    >
                      {formatCostInJPY(totalCost)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        テンプレート検索
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {formatCostInJPY(templateData?.cost)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        レポート検索
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {formatCostInJPY(reportData?.cost)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        分析詳細生成
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {formatCostInJPY(analysisData?.debug?.cost)}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            )}

            <Paper sx={{ mb: 4, bgcolor: '#fff' }}>
              <Box
                sx={{
                  p: 2,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FindInPageIcon
                    sx={{ color: 'primary.main', fontSize: 28 }}
                  />
                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={{ fontWeight: 700 }}
                  >
                    関連ドキュメント検索結果
                  </Typography>
                </Box>
                <Tooltip title="すべての結果をコピー">
                  <IconButton
                    onClick={() => {
                      const templateText =
                        templateData?.templates
                          .map(
                            (t: any) =>
                              `# ${t.name}\n分析タイプ: ${t.analysis_type}\n使用テーブル: ${t.used_tables}\n利用シーン: ${t.use_cases}\n\n## サマリ\n${t.summary}\n\n## SQL\n${t.sql}\n\n関連度: ${t.relevance_score}\n理由: ${t.reason}\n`,
                          )
                          .join('\n---\n') || '';

                      const reportText =
                        reportData?.reports
                          .map(
                            (r: any) =>
                              `# ${r.name}\n分析タイプ: ${r.analysis_type}\nクライアント: ${r.client}\n日付: ${r.date}\n\n## 目的\n${r.purpose}\n\n## 結論\n${r.conclusion}\n\n## 重要な発見\n${r.key_findings}\n\n## サマリ\n${r.summary}\n\n関連度: ${r.relevance_score}\n理由: ${r.reason}\n`,
                          )
                          .join('\n---\n') || '';

                      const allText = `# 分析テンプレート\n\n${templateText}\n\n# 分析レポート\n\n${reportText}`;
                      navigator.clipboard.writeText(allText);
                    }}
                    size="small"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>

              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <Typography>📋 分析テンプレート</Typography>
                        {templateData?.cost && (
                          <Chip
                            label={`コスト: ${formatCostInJPY(templateData.cost)}`}
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        )}
                      </Box>
                      <Tooltip title="テンプレート結果をコピー">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            const text =
                              templateData?.templates
                                .map(
                                  (t: any) =>
                                    `# ${t.name}\n分析タイプ: ${t.analysis_type}\n使用テーブル: ${t.used_tables}\n利用シーン: ${t.use_cases}\n\n## サマリ\n${t.summary}\n\n## SQL\n${t.sql}\n\n関連度: ${t.relevance_score}\n理由: ${t.reason}\n`,
                                )
                                .join('\n---\n') || '';
                            navigator.clipboard.writeText(text);
                          }}
                          size="small"
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    {isTemplateLoading && (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress
                          variant="determinate"
                          value={templateProgress}
                          sx={{
                            height: 4,
                            borderRadius: 2,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            '& .MuiLinearProgress-bar': {
                              borderRadius: 2,
                            },
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TemplateSearchResults
                    data={templateData}
                    isLoading={isTemplateLoading}
                    error={templateError}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <Typography>📊 分析レポート</Typography>
                        {reportData?.cost && (
                          <Chip
                            label={`コスト: ${formatCostInJPY(reportData.cost)}`}
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        )}
                      </Box>
                      <Tooltip title="レポート結果をコピー">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            const text =
                              reportData?.reports
                                .map(
                                  (r: any) =>
                                    `# ${r.name}\n分析タイプ: ${r.analysis_type}\nクライアント: ${r.client}\n日付: ${r.date}\n\n## 目的\n${r.purpose}\n\n## 結論\n${r.conclusion}\n\n## 重要な発見\n${r.key_findings}\n\n## サマリ\n${r.summary}\n\n関連度: ${r.relevance_score}\n理由: ${r.reason}\n`,
                                )
                                .join('\n---\n') || '';
                            navigator.clipboard.writeText(text);
                          }}
                          size="small"
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    {isReportLoading && (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress
                          variant="determinate"
                          value={reportProgress}
                          sx={{
                            height: 4,
                            borderRadius: 2,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            '& .MuiLinearProgress-bar': {
                              borderRadius: 2,
                            },
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <ReportSearchResults
                    data={reportData}
                    isLoading={isReportLoading}
                    error={reportError}
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Box sx={{ mt: 4 }}>
              <Paper sx={{ mb: 4, bgcolor: '#fff' }}>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FormatListBulletedIcon
                      sx={{ color: 'primary.main', fontSize: 28 }}
                    />
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 700 }}
                    >
                      分析一覧
                    </Typography>
                  </Box>
                </Box>

                <AnalysisDetails
                  data={analysisData}
                  isLoading={isAnalysisLoading}
                  error={analysisError}
                  message_id={messageId}
                  message={confirmedMessage}
                  analysis_options={selectedAnalysisOptions}
                  onCostUpdate={handleAnalysisCostUpdate}
                  variables={variables}
                />

                {analysisData?.debug && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <Typography>🔍 デバッグ情報</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        {/* プロンプト */}
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              mb: 1,
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 700 }}
                            >
                              プロンプト
                            </Typography>
                            <Tooltip title="プロンプトをコピー">
                              <IconButton
                                onClick={() => {
                                  if (analysisData?.debug?.prompt) {
                                    navigator.clipboard.writeText(
                                      analysisData.debug.prompt,
                                    );
                                  }
                                }}
                                size="small"
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{ p: 2, bgcolor: 'grey.50', borderRadius: 1 }}
                          >
                            <Typography
                              component="pre"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                fontFamily: 'monospace',
                                m: 0,
                              }}
                            >
                              {analysisData.debug.prompt || ''}
                            </Typography>
                          </Box>
                        </Box>

                        {/* LLMレスポンス（存在する場合のみ表示） */}
                        {analysisData.debug.response && (
                          <Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1,
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 700 }}
                              >
                                LLMレスポンス
                              </Typography>
                              <Tooltip title="レスポンスをコピー">
                                <IconButton
                                  onClick={() => {
                                    if (analysisData.debug.response) {
                                      navigator.clipboard.writeText(
                                        analysisData.debug.response,
                                      );
                                    }
                                  }}
                                  size="small"
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Box
                              sx={{ p: 2, bgcolor: 'grey.50', borderRadius: 1 }}
                            >
                              <Typography
                                component="pre"
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  wordBreak: 'break-word',
                                  fontFamily: 'monospace',
                                  m: 0,
                                }}
                              >
                                {analysisData.debug.response}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {/* コスト情報（存在する場合のみ表示） */}
                        {analysisData.debug.cost !== undefined && (
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                              コスト: {formatCostInJPY(analysisData.debug.cost)}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Paper>
            </Box>
          </>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AnalysisPage;
