import api from '../api';

interface IDeployToProductionRes {
  jobId: string;
}

export const deployToProduction = async (): Promise<IDeployToProductionRes> => {
  const res = await api.post('/shelf/deploy_to_production');
  return res.data;
};
