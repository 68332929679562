import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { ComponentMetricsV2 } from '../../services/component-service';

interface CustomPageTabsProps {
  pageTypes: string[];
  selectedPageType: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
  metrics: ComponentMetricsV2[];
}

const CustomPageTabs: React.FC<CustomPageTabsProps> = ({
  pageTypes,
  selectedPageType,
  onChange,
  metrics,
}) => {
  // InsightXコンポーネントを含むページタイプのみをフィルタリング
  const hasInsightX = (pageType: string): boolean => {
    return metrics.some(
      (m) =>
        m.page_name === pageType && m.component_name?.startsWith('InsightX'),
    );
  };

  // 表示数の合計を計算してソート用に使用
  const getPageTypeShownTotal = (pageType: string): number => {
    return metrics
      .filter(
        (m) =>
          m.page_name === pageType && m.component_name?.startsWith('InsightX'),
      )
      .reduce((sum, m) => sum + (m.shown || 0), 0);
  };

  // InsightXコンポーネントを含むページタイプのみを抽出し、表示数順にソート
  const filteredPageTypes = pageTypes
    .filter(hasInsightX)
    .sort((a, b) => getPageTypeShownTotal(b) - getPageTypeShownTotal(a));

  return (
    <Tabs
      value={selectedPageType}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: '#1976d2',
        },
      }}
    >
      {filteredPageTypes.map((pageType) => (
        <Tab
          key={pageType}
          value={pageType}
          label={pageType}
          sx={{
            textTransform: 'none',
            minWidth: 'auto',
            padding: '12px 16px',
            '&.Mui-selected': {
              color: '#1976d2',
            },
          }}
        />
      ))}
    </Tabs>
  );
};

export default CustomPageTabs;
