import api from '../../../services/api';
import {
  BuiltPrompt,
  CreatePromptModuleRequest,
  CreateTagRequest,
  PromptModule,
  SavePromptRequest,
  SavePromptResponse,
  SearchRequest,
  SearchResponse,
  SearchType,
  Tag,
  UpdatePromptModuleRequest,
  UpdateSessionMemoRequest,
  UpdateSessionPrePromptRequest,
  UpdateSessionTagsRequest,
} from './types';

export const GptContextService = {
  search: async (request: SearchRequest): Promise<SearchResponse> => {
    const response = await api.post<SearchResponse>(
      '/gpt-context/search',
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  },

  refresh: async (
    searchMessage: string,
    searchType: SearchType,
    channels?: string[],
  ): Promise<SearchResponse> => {
    const request: SearchRequest = {
      query: searchMessage,
      type: searchType,
      skip_cache: true,
      mode: 'llm',
      ...(searchType === 'slack' && { channels }),
    };
    return GptContextService.search(request);
  },

  getSearchHistory: async (): Promise<SearchResponse> => {
    const response = await api.get<SearchResponse>('/gpt-context/history');
    return response.data;
  },

  savePrompt: async (
    request: SavePromptRequest,
  ): Promise<SavePromptResponse> => {
    const response = await api.post<SavePromptResponse>(
      '/gpt-context/prompts',
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  },

  getPrompts: async (): Promise<BuiltPrompt[]> => {
    const response = await api.get<BuiltPrompt[]>('/gpt-context/prompts');
    return response.data;
  },

  deletePrompt: async (promptId: string): Promise<void> => {
    await api.delete(`/gpt-context/prompts/${promptId}`);
  },

  // タグ関連のAPI
  createTag: async (request: CreateTagRequest): Promise<Tag> => {
    const response = await api.post<Tag>('/gpt-context/tags', request, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  },

  getTags: async (): Promise<Tag[]> => {
    const response = await api.get<Tag[]>('/gpt-context/tags');
    return response.data;
  },

  getSessionTags: async (search_key: string): Promise<Tag[]> => {
    const response = await api.get<Tag[]>(
      `/gpt-context/sessions/${encodeURIComponent(search_key)}/tags`,
    );
    return response.data;
  },

  updateSessionTags: async (
    search_key: string,
    request: UpdateSessionTagsRequest,
  ): Promise<void> => {
    await api.put(
      `/gpt-context/sessions/${encodeURIComponent(search_key)}/tags`,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  },

  updateSessionPrePrompt: async (
    search_key: string,
    pre_prompt: string,
  ): Promise<void> => {
    const request: UpdateSessionPrePromptRequest = {
      search_key,
      pre_prompt,
    };
    await api.put(
      `/gpt-context/sessions/${encodeURIComponent(search_key)}/pre_prompt`,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  },

  updateSessionMemo: async (
    search_key: string,
    memo: string,
  ): Promise<void> => {
    const request: UpdateSessionMemoRequest = {
      search_key,
      memo,
    };
    await api.put(
      `/gpt-context/sessions/${encodeURIComponent(search_key)}/memo`,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  },

  // プロンプトモジュール関連のAPI
  createPromptModule: async (
    request: CreatePromptModuleRequest,
  ): Promise<PromptModule> => {
    const response = await api.post<PromptModule>(
      '/gpt-context/prompt-modules',
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  },

  getPromptModules: async (): Promise<PromptModule[]> => {
    const response = await api.get<PromptModule[]>(
      '/gpt-context/prompt-modules',
    );
    return response.data;
  },

  updatePromptModule: async (
    moduleId: string,
    request: UpdatePromptModuleRequest,
  ): Promise<PromptModule> => {
    const response = await api.put<PromptModule>(
      `/gpt-context/prompt-modules/${moduleId}`,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  },

  deleteSession: async (searchKey: string): Promise<void> => {
    await api.delete(`/gpt-context/sessions/${encodeURIComponent(searchKey)}`);
  },

  // 検索タイプに基づいて全件取得
  getAllByType: async (searchType: SearchType): Promise<any[]> => {
    const response = await api.get(`/gpt-context/all/${searchType}`);
    return response.data;
  },

  // フリーワード検索結果の保存
  saveFreeWordSearchResults: async (
    sessionName: string,
    searchType: SearchType,
    results: any[],
  ): Promise<void> => {
    const request = {
      search_message: sessionName,
      search_type: searchType,
      results: results.map((result) => ({
        id: result.id,
        relevance_score: 100,
        reason: 'フリーワード検索による選択',
      })),
    };
    await api.post('/gpt-context/save-free-word-search', request);
  },

  // 複数の検索結果をまとめて保存
  bulkSaveSearchResults: async (
    sessionName: string,
    searchType: SearchType,
    resultIds: string[],
  ): Promise<void> => {
    const request = {
      search_message: sessionName,
      search_type: searchType,
      result_ids: resultIds,
    };
    await api.post('/gpt-context/bulk-save-search', request);
  },
};

export default GptContextService;
