import Search from '@mui/icons-material/Search';
import { Box, Container, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { PromptBuilder } from './components/PromptBuilder';
import { PromptModuleList } from './components/PromptModuleList';
import SearchForm from './components/SearchForm';
import SearchResults from './components/SearchResults';
import { GptContextService } from './services/gptContext.service';
import {
  PromptModule,
  SearchRequest,
  SearchResponse,
  SearchSession,
  Tag,
} from './services/types';

const GptContextPage: React.FC = () => {
  const [sessions, setSessions] = useState<SearchSession[]>([]);
  const [selectedSessionIds, setSelectedSessionIds] = useState<string[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedModuleIds, setSelectedModuleIds] = useState<string[]>([]);

  // 検索履歴の取得
  const { data: historyData, isLoading: isHistoryLoading } =
    useQuery<SearchResponse>({
      queryKey: ['searchHistory'],
      queryFn: GptContextService.getSearchHistory,
    });

  // タグ一覧の取得
  const { data: tagsData } = useQuery<Tag[]>({
    queryKey: ['tags'],
    queryFn: GptContextService.getTags,
  });

  // プロンプトモジュール一覧の取得
  const { data: modulesData, refetch: refetchModules } = useQuery<
    PromptModule[]
  >({
    queryKey: ['promptModules'],
    queryFn: GptContextService.getPromptModules,
  });

  useEffect(() => {
    if (historyData && !sessions.length) {
      setSessions(historyData.sessions);
    }
  }, [historyData, sessions.length]);

  useEffect(() => {
    if (tagsData) {
      setTags(tagsData);
    }
  }, [tagsData]);

  const searchMutation = useMutation({
    mutationFn: GptContextService.search,
    onSuccess: (data) => {
      if (data.sessions.length > 0) {
        setSessions((prev) => [data.sessions[0], ...prev]);
      }
    },
  });

  const handleSearch = (request: SearchRequest) => {
    // タグによるフィルタリングを追加
    const searchRequest = {
      ...request,
      tag_ids: selectedTags.length > 0 ? selectedTags : undefined,
    };
    searchMutation.mutate(searchRequest);
  };

  const handleSessionUpdate = (updatedSession: SearchSession) => {
    setSessions((prev) =>
      prev.map((session) =>
        session.id === updatedSession.id ? updatedSession : session,
      ),
    );
  };

  const handleSessionSelect = (sessionId: string) => {
    setSelectedSessionIds((prev) => {
      if (prev.includes(sessionId)) {
        return prev.filter((id) => id !== sessionId);
      } else {
        return [...prev, sessionId];
      }
    });
  };

  const handleMultipleSessionSelect = (sessionIds: string[]) => {
    setSelectedSessionIds(sessionIds);
  };

  const handleModuleSelect = (moduleId: string) => {
    setSelectedModuleIds((prev) => {
      if (prev.includes(moduleId)) {
        return prev.filter((id) => id !== moduleId);
      } else {
        return [...prev, moduleId];
      }
    });
  };

  const selectedSessions = sessions.filter((session) =>
    selectedSessionIds.includes(session.id),
  );

  const selectedModules =
    modulesData?.filter((module) => selectedModuleIds.includes(module.id)) ||
    [];

  return (
    <Container sx={{ mt: 4, mb: 4, maxWidth: '100% !important' }}>
      {/* プロンプトビルダー */}
      <Paper sx={{ mb: 2, bgcolor: '#fff' }}>
        <PromptBuilder
          selectedSessions={selectedSessions}
          onSessionsSelect={handleMultipleSessionSelect}
          selectedModules={selectedModules}
        />
      </Paper>

      {/* プロンプトモジュール */}
      <Paper
        sx={{
          mb: 2,
          bgcolor: '#fff',
          '& .MuiAccordion-root': {
            boxShadow: 'none',
            '&:before': {
              display: 'none',
            },
          },
        }}
      >
        <PromptModuleList
          modules={modulesData || []}
          selectedModuleIds={selectedModuleIds}
          onModuleSelect={handleModuleSelect}
          onModulesUpdate={refetchModules}
        />
      </Paper>

      {/* コンテキスト検索セクション */}
      <Paper sx={{ bgcolor: '#fff' }}>
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontWeight: 700,
              fontSize: '1.2rem',
            }}
          >
            <Search sx={{ color: 'primary.main' }} />
            コンテキスト
          </Typography>
          <Box sx={{ mb: 2 }}>
            <SearchForm
              onSearch={handleSearch}
              isLoading={searchMutation.isPending}
              tags={tags}
              selectedTags={selectedTags}
              onTagsChange={setSelectedTags}
            />
          </Box>
          <SearchResults
            sessions={sessions}
            isLoading={searchMutation.isPending || isHistoryLoading}
            onSessionUpdate={handleSessionUpdate}
            selectedSessionIds={selectedSessionIds}
            onSessionSelect={handleSessionSelect}
            tags={tags}
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default GptContextPage;
