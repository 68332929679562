import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { ShelfMetrics } from '../../../services/shelf-service';
import N1Chart from '../shelf-insights/N1Chart';
import ShelfInsightsTable from '../shelf-insights/ShelfInsightsTable';

interface ShelfInsightsSectionProps {
  metrics: ShelfMetrics[];
  isLoading: boolean;
}

const ShelfInsightsSection: React.FC<ShelfInsightsSectionProps> = ({
  metrics,
  isLoading,
}) => {
  return (
    <Box
      sx={{
        mb: 6,
        bgcolor: 'background.paper',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
        minHeight: '200px',
      }}
    >
      <Box
        sx={{
          p: '8px 16px',
          bgcolor: '#1a1a1a',
          color: 'white',
          mx: '2px',
          borderRadius: '3px 3px 0 0',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', fontSize: '1.8rem' }}
        >
          InsightXシェルフインサイトレポート
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                gap: 3,
                mb: 3,
              }}
            >
              <N1Chart userGroupIndex={0} />
              <N1Chart userGroupIndex={1} />
              <N1Chart userGroupIndex={2} />
            </Box>
            <ShelfInsightsTable metrics={metrics} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ShelfInsightsSection;
