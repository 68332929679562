import { Box, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { shelfService } from '../../../services/shelf-service';
import { useClient } from '../../../store/client.store';

interface ShelfDisplaySectionProps {
  selectedPageType: string;
}

const ShelfDisplaySection: React.FC<ShelfDisplaySectionProps> = ({
  selectedPageType,
}) => {
  const { client: clientState } = useClient();

  const { data, isLoading, error } = useQuery({
    queryKey: [
      'shelfDisplay',
      selectedPageType,
      clientState.selectedClient?.clientId,
    ],
    queryFn: () =>
      shelfService.getShelfDisplayInfo(
        selectedPageType,
        clientState.selectedClient?.clientId,
      ),
    enabled: !!selectedPageType,
  });

  if (!data?.shelves?.length || isLoading || error) {
    return null;
  }

  return (
    <Paper
      elevation={2}
      sx={{
        mt: 2,
        mb: 3,
        borderRadius: 3,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '1.1rem',
            fontWeight: 'bold',
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box
            component="img"
            src="https://storage.googleapis.com/insightx-images/dashboard/public/assets/insightx_logo_black.png"
            alt="InsightX"
            sx={{ width: 30, height: 30 }}
          />
          {selectedPageType.replace('ページ', '')}
          ページで表示中のInsightXシェルフ
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            pb: 1.5,
            px: 0.5,
            pt: 0.5,
            overflowX: 'auto',
          }}
        >
          {data.shelves.map((shelf) => (
            <Box
              key={shelf.name}
              sx={{
                bgcolor: '#f8f8f8',
                borderRadius: 2,
                overflow: 'hidden',
                transition: 'transform 0.2s ease',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.04)',
                width: 'fit-content',
                flexShrink: 0,
                px: 2,
                pt: 0.5,
                pb: 0.5,
                '&:hover': {
                  transform: 'translateY(-2px)',
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: '#333',
                  textAlign: 'center',
                  mb: 0,
                }}
              >
                {shelf.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    flexShrink: 0,
                    width: shelf.image_width,
                    height: shelf.image_height,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component="img"
                    src={shelf.image_url}
                    alt={shelf.name}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '140px',
                    flexShrink: 0,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#666',
                      lineHeight: 1.4,
                      fontSize: '0.85rem',
                      fontWeight: 700,
                    }}
                  >
                    {shelf.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default ShelfDisplaySection;
