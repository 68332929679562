import AssessmentIcon from '@mui/icons-material/Assessment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { PropertySection } from './PropertySection';
import type { AnalysisTemplate } from './types';

interface TemplateCardProps {
  template: AnalysisTemplate;
  hideRelevanceScore?: boolean;
}

export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  hideRelevanceScore = false,
}) => {
  const [showSql, setShowSql] = useState(false);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center" gap={1}>
          <AssessmentIcon color="primary" />
          <Link
            href={template.page_url}
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            color="inherit"
          >
            <Typography variant="subtitle1">{template.name}</Typography>
          </Link>
          {!hideRelevanceScore && template.relevance_score && (
            <Chip
              label={`関連度: ${template.relevance_score}%`}
              color="primary"
              variant="outlined"
              size="small"
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{
              backgroundColor: 'primary.50',
              p: 1.5,
              borderRadius: 1,
              fontStyle: 'italic',
            }}
          >
            {template.reason}
          </Typography>

          <Stack spacing={2}>
            <PropertySection label="サマリ" value={template.summary} />
            <PropertySection
              label="分析タイプ"
              value={template.analysis_type}
            />
          </Stack>

          <Box>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setShowSql(!showSql)}
              startIcon={showSql ? null : <ContentCopyIcon />}
              sx={{ mb: showSql ? 2 : 0 }}
            >
              {showSql ? 'SQLを隠す' : 'SQLを表示'}
            </Button>
            {showSql && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  position: 'relative',
                  bgcolor: '#f5f5f5',
                  '& pre': {
                    margin: 0,
                    whiteSpace: 'pre-wrap',
                    fontFamily: 'monospace',
                    fontSize: '0.875rem',
                  },
                }}
              >
                <pre>{template.sql}</pre>
              </Paper>
            )}
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
