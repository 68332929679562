import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { Eye, MousePointerClick } from 'lucide-react';
import React, { useState } from 'react';
import { ShelfMetrics } from '../../../services/shelf-service';

interface ShelfInsightsTableProps {
  metrics: ShelfMetrics[];
}

type Order = 'asc' | 'desc';

interface Column {
  id: 'shown' | 'click' | 'clickr';
  label: string;
  subLabel: string;
  icon?: React.ElementType;
  iconColor?: string;
  format: (value: number) => string;
  group: '見た' | '使った';
  getValue?: (metric: any) => number;
}

const columns: Column[] = [
  {
    id: 'shown',
    label: '見た',
    subLabel: 'IMP数',
    icon: Eye,
    iconColor: '#2196F3',
    format: (value: number) => value.toLocaleString(),
    group: '見た',
  },
  {
    id: 'click',
    label: '使った',
    subLabel: 'クリック数',
    icon: MousePointerClick,
    iconColor: '#4CAF50',
    format: (value: number) => value.toLocaleString(),
    group: '使った',
  },
  {
    id: 'clickr',
    label: '使った',
    subLabel: 'クリック率',
    format: (value: number) => `${(value * 100).toFixed(3)}%`,
    getValue: (metric: any) => metric.click / (metric.shown || 1),
    group: '使った',
  },
];

const ShelfInsightsTable: React.FC<ShelfInsightsTableProps> = ({ metrics }) => {
  const [orderBy, setOrderBy] = useState<'shown' | 'click' | 'clickr'>('shown');
  const [order, setOrder] = useState<Order>('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [maxValues, setMaxValues] = useState<Record<string, number>>({});

  const handleRequestSort = (property: 'shown' | 'click' | 'clickr') => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedMetrics = React.useMemo(() => {
    const filteredMetrics = metrics.filter(
      (m) => m.shelf_type === 'IXニーズ' || m.shelf_type === 'IX検索キーワード',
    );

    const uniqueMetrics = filteredMetrics.reduce((acc, current) => {
      const existing = acc.find(
        (item) => item.bold_tagline === current.bold_tagline,
      );
      if (!existing) {
        acc.push(current);
      } else if (current.shown > existing.shown) {
        const index = acc.indexOf(existing);
        acc[index] = current;
      }
      return acc;
    }, [] as ShelfMetrics[]);

    const newMaxValues = columns.reduce(
      (acc, column) => {
        const values = uniqueMetrics.map((m) => {
          if (column.id === 'clickr') {
            return m.click / (m.shown || 1);
          }
          return m[column.id] || 0;
        });
        acc[column.id] = Math.max(0, ...values);
        return acc;
      },
      {} as Record<string, number>,
    );
    setMaxValues(newMaxValues);

    return uniqueMetrics.sort((a, b) => {
      let comparison = 0;
      if (orderBy === 'clickr') {
        const aValue = a.click / (a.shown || 1);
        const bValue = b.click / (b.shown || 1);
        comparison = aValue - bValue;
      } else {
        comparison = (a[orderBy] || 0) - (b[orderBy] || 0);
      }
      return order === 'desc' ? -comparison : comparison;
    });
  }, [metrics, order, orderBy]);

  const paginatedMetrics = sortedMetrics.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const renderMetricsBar = (
    value: number,
    maxValue: number,
    columnId: 'shown' | 'click' | 'clickr',
  ) => {
    const percentage = maxValue > 0 ? (value / maxValue) * 100 : 0;
    const limitedPercentage = Math.min(percentage, 99);

    const getBarColor = () => {
      switch (columnId) {
        case 'shown':
          return 'rgba(33, 150, 243, 0.15)'; // Eye color
        case 'click':
        case 'clickr':
          return 'rgba(76, 175, 80, 0.15)'; // MousePointerClick color
        default:
          return 'grey.200';
      }
    };

    return (
      <Box
        className="metrics-bar"
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: `${limitedPercentage}%`,
          bgcolor: getBarColor(),
          opacity: 0.8,
          transition: 'opacity 0.2s',
        }}
      />
    );
  };

  if (!metrics || metrics.length === 0) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} align="center">
                データがありません
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Paper
        elevation={2}
        sx={{
          borderRadius: 3,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
          >
            📊 こだわりシェルフのパフォーマンス
          </Typography>
          <TableContainer
            sx={{
              maxHeight: 1380,
              borderRadius: 2,
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      minWidth: 200,
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      bgcolor: 'background.paper',
                      borderRight: '1px solid',
                      borderRightColor: 'divider',
                    }}
                    rowSpan={2}
                  >
                    シェルフ名
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={1}
                    sx={{
                      borderBottom: 'none',
                      height: '40px',
                      verticalAlign: 'bottom',
                      pb: 1,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        right: '-1rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 0,
                        height: 0,
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderLeft: '10px solid rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                        }}
                      >
                        <Eye size={16} style={{ color: '#2196F3' }} />
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            color: 'text.primary',
                          }}
                        >
                          見た
                        </Typography>
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'text.secondary',
                          display: 'block',
                        }}
                      >
                        インプレッション
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{
                      borderBottom: 'none',
                      height: '40px',
                      verticalAlign: 'bottom',
                      pb: 1,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        right: '-1rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 0,
                        height: 0,
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderLeft: '10px solid rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 1,
                        }}
                      >
                        <MousePointerClick
                          size={16}
                          style={{ color: '#4CAF50' }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            color: 'text.primary',
                          }}
                        >
                          使った
                        </Typography>
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'text.secondary',
                          display: 'block',
                        }}
                      >
                        魅力度
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: 300,
                      borderRight: '1px solid',
                      borderRightColor: 'divider',
                    }}
                    rowSpan={2}
                  >
                    シェルフ内の商品例
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      sx={{
                        textAlign: 'center',
                        borderBottom: '2px solid',
                        borderBottomColor: 'divider',
                        borderRight: '1px solid',
                        borderRightColor: 'divider',
                        color: 'text.secondary',
                        height: '40px',
                        verticalAlign: 'top',
                        pt: 1,
                        bgcolor: 'background.paper',
                        '&:last-child': {
                          borderRight: 'none',
                        },
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                            textAlign: 'center',
                          }}
                        >
                          {column.subLabel}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedMetrics.map((metric) => (
                  <TableRow
                    key={`${metric.shelf_type}-${metric.bold_tagline}`}
                    sx={{
                      '&:hover': {
                        bgcolor: 'action.hover',
                        '& .metrics-bar': {
                          opacity: 0.7,
                        },
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        bgcolor: 'background.paper',
                        zIndex: 2,
                        borderRight: '1px solid',
                        borderRightColor: 'divider',
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {metric.bold_tagline}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: 'text.secondary' }}
                      >
                        {metric.gray_tagline}
                      </Typography>
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{
                          position: 'relative',
                          borderRight: '1px solid',
                          borderRightColor: 'divider',
                          '&:last-child': {
                            borderRight: 'none',
                          },
                        }}
                      >
                        {renderMetricsBar(
                          column.id === 'clickr'
                            ? metric.click / (metric.shown || 1)
                            : metric[column.id] || 0,
                          maxValues[column.id],
                          column.id,
                        )}
                        <Typography
                          variant="body2"
                          sx={{
                            textAlign: 'center',
                            position: 'relative',
                            zIndex: 1,
                            fontWeight: 'bold',
                          }}
                        >
                          {column.id === 'clickr'
                            ? column.format(metric.click / (metric.shown || 1))
                            : column.format(metric[column.id] || 0)}
                        </Typography>
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        borderRight: '1px solid',
                        borderRightColor: 'divider',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          overflowX: 'auto',
                          '&::-webkit-scrollbar': {
                            height: '6px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            borderRadius: '3px',
                          },
                        }}
                      >
                        {metric.top_items?.map((item) => (
                          <Box
                            key={item.aid}
                            component="a"
                            href={item.item_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              textDecoration: 'none',
                              color: 'inherit',
                              minWidth: '60px',
                              maxWidth: '60px',
                            }}
                          >
                            <img
                              src={item.image_url}
                              alt={item.item_title}
                              loading="lazy"
                              style={{
                                width: '100%',
                                height: '80px',
                                objectFit: 'cover',
                                borderRadius: '4px',
                              }}
                            />
                            <Typography
                              variant="caption"
                              sx={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                mt: 0.5,
                                fontSize: '0.65rem',
                                color: 'text.secondary',
                                lineHeight: 1.2,
                                height: '1.6rem',
                                textAlign: 'center',
                              }}
                            >
                              {item.brand_name}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={sortedMetrics.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="表示件数"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} / ${count}`
            }
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ShelfInsightsTable;
