import api from './api';

export interface ShelfMetrics {
  url_page_type: string;
  shelf_type: string;
  gray_tagline: string;
  bold_tagline: string;
  shown: number;
  scroll: number;
  click: number;
  cv: number;
  cart: number;
  favorite: number;
  scrollr: number;
  clickr: number;
  cvr: number;
  cartr: number;
  favoriter: number;
  revenue: number;
  top_items?: Array<{
    aid: string;
    item_title: string;
    brand_name: string;
    category: string;
    sub_category: string;
    image_url: string;
    item_url: string;
    item_shown: number;
  }>;
}

export interface TrendSearchShelf {
  shelf_id: string;
  gray_tagline: string;
  bold_tagline: string;
  shown_train_period: number;
  shown_test_period: number;
  shown_per_day_train: number;
  shown_per_day_test: number;
  trend_score: number;
  rank: number;
  top_items: Array<{
    aid: string;
    item_title: string;
    brand_name: string;
    category: string;
    sub_category: string;
    image_url: string;
    item_url: string;
    item_shown: number;
    item_click: number;
    item_clickr: number;
  }>;
}

export interface TrendSearchShelvesResponse {
  shelves: TrendSearchShelf[];
}

export interface DailyShelfMetrics {
  date: string;
  url_page_type: string;
  shown: number;
  click: number;
  cv: number;
  revenue: number;
}

export interface DailySSMetrics {
  ymd: string;
  ss: number;
  pdp: number;
  shelf_click: number;
  shelf_shown: number;
  cv: number;
  shelf_pdp: number;
  shelf_cv: number;
  revenue: number;
  shelf_revenue: number;
}

export interface ShelfStats {
  stats: ShelfMetrics[];
}

export interface DailyMetricsResponse {
  metrics: DailyShelfMetrics[];
}

export interface DailySSMetricsResponse {
  metrics: DailySSMetrics[];
}

export interface GetShelfStatsRequest {
  start_date: string;
  end_date: string;
}

export interface ShelfDisplayInfo {
  name: string;
  image_url: string;
  image_width: number;
  image_height: number;
  description: string;
}

export interface ShelfDisplayResponse {
  shelves: ShelfDisplayInfo[];
}

export interface N1User {
  uu_id: string;
  pv_items: {
    pv_items: Array<{
      aid: string;
      publish_time: string;
      item_title: string;
      brand_name: string;
      category: string;
      sub_category: string;
      image_url: string;
      item_url: string;
    }>;
  };
}

export interface RandomN1UsersResponse {
  users: N1User[];
}

export const shelfService = {
  async getShelfStats(params: GetShelfStatsRequest): Promise<ShelfStats> {
    const response = await api.post('/shelf/stats', params);
    return response.data;
  },

  async getDailyMetrics(
    params: GetShelfStatsRequest,
  ): Promise<DailyMetricsResponse> {
    const response = await api.post('/shelf/daily_metrics', params);
    return response.data;
  },

  async getDailySSMetrics(
    params: GetShelfStatsRequest,
  ): Promise<DailySSMetricsResponse> {
    const response = await api.post('/shelf/daily_ss_metrics', params);
    return response.data;
  },

  async getShelfDisplayInfo(
    url_page_type: string,
    client_id?: string,
  ): Promise<ShelfDisplayResponse> {
    const params = new URLSearchParams({ url_page_type });
    if (client_id) {
      params.append('client_id', client_id);
    }
    const response = await api.get(
      `/shelf-display/display-info?${params.toString()}`,
    );
    return response.data;
  },

  async getTrendSearchShelves(): Promise<TrendSearchShelvesResponse> {
    const response = await api.post('/shelf/trend_search_shelves', {});
    return response.data;
  },

  async getRandomN1Users(): Promise<RandomN1UsersResponse> {
    const response = await api.post('/shelf/random_n1_users', {});
    return response.data;
  },
};
