import api from '../api';

interface IReviewShelfQueriesRes {
  jobId: string;
}

export const reviewShelfQueries = async (): Promise<IReviewShelfQueriesRes> => {
  const res = await api.post('/shelf/review_shelf_queries');
  return res.data;
};
