import { Alert, Skeleton, Stack } from '@mui/material';
import React from 'react';
import type { SearchAnalysisTemplatesResponse } from '../../services/analysis-service';
import { SearchDebugInfo } from './SearchDebugInfo';
import { TemplateCard } from './TemplateCard';

interface TemplateSearchResultsProps {
  data?: SearchAnalysisTemplatesResponse;
  isLoading: boolean;
  error: unknown;
  hideRelevanceScore?: boolean;
}

export const TemplateSearchResults: React.FC<TemplateSearchResultsProps> = ({
  data,
  isLoading,
  error,
  hideRelevanceScore = false,
}) => {
  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 4 }}>
        テンプレートの検索中にエラーが発生しました
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <Stack spacing={2}>
        {[...Array(2)].map((_, i) => (
          <Skeleton key={i} variant="rectangular" height={200} />
        ))}
      </Stack>
    );
  }

  if (!data?.templates.length) {
    return (
      <Alert severity="info">
        該当する分析テンプレートが見つかりませんでした
      </Alert>
    );
  }

  return (
    <Stack spacing={2}>
      {data.templates.map((template) => (
        <TemplateCard
          key={template.template_id}
          template={template}
          hideRelevanceScore={hideRelevanceScore}
        />
      ))}
      <SearchDebugInfo
        prompt={data.debug?.prompt}
        response={data.debug?.response}
        error={data.debug?.error}
      />
    </Stack>
  );
};
