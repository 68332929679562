import AddIcon from '@mui/icons-material/Add';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ChatIcon from '@mui/icons-material/Chat';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupsIcon from '@mui/icons-material/Groups';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import StorageIcon from '@mui/icons-material/Storage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React, { useState } from 'react';
import { GptContextService } from '../services/gptContext.service';
import { SearchSession, Tag } from '../services/types';
import ResultCard from './ResultCard';

interface SearchResultsProps {
  sessions: SearchSession[];
  isLoading: boolean;
  onSessionUpdate?: (updatedSession: SearchSession) => void;
  selectedSessionIds: string[];
  onSessionSelect: (sessionId: string) => void;
  tags: Tag[];
  notification: {
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  };
  setNotification: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      message: string;
      severity: 'success' | 'error';
    }>
  >;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  sessions,
  isLoading,
  onSessionUpdate,
  selectedSessionIds,
  onSessionSelect,
  tags,
  notification,
  setNotification,
}) => {
  const [expandedSessions, setExpandedSessions] = useState<Set<string>>(
    new Set(),
  );
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [prePromptDialogOpen, setPrePromptDialogOpen] = useState(false);
  const [memoDialogOpen, setMemoDialogOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState<SearchSession | null>(
    null,
  );
  const [newTagName, setNewTagName] = useState('');
  const [selectedExistingTag, setSelectedExistingTag] = useState<Tag | null>(
    null,
  );
  const [prePromptText, setPrePromptText] = useState('');
  const [memoText, setMemoText] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState<SearchSession | null>(
    null,
  );

  // より落ち着いた色に変更
  const typeConfig: {
    [key: string]: { color: string; icon: React.ReactNode };
  } = {
    discussion: { color: '#42a5f5', icon: <ChatIcon /> },
    analysis: { color: '#66bb6a', icon: <AnalyticsIcon /> },
    meeting: { color: '#ffa726', icon: <GroupsIcon /> },
    slack: { color: '#ec407a', icon: <ChatIcon /> },
    sql_template: { color: '#7e57c2', icon: <CodeIcon /> },
    analysis_table: { color: '#26a69a', icon: <StorageIcon /> },
    analysis_template: { color: '#5c6bc0', icon: <QueryStatsIcon /> },
  };

  // コストを円に変換する関数
  const formatCostInJPY = (cost: number) => {
    if (!cost) {
      return '';
    }
    const isFree = cost == 0;
    if (isFree) {
      return '';
    }
    const jpyCost = Math.round(cost * 150 * 1000) / 1000;
    return `¥${jpyCost.toLocaleString(undefined, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })}`;
  };

  // 検索タイプを日本語に変換する関数
  const formatSearchType = (type: string) => {
    const typeMap: { [key: string]: string } = {
      discussion: 'ディスカッション',
      analysis: '分析',
      meeting: 'ミーティング',
      slack: 'Slack',
      sql_template: 'SQLテンプレート',
      analysis_table: '分析テーブル',
      analysis_template: '分析テンプレート',
    };
    return typeMap[type] || type;
  };

  // セッションの内容をコピー用のテキストに変換する関数
  const getSessionCopyText = (session: SearchSession): string => {
    const lines: string[] = [
      `検索クエリ: ${session.searchMessage}`,
      `検索タイプ: ${formatSearchType(session.searchType)}`,
      `検索日時: ${new Date(session.createdAt).toLocaleString('ja-JP')}`,
    ];

    // タグ情報を追加
    if (session.tag_ids && session.tag_ids.length > 0) {
      const tagNames = session.tag_ids
        .map((tagId) => tags.find((t) => t.name === tagId)?.name)
        .filter((name): name is string => !!name);
      if (tagNames.length > 0) {
        lines.push(`タグ: ${tagNames.join(', ')}`);
      }
    }

    // Slackの場合はチャンネル情報を追加
    if (
      session.searchType === 'slack' &&
      session.channels &&
      session.channels.length > 0
    ) {
      lines.push(
        `検索対象チャンネル: ${session.channels.map((ch) => `#${ch}`).join(', ')}`,
      );
    }

    lines.push('', '検索結果:', '');

    session.results.forEach((result, index) => {
      lines.push(`${index + 1}. ${result.title}`);
      lines.push(`作成日: ${result.createdAt}`);
      lines.push(`最終更新: ${result.lastEditedAt}`);
      if (result.summary) {
        lines.push(`サマリー: ${result.summary}`);
      }

      // プロパティの追加
      Object.entries(result.properties).forEach(([key, value]) => {
        if (value) {
          lines.push(`${key}: ${value}`);
        }
      });

      // コンテンツの追加（20000文字でtruncate）
      if (result.content) {
        const truncatedContent =
          result.content.length > 20000
            ? result.content.slice(0, 19997) + '...'
            : result.content;
        lines.push(`コンテンツ:\n${truncatedContent}`);
      }

      lines.push(''); // 結果間の空行
    });

    return lines.join('\n');
  };

  // コピー処理を行う関数
  const handleCopy = async (session: SearchSession) => {
    try {
      const text = getSessionCopyText(session);
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('コピーに失敗しました:', err);
    }
  };

  // 更新処理を行う関数
  const handleRefresh = async (session: SearchSession) => {
    try {
      const response = await GptContextService.refresh(
        session.searchMessage,
        session.searchType,
        session.channels,
      );
      if (response.sessions.length > 0 && onSessionUpdate) {
        onSessionUpdate(response.sessions[0]);
      }
    } catch (err) {
      console.error('更新に失敗しました:', err);
    }
  };

  // タグの更新処理を行う関数
  const handleTagUpdate = async (session: SearchSession, tagIds: string[]) => {
    try {
      await GptContextService.updateSessionTags(session.searchKey, {
        search_key: session.searchKey,
        tag_ids: tagIds,
      });
      if (onSessionUpdate) {
        onSessionUpdate({
          ...session,
          tag_ids: tagIds,
        });
      }
    } catch (err) {
      console.error('タグの更新に失敗しました:', err);
    }
  };

  // 日付フォーマットを簡略化
  const formatDateTime = (dateStr: string) => {
    return new Date(dateStr).toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleAccordionChange = (sessionId: string) => {
    setExpandedSessions((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(sessionId)) {
        newExpanded.delete(sessionId);
      } else {
        newExpanded.add(sessionId);
      }
      return newExpanded;
    });
  };

  // ランダムな色を生成する関数
  const generateRandomColor = () => {
    const colors = [
      '#2196F3', // Blue
      '#4CAF50', // Green
      '#F44336', // Red
      '#FF9800', // Orange
      '#9C27B0', // Purple
      '#00BCD4', // Cyan
      '#795548', // Brown
      '#607D8B', // Blue Grey
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // タグを追加する関数
  const handleAddTag = async () => {
    if (!selectedSession) return;

    try {
      if (newTagName) {
        // 新しいタグを作成
        const newTag = await GptContextService.createTag({
          name: newTagName,
          color: generateRandomColor(),
        });
        const currentTagIds = selectedSession.tag_ids || [];
        await handleTagUpdate(selectedSession, [...currentTagIds, newTag.name]);
      } else if (selectedExistingTag) {
        // 既存のタグを追加
        const currentTagIds = selectedSession.tag_ids || [];
        if (!currentTagIds.includes(selectedExistingTag.name)) {
          await handleTagUpdate(selectedSession, [
            ...currentTagIds,
            selectedExistingTag.name,
          ]);
        }
      }
    } catch (err) {
      console.error('タグの追加に失敗しました:', err);
    }

    // ダイアログをリセット
    setTagDialogOpen(false);
    setNewTagName('');
    setSelectedExistingTag(null);
    setSelectedSession(null);
  };

  // pre_promptの更新処理を行う関数
  const handlePrePromptUpdate = async () => {
    if (!selectedSession) return;

    try {
      await GptContextService.updateSessionPrePrompt(
        selectedSession.searchKey,
        prePromptText,
      );
      if (onSessionUpdate) {
        onSessionUpdate({
          ...selectedSession,
          pre_prompt: prePromptText,
        });
      }
    } catch (err) {
      console.error('pre_promptの更新に失敗しました:', err);
    }

    setPrePromptDialogOpen(false);
    setPrePromptText('');
    setSelectedSession(null);
  };

  // メモの更新処理を行う関数
  const handleMemoUpdate = async () => {
    if (!selectedSession) return;

    try {
      await GptContextService.updateSessionMemo(
        selectedSession.searchKey,
        memoText,
      );
      if (onSessionUpdate) {
        onSessionUpdate({
          ...selectedSession,
          memo: memoText,
        });
      }
    } catch (err) {
      console.error('メモの更新に失敗しました:', err);
    }

    setMemoDialogOpen(false);
    setMemoText('');
    setSelectedSession(null);
  };

  // セッション削除の処理
  const handleDeleteSession = async () => {
    if (!sessionToDelete) return;

    try {
      await GptContextService.deleteSession(sessionToDelete.searchKey);
      // セッション一覧から削除したセッションを除外して親コンポーネントに通知
      if (onSessionUpdate) {
        onSessionUpdate({
          ...sessionToDelete,
          results: [], // 結果をクリアして削除状態を表現
        });
      }
      setDeleteDialogOpen(false);
      setSessionToDelete(null);
      // 通知を表示
      setNotification({
        open: true,
        message: 'セッションを削除しました',
        severity: 'success',
      });
    } catch (error) {
      console.error('セッションの削除に失敗しました:', error);
      // エラー通知を表示
      setNotification({
        open: true,
        message: 'セッションの削除に失敗しました',
        severity: 'error',
      });
    }
  };

  // 初期ロード時のローディング表示
  if (sessions.length === 0 && isLoading) {
    return (
      <Box>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontWeight: 600,
            fontSize: '0.9rem',
            color: 'text.secondary',
          }}
        >
          <FormatListBulletedIcon sx={{ fontSize: '1.2rem' }} />
          コンテキスト検索一覧
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                mb: 2,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                height: '160px',
                p: 2,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                bgcolor: '#fff',
              }}
            >
              <CircularProgress size={32} />
              <Typography color="text.secondary">読み込み中...</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // 検索結果がない場合の表示
  if (sessions.length === 0) {
    return (
      <Box>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontWeight: 600,
            fontSize: '0.9rem',
            color: 'text.secondary',
          }}
        >
          <FormatListBulletedIcon sx={{ fontSize: '1.2rem' }} />
          コンテキスト検索一覧
        </Typography>
        <Typography color="text.secondary">検索結果がありません</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          fontWeight: 600,
          fontSize: '0.9rem',
          color: 'text.secondary',
        }}
      >
        <FormatListBulletedIcon sx={{ fontSize: '1.2rem' }} />
        コンテキスト検索一覧
      </Typography>
      <Grid container spacing={2}>
        {isLoading && (
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                mb: 2,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                height: '160px',
                p: 2,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                bgcolor: '#fff',
              }}
            >
              <CircularProgress size={32} />
              <Typography color="text.secondary">検索中...</Typography>
            </Paper>
          </Grid>
        )}
        {sessions.map((session) => (
          <Grid item xs={12} md={4} key={session.id}>
            <Paper
              sx={{
                mb: 2,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                height: '160px',
                position: 'relative',
              }}
            >
              {/* チェックボックスを追加 */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  zIndex: 2,
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '50%',
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Checkbox
                  checked={selectedSessionIds.includes(session.id)}
                  onChange={(e) => {
                    e.stopPropagation();
                    onSessionSelect(session.id);
                  }}
                  size="small"
                />
              </Box>
              <Accordion
                expanded={expandedSessions.has(session.id)}
                onChange={() => handleAccordionChange(session.id)}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 'none',
                  '& .MuiAccordionDetails-root': {
                    p: 0,
                    maxHeight: '80vh',
                    overflow: 'auto',
                  },
                  '&.Mui-expanded': {
                    position: 'fixed',
                    top: '80px',
                    left: '10%',
                    right: '10%',
                    width: '80%',
                    height: '80vh',
                    zIndex: 1200,
                    backgroundColor: 'white',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    bgcolor: `${typeConfig[session.searchType]?.color}15`,
                    borderLeft: `3px solid ${typeConfig[session.searchType]?.color || 'primary.main'}`,
                    minHeight: '160px !important',
                    '& .MuiAccordionSummary-content': {
                      m: 0,
                      p: 1.5,
                      flexDirection: 'column',
                      height: '100%',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      gap: 1.5,
                    }}
                  >
                    {/* ヘッダー部分 */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {/* 左側：タイプとメタ情報 */}
                      <Box
                        sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          {typeConfig[session.searchType]?.icon || (
                            <SearchIcon />
                          )}
                          <Chip
                            label={formatSearchType(session.searchType)}
                            size="small"
                            sx={{
                              bgcolor: `${typeConfig[session.searchType]?.color}22`,
                              color: typeConfig[session.searchType]?.color,
                              fontWeight: 500,
                              height: '24px',
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography variant="caption" color="text.secondary">
                            {formatDateTime(session.createdAt)}
                          </Typography>
                          {formatCostInJPY(session.debug.cost) && (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {formatCostInJPY(session.debug.cost)}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      {/* 右側：アクションボタン */}
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="更新">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRefresh(session);
                            }}
                            sx={{
                              p: 0.5,
                              color: typeConfig[session.searchType]?.color,
                              '&:hover': {
                                bgcolor: `${typeConfig[session.searchType]?.color}22`,
                              },
                            }}
                          >
                            <RefreshIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="結果をコピー" placement="top">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(session);
                            }}
                            sx={{
                              p: 0.5,
                              color: typeConfig[session.searchType]?.color,
                              '&:hover': {
                                bgcolor: `${typeConfig[session.searchType]?.color}22`,
                              },
                            }}
                          >
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="セッションを削除" placement="top">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSessionToDelete(session);
                              setDeleteDialogOpen(true);
                            }}
                            sx={{
                              p: 0.5,
                              color: typeConfig[session.searchType]?.color,
                              '&:hover': {
                                bgcolor: `${typeConfig[session.searchType]?.color}22`,
                              },
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>

                    {/* タグ、pre_prompt、メモ */}
                    <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                      {session.tag_ids?.map((tagId) => {
                        const tag = tags.find((t) => t.name === tagId);
                        if (!tag) return null;
                        return (
                          <Chip
                            key={tag.name}
                            label={tag.name}
                            size="small"
                            sx={{
                              backgroundColor: tag.color + '22',
                              color: tag.color,
                              height: '24px',
                            }}
                            onDelete={() => {
                              const newTagIds =
                                session.tag_ids?.filter(
                                  (id) => id !== tag.name,
                                ) || [];
                              handleTagUpdate(session, newTagIds);
                            }}
                          />
                        );
                      })}
                      <Chip
                        icon={<AddIcon />}
                        label="タグを追加"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedSession(session);
                          setTagDialogOpen(true);
                        }}
                        sx={{
                          height: '24px',
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: 'action.hover',
                          },
                        }}
                      />
                      <Chip
                        icon={<AddIcon />}
                        label={
                          session.pre_prompt
                            ? 'pre_promptを編集'
                            : 'pre_promptを追加'
                        }
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedSession(session);
                          setPrePromptText(session.pre_prompt || '');
                          setPrePromptDialogOpen(true);
                        }}
                        sx={{
                          height: '24px',
                          cursor: 'pointer',
                          ...(session.pre_prompt
                            ? {
                                backgroundColor: '#4a148c22',
                                color: '#4a148c',
                                fontWeight: 'bold',
                              }
                            : {
                                '&:hover': {
                                  bgcolor: 'action.hover',
                                },
                              }),
                        }}
                      />
                      <Chip
                        icon={<AddIcon />}
                        label={session.memo ? 'メモを編集' : 'メモを追加'}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedSession(session);
                          setMemoText(session.memo || '');
                          setMemoDialogOpen(true);
                        }}
                        sx={{
                          height: '24px',
                          cursor: 'pointer',
                          ...(session.memo
                            ? {
                                backgroundColor: '#1a237e22',
                                color: '#1a237e',
                                fontWeight: 'bold',
                              }
                            : {
                                '&:hover': {
                                  bgcolor: 'action.hover',
                                },
                              }),
                        }}
                      />
                    </Box>

                    {/* メモの表示 */}
                    {session.memo && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#1a237e',
                          fontStyle: 'italic',
                          mt: 1,
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          textOverflow: 'ellipsis',
                          lineHeight: 1.4,
                        }}
                      >
                        📝 {session.memo}
                      </Typography>
                    )}

                    {/* 検索メッセージ部分 */}
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 'auto',
                        color: 'text.primary',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                        lineHeight: 1.5,
                        fontWeight: 600,
                      }}
                    >
                      {session.searchMessage}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ p: 1.5 }}>
                    {session.results.map((result) => (
                      <ResultCard
                        key={result.id}
                        result={result}
                        isExpanded={expandedSessions.has(session.id)}
                      />
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* タグ追加ダイアログ */}
      <Dialog open={tagDialogOpen} onClose={() => setTagDialogOpen(false)}>
        <DialogTitle>タグを追加</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
            <Autocomplete
              options={tags}
              getOptionLabel={(option) => option.name}
              value={selectedExistingTag}
              onChange={(_, newValue) => {
                setSelectedExistingTag(newValue);
                if (newValue) setNewTagName('');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="既存のタグから選択"
                  variant="outlined"
                  fullWidth
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box
                    component="span"
                    sx={{
                      width: 14,
                      height: 14,
                      mr: 1,
                      borderRadius: '50%',
                      display: 'inline-block',
                      backgroundColor: option.color,
                    }}
                  />
                  {option.name}
                </Box>
              )}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: 'center' }}
            >
              または
            </Typography>
            <TextField
              label="新しいタグを作成"
              value={newTagName}
              onChange={(e) => {
                setNewTagName(e.target.value);
                if (e.target.value) setSelectedExistingTag(null);
              }}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTagDialogOpen(false)}>キャンセル</Button>
          <Button
            onClick={handleAddTag}
            disabled={!newTagName && !selectedExistingTag}
            variant="contained"
          >
            追加
          </Button>
        </DialogActions>
      </Dialog>

      {/* pre_prompt追加ダイアログ */}
      <Dialog
        open={prePromptDialogOpen}
        onClose={() => setPrePromptDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>pre_promptを設定</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
            <TextField
              label="pre_prompt"
              value={prePromptText}
              onChange={(e) => setPrePromptText(e.target.value)}
              multiline
              rows={16}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPrePromptDialogOpen(false)}>
            キャンセル
          </Button>
          <Button onClick={handlePrePromptUpdate} variant="contained">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* メモ追加ダイアログ */}
      <Dialog
        open={memoDialogOpen}
        onClose={() => setMemoDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>メモを設定</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
            <TextField
              label="メモ"
              value={memoText}
              onChange={(e) => setMemoText(e.target.value)}
              multiline
              rows={16}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMemoDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleMemoUpdate} variant="contained">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* 削除確認ダイアログ */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>セッションの削除</DialogTitle>
        <DialogContent>
          <Typography>
            このセッションを削除してもよろしいですか？
            <br />
            この操作は取り消せません。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>キャンセル</Button>
          <Button
            onClick={handleDeleteSession}
            color="error"
            variant="contained"
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>

      {/* 通知 */}
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={() => setNotification({ ...notification, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={notification.severity}
          onClose={() => setNotification({ ...notification, open: false })}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default SearchResults;
