import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { ComponentMetrics } from '../../services/component-service';
import { getPageTypeLabel } from '../../utils/page-type-utils';

interface CustomPageTabsProps {
  pageTypes: string[];
  selectedPageType: string;
  onChange: (event: React.SyntheticEvent | null, value: string) => void;
  metrics: ComponentMetrics[];
}

const CustomPageTabs: React.FC<CustomPageTabsProps> = ({
  pageTypes,
  selectedPageType,
  onChange,
  metrics,
}) => {
  // IXコンポーネントを持つページタイプのみをフィルタリング
  const hasIX = (pageType: string): boolean => {
    console.log(pageType, metrics);
    return metrics.some(
      (m) =>
        (m.url_page_type === pageType && m.component?.startsWith('InsightX')) ||
        // TODO: 丸井さんで一時的にPDPを表示するために条件を入れる
        (m.url_page_type === pageType &&
          m.component === '当該カテゴリーの類似アイテム'),
    );
  };

  // 表示数の合計を計算
  const getPageTypeShownTotal = (pageType: string): number => {
    return metrics
      .filter(
        (m) =>
          m.url_page_type === pageType && m.component?.startsWith('InsightX'),
      )
      .reduce((sum, m) => sum + (m.shown || 0), 0);
  };

  // IXコンポーネントを持つページタイプのみを抽出し、表示数順にソート
  const filteredAndSortedPageTypes = pageTypes
    .filter(hasIX)
    .sort((a, b) => getPageTypeShownTotal(b) - getPageTypeShownTotal(a));

  // タブが選択されていない場合、最初のタブを選択
  useEffect(() => {
    // 現在選択されているページタイプがフィルタリング後のリストに存在しない場合、
    // または選択されていない場合は、最初のタブを選択
    if (filteredAndSortedPageTypes.length > 0) {
      if (
        !selectedPageType ||
        !filteredAndSortedPageTypes.includes(selectedPageType)
      ) {
        onChange(null, filteredAndSortedPageTypes[0]);
      }
    }

    // クリーンアップ関数
    return () => {
      // コンポーネントのアンマウント時やメトリクスの更新時にクリーンアップ
      if (!filteredAndSortedPageTypes.includes(selectedPageType)) {
        onChange(null, '');
      }
    };
  }, [selectedPageType, filteredAndSortedPageTypes, onChange, metrics]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0px',
        bgcolor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      {filteredAndSortedPageTypes.map((pageType) => (
        <Box
          key={pageType}
          onClick={() => onChange(null, pageType)}
          sx={{
            px: 4,
            py: 1.5,
            cursor: 'pointer',
            bgcolor: 'transparent',
            color: selectedPageType === pageType ? '#000' : '#666',
            borderBottom:
              selectedPageType === pageType ? '2px solid #000' : 'none',
            transition: 'all 0.2s ease',
            fontWeight: selectedPageType === pageType ? 600 : 400,
            '&:hover': {
              color: '#000',
            },
          }}
        >
          {getPageTypeLabel(pageType)}
        </Box>
      ))}
    </Box>
  );
};

export default CustomPageTabs;
