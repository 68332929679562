import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ChatIcon from '@mui/icons-material/Chat';
import CodeIcon from '@mui/icons-material/Code';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupsIcon from '@mui/icons-material/Groups';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import StorageIcon from '@mui/icons-material/Storage';
import { Box, Link, Paper, Typography } from '@mui/material';
import React from 'react';
import { SearchResult } from '../services/types';

interface ResultCardProps {
  result: SearchResult;
  isExpanded: boolean;
}

const ResultCard: React.FC<ResultCardProps> = ({ result, isExpanded }) => {
  // より落ち着いた色に変更
  const typeConfig: {
    [key: string]: { color: string; icon: React.ReactNode };
  } = {
    discussion: { color: '#42a5f5', icon: <ChatIcon /> },
    analysis: { color: '#66bb6a', icon: <AnalyticsIcon /> },
    meeting: { color: '#ffa726', icon: <GroupsIcon /> },
    slack: { color: '#ec407a', icon: <ChatIcon /> },
    sql_template: { color: '#7e57c2', icon: <CodeIcon /> },
    analysis_table: { color: '#26a69a', icon: <StorageIcon /> },
    analysis_template: { color: '#5c6bc0', icon: <QueryStatsIcon /> },
  };

  // 日付をフォーマットする関数
  const formatDate = (dateStr: string | null): string => {
    if (!dateStr) return '未設定';
    try {
      const date = new Date(dateStr);
      return date.toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    } catch (e) {
      console.error('日付のフォーマットに失敗:', e);
      return '無効な日付';
    }
  };

  // NotionのURLを生成
  const notionUrl = `https://www.notion.so/insightx-tech/${result.id}`;

  return (
    <Box sx={{ mb: 1.5 }}>
      <Paper
        elevation={0}
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderLeft: `3px solid ${typeConfig[result.type]?.color || 'primary.main'}`,
          position: 'relative',
        }}
      >
        {/* メインコンテンツエリア */}
        <Box
          sx={{
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              p: 1.5,
              display: 'flex',
              gap: 1.5,
            }}
          >
            <Box sx={{ flexShrink: 0 }}>
              {typeConfig[result.type]?.icon || <DescriptionIcon />}
            </Box>
            <Box sx={{ flex: 1, minWidth: 0 }}>
              {/* タイトルと「Notionで開く」リンク */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  mb: 1,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 500,
                    flex: 1,
                    minWidth: 0,
                  }}
                >
                  {result.title}
                </Typography>
                <Link
                  href={notionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: typeConfig[result.type]?.color,
                    fontSize: '0.875rem',
                    whiteSpace: 'nowrap',
                    flexShrink: 0,
                  }}
                >
                  Notionで開く
                </Link>
              </Box>

              {/* メタデータ */}
              <Box sx={{ display: 'flex', gap: 1.5, mb: 1, flexWrap: 'wrap' }}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  <AccessTimeIcon fontSize="small" />
                  最終更新: {formatDate(result.lastEditedAt)}
                </Typography>
                {Object.entries(result.properties).map(
                  ([key, value]) =>
                    value && (
                      <Typography
                        key={key}
                        variant="caption"
                        color="text.secondary"
                      >
                        {key}: {value}
                      </Typography>
                    ),
                )}
              </Box>

              {/* サマリー */}
              {result.summary && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1 }}
                >
                  {result.summary}
                </Typography>
              )}

              {/* コンテンツ */}
              {result.content && isExpanded && (
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    color: 'text.primary',
                    mt: 2,
                    p: 2,
                    bgcolor: 'grey.50',
                    borderRadius: 1,
                    maxHeight: '500px',
                    overflow: 'auto',
                  }}
                >
                  {result.content}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ResultCard;
