import { Box, Card, CardContent, Typography } from '@mui/material';
import { format, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { ja } from 'date-fns/locale';
import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DailySSMetrics } from '../../../services/shelf-service';
import { TimeUnit } from './TimeUnitSelector';

interface OverallMetricsChartsProps {
  data: DailySSMetrics[];
  timeUnit: TimeUnit;
}

const OverallMetricsCharts: React.FC<OverallMetricsChartsProps> = ({
  data,
  timeUnit,
}) => {
  const getTimeUnitStartDate = (date: string) => {
    const dateObj = parseISO(date);
    switch (timeUnit) {
      case 'week':
        return format(startOfWeek(dateObj, { locale: ja }), 'yyyy-MM-dd');
      case 'month':
        return format(startOfMonth(dateObj), 'yyyy-MM-dd');
      default:
        return date;
    }
  };

  const processData = (metrics: DailySSMetrics[]) => {
    const groupedData = metrics.reduce(
      (acc, curr) => {
        const date = curr.ymd;
        const key = getTimeUnitStartDate(date);

        if (!acc[key]) {
          acc[key] = {
            date: key,
            shelf_shown: 0,
            shelf_shown_rate: 0,
            shelf_click: 0,
            shelf_click_rate: 0,
            shelf_cv: 0,
            shelf_cv_rate: 0,
            total_ss: 0,
            total_cv: 0,
          };
        }

        acc[key].shelf_shown += curr.shelf_shown;
        acc[key].shelf_click += curr.shelf_click;
        acc[key].shelf_cv += curr.shelf_cv;
        acc[key].total_ss += curr.ss;
        acc[key].total_cv += curr.cv || 0;

        return acc;
      },
      {} as Record<
        string,
        {
          date: string;
          shelf_shown: number;
          shelf_shown_rate: number;
          shelf_click: number;
          shelf_click_rate: number;
          shelf_cv: number;
          shelf_cv_rate: number;
          total_ss: number;
          total_cv: number;
        }
      >,
    );

    // Calculate rates
    Object.values(groupedData).forEach((data) => {
      if (data.total_ss > 0) {
        data.shelf_shown_rate = (data.shelf_shown / data.total_ss) * 100;
      }
      if (data.shelf_shown > 0) {
        data.shelf_click_rate = (data.shelf_click / data.shelf_shown) * 100;
      }
      if (data.total_cv > 0) {
        data.shelf_cv_rate = (data.shelf_cv / data.total_cv) * 100;
      }
    });

    return Object.values(groupedData).sort((a, b) =>
      a.date.localeCompare(b.date),
    );
  };

  const formatXAxisTick = (date: string) => {
    switch (timeUnit) {
      case 'week':
        return format(parseISO(date), 'M/d週', { locale: ja });
      case 'month':
        return format(parseISO(date), 'yyyy/M月', { locale: ja });
      default:
        return format(parseISO(date), 'M/d', { locale: ja });
    }
  };

  const chartData = processData(data);

  const charts = [
    {
      title: 'シェルフIMPSS数',
      dataKey: 'shelf_shown',
      rateKey: 'shelf_shown_rate',
      color: '#2196F3',
    },
    {
      title: 'シェルフクリックSS数',
      dataKey: 'shelf_click',
      rateKey: 'shelf_click_rate',
      color: '#4CAF50',
    },
    {
      title: 'シェルフ経由CV SS数',
      dataKey: 'shelf_cv',
      rateKey: 'shelf_cv_rate',
      color: '#FF9800',
    },
  ];

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: 2,
        mb: 3,
      }}
    >
      {charts.map((chart) => (
        <Card
          key={chart.title}
          sx={{
            height: '100%',
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
            bgcolor: '#FFFFFF',
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: '1rem',
                mb: 2,
                textAlign: 'left',
              }}
            >
              {chart.title}
            </Typography>
            <Box sx={{ width: '100%', height: 280 }}>
              <ResponsiveContainer>
                <ComposedChart
                  data={chartData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="rgba(0, 0, 0, 0.1)"
                  />
                  <XAxis
                    dataKey="date"
                    tickFormatter={formatXAxisTick}
                    tick={{ fontSize: 12, fill: '#666' }}
                    tickLine={{ stroke: '#ccc' }}
                    axisLine={{ stroke: '#ccc' }}
                  />
                  <YAxis
                    yAxisId="left"
                    tick={{ fontSize: 12, fill: '#666' }}
                    tickLine={{ stroke: '#ccc' }}
                    axisLine={{ stroke: '#ccc' }}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    tick={{ fontSize: 12, fill: '#666' }}
                    tickLine={{ stroke: '#ccc' }}
                    axisLine={{ stroke: '#ccc' }}
                    tickFormatter={(value) => `${value}%`}
                    domain={[0, 40]}
                  />
                  <Tooltip
                    formatter={(value: number, name: string) => {
                      if (name === '全体に対する割合') {
                        return [`${value.toFixed(1)}%`, name];
                      }
                      return [value.toLocaleString(), name];
                    }}
                    labelFormatter={(label: string) =>
                      format(parseISO(label), 'yyyy/M/d (E)', {
                        locale: ja,
                      })
                    }
                  />
                  <Legend
                    align="right"
                    verticalAlign="top"
                    height={36}
                    iconType="circle"
                    iconSize={8}
                  />
                  <Area
                    type="monotone"
                    dataKey={chart.dataKey}
                    name={chart.title}
                    yAxisId="left"
                    fill="#666666"
                    fillOpacity={0.2}
                    stroke="none"
                  />
                  <Line
                    type="monotone"
                    dataKey={chart.rateKey}
                    name={
                      chart.dataKey === 'shelf_click'
                        ? 'クリック率'
                        : '全体に対する割合'
                    }
                    yAxisId="right"
                    stroke={chart.color}
                    strokeWidth={2}
                    dot={false}
                    activeDot={{ r: 4 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default OverallMetricsCharts;
