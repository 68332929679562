import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../store/auth.store';
import { useClient } from '../../store/client.store';

const drawerWidth = 240;
const miniDrawerWidth = 60;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: miniDrawerWidth,
});

interface DrawerProps {
  open?: boolean;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface MenuItem {
  name: string;
  icon: string;
  path: string;
  subItems?: MenuItem[];
}

interface MenuItemWithIcon {
  name: string;
  icon: string;
  path: string;
  subItems?: MenuItemWithIcon[];
}

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const MenuIcon: React.FC<{ iconName: string }> = ({ iconName }) => {
  switch (iconName) {
    case 'Person':
      return <PersonIcon />;
    case 'ShoppingBag':
      return <ShoppingBagIcon />;
    case 'Search':
      return <SearchIcon />;
    case 'ViewModule':
      return <ViewModuleIcon />;
    case 'Build':
      return <BuildIcon />;
    case 'Assessment':
      return <AssessmentIcon />;
    case 'Analytics':
      return <BarChartIcon />;
    default:
      return null;
  }
};

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [openGroups, setOpenGroups] = useState<Record<string, boolean>>({});
  const { auth: authState, logout } = useAuth();
  const { client: clientState, setSelectedClient } = useClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('Dashboard');

  const generateMenuItems = (): MenuItemWithIcon[] => {
    if (!authState.user?.menuItems) return [];

    return authState.user.menuItems.map((item) => ({
      name: item.name,
      icon: item.icon,
      path: item.path,
    }));
  };

  const menuItems = generateMenuItems();

  useEffect(() => {
    const currentMenuItem = menuItems.find((item) =>
      location.pathname.startsWith(item.path),
    );
    if (currentMenuItem) {
      setCurrentPage(currentMenuItem.name);
    }
  }, [location, menuItems]);

  useEffect(() => {
    if (!clientState.selectedClient && clientState.clients.length > 0) {
      setSelectedClient(clientState.clients[0].clientId);
    }
  }, [clientState.clients, clientState.selectedClient, setSelectedClient]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const clientIdFromUrl = searchParams.get('clientId');
    if (
      clientIdFromUrl &&
      clientIdFromUrl !== clientState.selectedClient?.clientId
    ) {
      setSelectedClient(clientIdFromUrl);
    }
  }, [location.search, clientState.selectedClient, setSelectedClient]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleGroupToggle = (name: string) => {
    setOpenGroups((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const newClientId = event.target.value;
    setSelectedClient(newClientId);
  };

  const renderMenuItem = (item: MenuItemWithIcon, depth = 0) => {
    if (!item.icon) return null;

    if (item.subItems) {
      return (
        <React.Fragment key={item.name}>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={() => handleGroupToggle(item.name)}
              sx={{ pl: 2 + depth * 2 }}
            >
              <ListItemIcon>
                <MenuIcon iconName={item.icon} />
              </ListItemIcon>
              {open && <ListItemText primary={item.name} />}
              {open &&
                (openGroups[item.name] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
          </ListItem>
          <Collapse in={openGroups[item.name]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map((subItem) =>
                renderMenuItem(subItem, depth + 1),
              )}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }

    return (
      <ListItem key={item.name} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          component={Link}
          to={`${item.path}${
            clientState.selectedClient
              ? `?clientId=${clientState.selectedClient.clientId}`
              : ''
          }`}
          sx={{ pl: 2 + depth * 2 }}
        >
          <ListItemIcon>
            <MenuIcon iconName={item.icon} />
          </ListItemIcon>
          {open && <ListItemText primary={item.name} />}
        </ListItemButton>
      </ListItem>
    );
  };

  if (!authState.user || clientState.isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/dashboard')}
            >
              <img
                src="/insightx-logo.png"
                alt="InsightX Logo"
                style={{ height: '40px', marginRight: '16px' }}
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontFamily: 'Noto Sans JP, sans-serif', fontWeight: 700 }}
              >
                {currentPage}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {clientState.clients.length >= 1 && (
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="client-select-label">Client</InputLabel>
                <Select
                  labelId="client-select-label"
                  id="client-select"
                  value={clientState.selectedClient?.clientId || ''}
                  label="Client"
                  onChange={handleClientChange}
                >
                  {clientState.clients.map((client) => (
                    <MenuItem key={client.clientId} value={client.clientId}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button onClick={handleLogout}>ログアウト</Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <DrawerHeader />
        <Divider />
        <List sx={{ marginTop: '10px' }}>
          {menuItems.map((item) => renderMenuItem(item))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${miniDrawerWidth}px)` },
          ml: { sm: `${miniDrawerWidth}px` },
          transition: 'margin-left 0.2s',
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
