import { Box, Typography } from '@mui/material';
import React from 'react';
import { IShelf } from '../../../insightx-utils/types/shelf.type';

interface N1ShelfViewProps {
  shelf: IShelf;
}

interface ItemCardProps {
  aid: string;
  title: string;
  brand: string;
  imageUrl: string;
  url: string;
}

const ItemCard: React.FC<ItemCardProps> = ({ title, brand, imageUrl }) => {
  return (
    <Box
      sx={{
        width: 90,
        flexShrink: 0,
        mr: 1,
      }}
    >
      <img
        src={imageUrl}
        alt={title}
        loading="lazy"
        style={{
          width: '100%',
          height: 120,
          objectFit: 'cover',
          borderRadius: 4,
          marginBottom: 4,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: '#666',
          fontSize: '0.7rem',
          mb: 0.25,
        }}
      >
        {brand}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: '0.75rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          lineHeight: 1.2,
          height: '2.4em',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const N1ShelfView: React.FC<N1ShelfViewProps> = ({ shelf }) => {
  return (
    <Box sx={{ mb: 3 }}>
      {/* シェルフのタイトル */}
      <Box sx={{ mb: 1 }}>
        {shelf.tagline2 && (
          <Typography
            variant="h6"
            sx={{
              fontSize: '0.8rem',
              fontWeight: 'bold',
            }}
          >
            {shelf.tagline2}
          </Typography>
        )}
        {shelf.tagline1 && (
          <Typography
            variant="body2"
            sx={{
              color: '#666',
              fontSize: '0.7rem',
              mb: 0.25,
            }}
          >
            {shelf.tagline1}
          </Typography>
        )}
      </Box>

      {/* 商品リスト */}
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          pb: 1,
          '&::-webkit-scrollbar': {
            height: 3,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: 1.5,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ddd',
            borderRadius: 1.5,
          },
        }}
      >
        {shelf.items?.map((item, index) => (
          <ItemCard
            key={`${item.aid}-${index}`}
            aid={item.aid}
            title={item.title}
            brand={item.brand}
            imageUrl={item.imageUrl}
            url={item.url}
          />
        ))}
      </Box>
    </Box>
  );
};

export default N1ShelfView;
