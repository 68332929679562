import api from './api';

export interface ComponentMetrics {
  url_page_type: string;
  component: string;
  type: 'component' | 'shelf';
  shown: number;
  click: number;
  favorite: number;
  cv: number;
  ctr: number | null;
  favoriterate: number | null;
  cvr: number | null;
}

export interface ComponentMetricsV2 {
  page_name: string;
  component_name: string;
  shown: number;
  click: number;
  favorite: number;
  cv: number;
  clickr: number | null;
  favoriter: number | null;
  cvr: number | null;
}

export interface ComponentStats {
  stats: ComponentMetrics[];
}

export interface ComponentStatsV2 {
  stats: ComponentMetricsV2[];
}

export interface GetComponentStatsRequest {
  start_date: string;
  end_date: string;
}

export const componentService = {
  async getComponentStats(
    params: GetComponentStatsRequest,
  ): Promise<ComponentStats> {
    const response = await api.post('/component/stats', params);
    const result: ComponentStats = response.data;
    result.stats = result.stats.filter((m) => {
      if (m.type === 'component' && m.component.startsWith('IX')) {
        return false;
      }
      if (['InsightXシェルフ', 'Shelf', 'Shelf2'].includes(m.component)) {
        return false;
      }
      return true;
    });
    return result;
  },

  async getComponentStatsV2(
    params: GetComponentStatsRequest,
  ): Promise<ComponentStatsV2> {
    const response = await api.post('/component/stats/v2', params);
    return response.data;
  },
};
