/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { RecommendLogicEnum } from '../../enums/recommend-logic.enum';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';
import { IShelfConfig } from '../../types/shelf-config.type';
import { IShelfOrder } from '../../types/shelf-order.type';
import ShelfOrderForm from './ShelfOrderForm';

const BuilderContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
`;

interface ShelfConfigBuilderProps {
  shelfConfig: IShelfConfig;
  onConfigChange: (newConfig: IShelfConfig) => void;
  baseType: 'user-based' | 'item-based';
}

const ShelfConfigBuilder: React.FC<ShelfConfigBuilderProps> = ({
  shelfConfig,
  onConfigChange,
  baseType,
}) => {
  const [config, setConfig] = useState<IShelfConfig>(shelfConfig);
  const [baseRecommendLogic, setBaseRecommendLogic] =
    useState<RecommendLogicEnum>(RecommendLogicEnum.Covis);
  const [searchParams, setSearchParams] = useSearchParams();

  // 初期ロード時に URL パラメータから config を読み込む
  useEffect(() => {
    const configParam = searchParams.get('config');
    if (configParam) {
      try {
        const decodedConfig = JSON.parse(decodeURIComponent(configParam));
        setConfig(decodedConfig);
        onConfigChange(decodedConfig);
      } catch (error) {
        console.error('URL からの config のパースに失敗しました:', error);
      }
    } else {
      setConfig(shelfConfig);
    }
  }, []); // 初回のみ実行

  // config が変更されたときに URL パラメータを更新
  useEffect(() => {
    const encodedConfig = encodeURIComponent(JSON.stringify(config));
    if (encodedConfig !== searchParams.get('config')) {
      setSearchParams({ config: encodedConfig });
    }
  }, [config]); // config が変更されたときのみ実行

  const handleChange = (field: keyof IShelfConfig, value: any) => {
    const updatedConfig = {
      ...config,
      historyFilteredItemNum: 100,
      enableCoVisForyou: true,
      enableColdStart: true,
      [field]: value,
    };
    setConfig(updatedConfig);
    onConfigChange(updatedConfig);
  };

  const handleBaseRecommendLogicChange = (newLogic: RecommendLogicEnum) => {
    setBaseRecommendLogic(newLogic);
    if (baseType === 'item-based') {
      const updatedShelfOrders = updateShelfOrdersWithBaseLogic(
        config.shelfOrders || [],
        newLogic,
      );
      handleChange('shelfOrders', updatedShelfOrders);
    }
  };

  const updateShelfOrdersWithBaseLogic = (
    shelfOrders: IShelfOrder[],
    logic: RecommendLogicEnum,
  ): IShelfOrder[] => {
    return shelfOrders.map((shelfOrder) => {
      const updatedShelfOrder = { ...shelfOrder };
      if (baseType === 'item-based') {
        updatedShelfOrder.baseShelfOrders = [
          {
            shelfType: ShelfTypeEnum.related,
            shelfNum: 1,
            recommendLogic: logic,
          },
        ];
      }
      if (shelfOrder.shelfOrders && shelfOrder.shelfOrders.length > 0) {
        updatedShelfOrder.shelfOrders = updateShelfOrdersWithBaseLogic(
          shelfOrder.shelfOrders,
          logic,
        );
      }
      return updatedShelfOrder;
    });
  };

  const handleAddShelfOrder = () => {
    const newShelfOrder: IShelfOrder = {
      shelfType:
        baseType === 'item-based'
          ? ShelfTypeEnum.related
          : ShelfTypeEnum.history,
      shelfNum: 1,
      tagline2Template: '',
      maxItems: 50,
    };
    if (baseType === 'item-based') {
      newShelfOrder.baseShelfOrders = [
        {
          shelfType: ShelfTypeEnum.related,
          shelfNum: 1,
          recommendLogic: baseRecommendLogic,
        },
      ];
    }
    const updatedShelfOrders = [...(config.shelfOrders || []), newShelfOrder];
    handleChange('shelfOrders', updatedShelfOrders);
  };

  const handleUpdateShelfOrder = (
    index: number,
    updatedShelfOrder: IShelfOrder,
  ) => {
    const updatedShelfOrders = config.shelfOrders?.map((shelfOrder, i) =>
      i === index ? updatedShelfOrder : shelfOrder,
    );
    handleChange('shelfOrders', updatedShelfOrders);
    console.log('updatedShelfOrder:', updatedShelfOrder);
  };

  const handleRemoveShelfOrder = (index: number) => {
    const updatedShelfOrders = config.shelfOrders?.filter(
      (_, i) => i !== index,
    );
    handleChange('shelfOrders', updatedShelfOrders);
  };

  return (
    <BuilderContainer>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: '20px',
          fontWeight: 'bold',
        }}
      >
        シェルフ設定
      </Typography>
      {baseType === 'item-based' && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>ベースのRecommend Logic</InputLabel>
          <Select
            value={baseRecommendLogic}
            onChange={(e) =>
              handleBaseRecommendLogicChange(
                e.target.value as RecommendLogicEnum,
              )
            }
            label="ベースのRecommend Logic"
          >
            <MenuItem value={RecommendLogicEnum.Covis}>Covis</MenuItem>
            <MenuItem value={RecommendLogicEnum.TwoTower}>Two Tower</MenuItem>
            <MenuItem value={RecommendLogicEnum.Cart2All}>Cart2All</MenuItem>
          </Select>
        </FormControl>
      )}
      {config.shelfOrders &&
        config.shelfOrders.map((shelfOrder, index) => (
          <ShelfOrderForm
            key={index}
            shelfOrder={shelfOrder}
            onUpdate={(updatedShelfOrder) =>
              handleUpdateShelfOrder(index, updatedShelfOrder)
            }
            onRemove={() => handleRemoveShelfOrder(index)}
            baseType={baseType}
            baseRecommendLogic={baseRecommendLogic}
          />
        ))}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleAddShelfOrder}
        sx={{ color: 'white', marginTop: '10px' }}
      >
        シェルフを追加
      </Button>

      <Accordion sx={{ marginTop: '30px', borderRadius: '10px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: '700' }}>
            現在のシェルフ設定JSON
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SyntaxHighlighter language="json" style={coy}>
            {JSON.stringify(config, null, 2)}
          </SyntaxHighlighter>
        </AccordionDetails>
      </Accordion>
    </BuilderContainer>
  );
};

export default ShelfConfigBuilder;
