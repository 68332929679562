import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RestoreIcon from '@mui/icons-material/Restore';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { postShelfItemsSearch } from '../../services/shelf-builder/post-shelf-items-search';
import { postStgSearchShelfQueries } from '../../services/shelf-builder/post-stg-search-shelf-queries';
import { postShelfTaglinesKnn } from '../../services/shelf-previewer/post-shelf-taglines-knn';
import { IShelfSearchItem } from '../../types/post-shelf-item-search.type';
import {
  IStgSearchShelfQuery,
  ShelfStatus,
} from '../../types/stg-search-shelf-query.type';
import ItemSlider from './ItemSlider';
import { SimilarShelvesState } from './ShelfBuilder';
import SimilarShelves from './SimilarShelves';
import { InputContainer, ShelfContainer } from './styles';

interface ShelfEditorProps {
  initialShelf: IStgSearchShelfQuery;
  onDeleteStatusChange?: (shelfId: string, status: ShelfStatus) => void;
}

interface ShelfState extends IStgSearchShelfQuery {
  updating: boolean;
  items?: IShelfSearchItem[];
  loading?: boolean;
  visibleItems?: IShelfSearchItem[];
  item_count?: number;
  status: ShelfStatus;
}

const ShelfEditor: React.FC<ShelfEditorProps> = ({
  initialShelf,
  onDeleteStatusChange,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [shelf, setShelf] = useState<ShelfState>({
    ...initialShelf,
    updating: false,
    loading: false,
    status: initialShelf.status || ShelfStatus.DRAFT,
  });
  const [similarShelves, setSimilarShelves] = useState<SimilarShelvesState>([]);

  const fetchSimilarShelves = useCallback(async (tagline: string) => {
    try {
      const knnResults = await postShelfTaglinesKnn([tagline]);
      setSimilarShelves(knnResults[tagline] || []);
    } catch (error) {
      console.error('Error fetching similar shelves:', error);
    }
  }, []);

  const fetchShelfItems = useCallback(async () => {
    if (!shelf.where_condition) return;

    try {
      setShelf((prev) => ({ ...prev, loading: true }));
      const searchRes = await postShelfItemsSearch({
        where_condition: shelf.where_condition,
        order_by: 'pv_count_7d desc',
        limit: 50,
      });
      const itemCount = searchRes.item_count || 0;

      postStgSearchShelfQueries([
        {
          ...shelf,
          item_count: itemCount,
          updated_at: new Date(),
        },
      ]);

      // メモリ使用料削減のため展開されている場合のみitemsとvisibleItemsを更新
      setShelf((prev) => ({
        ...prev,
        items: expanded ? searchRes.items : undefined,
        visibleItems: expanded ? searchRes.items?.slice(0, 30) : undefined,
        item_count: itemCount,
        loading: false,
      }));
    } catch (error) {
      console.error('Error fetching shelf items:', error);
      setShelf((prev) => ({
        ...prev,
        item_count: -1,
        loading: false,
      }));
    }
  }, [shelf.where_condition, shelf.status, onDeleteStatusChange, expanded]);

  const handleFieldChange = useCallback(
    async (field: keyof ShelfState, value: string) => {
      setShelf((prev) => ({
        ...prev,
        [field]: value,
        updating: true,
      }));

      try {
        const updatedShelf = {
          ...shelf,
          [field]: value,
        };
        await postStgSearchShelfQueries([updatedShelf]);
        setShelf((prev) => ({ ...prev, updating: false }));
      } catch (error) {
        console.error(error);
        setShelf((prev) => ({ ...prev, updating: false }));
      }
    },
    [shelf],
  );

  const handleLoadMore = useCallback(() => {
    setShelf((prev) => {
      const currentVisibleCount = prev.visibleItems?.length || 0;
      const nextVisibleCount = Math.min(
        currentVisibleCount + 20,
        prev.items?.length || 0,
      );
      return {
        ...prev,
        visibleItems: prev.items?.slice(0, nextVisibleCount),
      };
    });
  }, []);

  const handleTaglineBlur = useCallback(async () => {
    if (!shelf.tagline_2) return;
    await fetchSimilarShelves(shelf.tagline_2);
  }, [shelf.tagline_2, fetchSimilarShelves]);

  const handleWhereConditionBlur = useCallback(() => {
    fetchShelfItems();
  }, [fetchShelfItems]);

  const handleApplyImprovedTagline = useCallback(
    async (tagline: string) => {
      if (!tagline) return;

      try {
        setShelf((prev) => ({ ...prev, updating: true }));
        const updatedShelf = {
          ...shelf,
          tagline_2: tagline,
        };

        await postStgSearchShelfQueries([updatedShelf]);
        setShelf((prev) => ({ ...prev, ...updatedShelf, updating: false }));
        await fetchSimilarShelves(updatedShelf.tagline_2);
      } catch (error) {
        console.error(error);
        setShelf((prev) => ({ ...prev, updating: false }));
      }
    },
    [shelf, fetchSimilarShelves],
  );

  const handleToggleApprove = useCallback(async () => {
    try {
      setShelf((prev) => ({ ...prev, updating: true }));
      const newStatus =
        shelf.status === ShelfStatus.APPROVED
          ? ShelfStatus.DRAFT
          : ShelfStatus.APPROVED;
      const updatedShelf = {
        ...shelf,
        status: newStatus,
      };

      await postStgSearchShelfQueries([updatedShelf]);
      setShelf((prev) => ({ ...prev, ...updatedShelf, updating: false }));
      setExpanded(false);
    } catch (error) {
      console.error(error);
      setShelf((prev) => ({ ...prev, updating: false }));
    }
  }, [shelf]);

  const handleDelete = useCallback(async () => {
    try {
      setShelf((prev) => ({ ...prev, updating: true }));
      const updatedShelf = {
        ...shelf,
        status: ShelfStatus.DELETED,
      };

      await postStgSearchShelfQueries([updatedShelf]);
      setShelf((prev) => ({ ...prev, ...updatedShelf, updating: false }));
      if (onDeleteStatusChange) {
        onDeleteStatusChange(shelf.shelf_id, ShelfStatus.DELETED);
      }
    } catch (error) {
      console.error(error);
      setShelf((prev) => ({ ...prev, updating: false }));
    }
  }, [shelf, onDeleteStatusChange]);

  const handleRestore = useCallback(async () => {
    try {
      setShelf((prev) => ({ ...prev, updating: true }));
      const updatedShelf = {
        ...shelf,
        status: ShelfStatus.DRAFT,
      };

      await postStgSearchShelfQueries([updatedShelf]);
      setShelf((prev) => ({ ...prev, ...updatedShelf, updating: false }));
      if (onDeleteStatusChange) {
        onDeleteStatusChange(shelf.shelf_id, ShelfStatus.DRAFT);
      }
    } catch (error) {
      console.error(error);
      setShelf((prev) => ({ ...prev, updating: false }));
    }
  }, [shelf, onDeleteStatusChange]);

  const formatMatchResult = (result: string) => {
    if (result === 'MATCH') {
      return '類似あり';
    } else if (result === 'NOT MATCH') {
      return '類似なし';
    } else {
      return '-';
    }
  };

  useEffect(() => {
    if (
      shelf.item_count == null &&
      !shelf.loading &&
      shelf.status !== ShelfStatus.DELETED
    ) {
      fetchShelfItems();
    }
  }, [shelf.item_count, shelf.loading, shelf.status, fetchShelfItems]);

  useEffect(() => {
    if (expanded && !shelf.loading && shelf.items === undefined) {
      fetchShelfItems();
    }
  }, [expanded, fetchShelfItems, shelf.items]);

  const extractItemTitleSearchStrings = (where_condition: string): string[] => {
    if (!where_condition) return [];

    const matches =
      where_condition.match(/item_title (?:like|LIKE) '%([^']+)%'/g) || [];
    return matches.map(
      (match) => match.match(/item_title (?:like|LIKE) '%([^']+)%'/)[1],
    );
  };

  return (
    <ShelfContainer>
      <Accordion
        expanded={expanded}
        onChange={(_, isExpanded) => setExpanded(isExpanded)}
      >
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: '0 !important',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              '& > *': {
                flex: '0 0 auto',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                mr: 2,
                width: '300px',
              }}
            >
              {shelf.tagline_2 || 'No tagline'}
            </Typography>
            <Box
              sx={{
                width: '100px',
                mr: 2,
              }}
            >
              {shelf.status === ShelfStatus.DRAFT && (
                <Chip
                  label="下書き"
                  size="small"
                  color="default"
                  icon={<EditNoteIcon />}
                  sx={{ height: '20px' }}
                />
              )}
              {shelf.status === ShelfStatus.APPROVED && (
                <Chip
                  label="承認済み"
                  size="small"
                  color="success"
                  icon={<CheckCircleIcon />}
                  sx={{ height: '20px' }}
                />
              )}
              {shelf.status === ShelfStatus.DEPLOYED && (
                <Chip
                  label="デプロイ済み"
                  size="small"
                  color="info"
                  icon={<RocketLaunchIcon />}
                  sx={{ height: '20px', ml: 0.5 }}
                />
              )}
              {shelf.status === ShelfStatus.DELETED && (
                <Chip
                  label="削除済み"
                  size="small"
                  color="error"
                  icon={<DeleteIcon />}
                  sx={{ height: '20px', ml: 0.5 }}
                />
              )}
            </Box>
            <Typography
              variant="body2"
              sx={{
                width: '100px',
                mr: 2,
                textAlign: 'left',
              }}
            >
              {shelf.category || '-'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                width: '100px',
                mr: 2,
                textAlign: 'left',
              }}
            >
              {shelf.sub_category || '-'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                width: '100px',
                mr: 2,
                textAlign: 'center',
                color:
                  shelf.tagline_2 && shelf.tagline_2.length >= 19
                    ? 'red'
                    : 'inherit',
              }}
            >
              {shelf.tagline_2 ? shelf.tagline_2.length : '-'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                width: '80px',
                mr: 2,
                textAlign: 'center',
                color:
                  shelf.similarity_check_result === 'NOT MATCH'
                    ? 'green'
                    : shelf.similarity_check_result === 'MATCH'
                      ? 'red'
                      : 'inherit',
              }}
            >
              {formatMatchResult(shelf.similarity_check_result)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                width: '50px',
                mr: 2,
                textAlign: 'center',
                color: Number(shelf.quality_score) >= 8 ? 'green' : 'red',
              }}
            >
              {typeof shelf.quality_score === 'number'
                ? shelf.quality_score
                : '-'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                width: '80px',
                mr: 2,
                textAlign: 'center',
              }}
            >
              {shelf.loading ? (
                <CircularProgress size={18} />
              ) : shelf.item_count === null ? (
                '-'
              ) : shelf.item_count === -1 ? (
                'エラー'
              ) : (
                shelf.item_count
              )}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                width: '50px',
                textAlign: 'center',
                color: Number(shelf.relevance_score) >= 8 ? 'green' : 'red',
              }}
            >
              {typeof shelf.relevance_score === 'number'
                ? shelf.relevance_score
                : '-'}
            </Typography>
            <Box
              sx={{
                width: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                color={
                  shelf.status === ShelfStatus.APPROVED ? 'error' : 'success'
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleApprove();
                }}
                size="small"
                disabled={
                  shelf.status === ShelfStatus.DELETED ||
                  shelf.status === ShelfStatus.DEPLOYED
                }
              >
                {shelf.status === ShelfStatus.APPROVED
                  ? '承認取消'
                  : '承認する'}
              </Button>
              {shelf.status === ShelfStatus.DELETED ? (
                <Button
                  variant="outlined"
                  color="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRestore();
                  }}
                  size="small"
                  startIcon={<RestoreIcon />}
                >
                  復活
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                  size="small"
                  startIcon={<DeleteIcon />}
                >
                  削除
                </Button>
              )}
              {shelf.updating && <CircularProgress size={24} sx={{ ml: 1 }} />}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <InputContainer>
            <Box>
              <TextField
                label="タグライン"
                value={shelf.tagline_2}
                onChange={(e) => handleFieldChange('tagline_2', e.target.value)}
                onBlur={() => {
                  handleFieldChange('tagline_2', shelf.tagline_2);
                  handleTaglineBlur();
                }}
                size="small"
                placeholder="タグラインを入力"
                sx={{ width: '100%' }}
              />

              <Box sx={{ display: 'flex', gap: 2, mt: 1, ml: 2 }}>
                {/* 左カラム: 類似タグライン */}
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    類似タグライン
                  </Typography>

                  {Array.isArray(shelf.similarity_check_examples) &&
                  shelf.similarity_check_examples.length > 0 ? (
                    <Box
                      sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        p: 1.5,
                      }}
                    >
                      <Box
                        component="table"
                        sx={{
                          width: '100%',
                          borderCollapse: 'collapse',
                          '& td': {
                            p: 0.5,
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                          },
                          fontSize: '0.875rem',
                          textAlign: 'left',
                        }}
                      >
                        <Box component="thead">
                          <Box component="tr">
                            <Box component="th">タグライン</Box>
                            <Box component="th">理由</Box>
                          </Box>
                        </Box>
                        {shelf.similarity_check_examples.map(
                          (example, index) => (
                            <Box component="tr" key={index}>
                              <Box
                                component="td"
                                sx={{
                                  wordBreak: 'break-all',
                                }}
                              >
                                {example.tagline}
                              </Box>
                              <Box component="td">{example.reason}</Box>
                            </Box>
                          ),
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="body2">
                      類似タグラインはありません
                    </Typography>
                  )}
                </Box>

                {/* 右カラム: タグライン品質 */}
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    タグライン品質
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      p: 1.5,
                    }}
                  >
                    {typeof shelf.quality_score === 'number' && (
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 1,
                          fontWeight: 'bold',
                        }}
                      >
                        品質スコア:
                        {
                          <Box
                            sx={{
                              color:
                                Number(shelf.quality_score) >= 8
                                  ? 'green'
                                  : 'red',
                              display: 'inline-block',
                              ml: 1,
                            }}
                          >
                            {shelf.quality_score}
                          </Box>
                        }
                      </Typography>
                    )}
                    {shelf.quality_reason && (
                      <Typography
                        variant="body2"
                        sx={{ mb: 1, whiteSpace: 'pre-wrap' }}
                      >
                        理由: {shelf.quality_reason}
                      </Typography>
                    )}
                    {shelf.improved_taglines &&
                      shelf.improved_taglines.length > 0 && (
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="body2" sx={{ mb: 0.5 }}>
                            改善案:
                          </Typography>
                          {shelf.improved_taglines.map((tagline, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: 'action.hover',
                                p: 1,
                                borderRadius: 1,
                                mb: 1,
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ flex: 1, wordBreak: 'break-all' }}
                              >
                                {tagline}
                              </Typography>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() =>
                                  handleApplyImprovedTagline(tagline)
                                }
                                startIcon={<CheckIcon />}
                                sx={{ minWidth: 'auto' }}
                              >
                                適用
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mt: 2, mb: 2 }}>
              <TextField
                label="カテゴリ"
                value={shelf.category}
                onChange={(e) => handleFieldChange('category', e.target.value)}
                onBlur={() => handleFieldChange('category', shelf.category)}
                size="small"
                placeholder="カテゴリを入力"
                sx={{ width: '200px' }}
              />
              <TextField
                label="サブカテゴリ"
                value={shelf.sub_category}
                onChange={(e) =>
                  handleFieldChange('sub_category', e.target.value)
                }
                onBlur={() =>
                  handleFieldChange('sub_category', shelf.sub_category)
                }
                size="small"
                placeholder="サブカテゴリを入力"
                sx={{ width: '200px' }}
              />
            </Box>

            <Box>
              <TextField
                label="Where条件"
                value={shelf.where_condition}
                onChange={(e) =>
                  handleFieldChange('where_condition', e.target.value)
                }
                onBlur={handleWhereConditionBlur}
                size="small"
                multiline
                rows={2}
                placeholder="Where条件を入力"
                sx={{ width: '100%' }}
              />
              {(typeof shelf.relevance_score === 'number' ||
                shelf.relevance_reason ||
                (shelf.improved_where_condition &&
                  shelf.improved_where_condition !== '変更なし')) && (
                <Typography
                  variant="body2"
                  sx={{
                    color: Number(shelf.relevance_score) >= 8 ? 'green' : 'red',
                    mt: 0.5,
                    ml: 2,
                  }}
                >
                  {<div>関連度スコア: {shelf.relevance_score}</div>}
                  {shelf.relevance_reason && (
                    <div>関連度理由: {shelf.relevance_reason}</div>
                  )}
                  {shelf.improved_where_condition &&
                    shelf.improved_where_condition !== '変更なし' && (
                      <div>改善案: {shelf.improved_where_condition}</div>
                    )}
                </Typography>
              )}
            </Box>
          </InputContainer>

          <SimilarShelves similarShelves={similarShelves} />

          {shelf.loading ? (
            <CircularProgress />
          ) : (
            expanded &&
            shelf.visibleItems &&
            shelf.visibleItems.length > 0 && (
              <ItemSlider
                items={shelf.items || []}
                visibleItems={shelf.visibleItems}
                onLoadMore={handleLoadMore}
                itemTitleSearchStrings={extractItemTitleSearchStrings(
                  shelf.where_condition,
                )}
              />
            )
          )}
        </AccordionDetails>
      </Accordion>
    </ShelfContainer>
  );
};

export default ShelfEditor;
