import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ItemShelfPreviewer from './components/item-shelf-previewer/ItemShelfPreviewer';
import DashboardLayout from './components/layout/DashboardLayout';
import ListShelfPreviewer from './components/list-shelf-previewer/ListShelfPreviewer';
import MetricsViewer from './components/metrics-viewer/page/MetricsViewer';
import LoginPage from './components/pages/LoginPage';
import PrivateRoute from './components/route/PrivateRoute';
import ShelfBuilder from './components/shelf-builder/ShelfBuilder';
import ShelfPreviewerPage from './components/shelf-previewer/ShelfPreviewerPage';
import { ConfigUtil } from './config/config-util';
import AnalysisPage from './pages/analysis/AnalysisPage';
import GptContextPage from './pages/gpt-context/GptContextPage';
import { AuthProvider } from './providers/AuthProvider';
import { ClientProvider } from './providers/ClientProvider';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FF6600',
    },
    secondary: {
      main: '#FF9933',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#333333',
    },
  },
  typography: {
    fontFamily: '"Noto Sans JP", "Helvetica", "Arial", sans-serif',
  },
});

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={ConfigUtil.getGoogleCliendId()}>
          <BrowserRouter>
            <AuthProvider>
              <ClientProvider>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route element={<PrivateRoute />}>
                    <Route
                      path="/dashboard/*"
                      element={
                        <DashboardLayout>
                          <Routes>
                            <Route
                              path="shelf-previewer"
                              element={<ShelfPreviewerPage />}
                            />
                            <Route
                              path="item-shelf-previewer"
                              element={<ItemShelfPreviewer />}
                            />
                            <Route
                              path="list-shelf-previewer"
                              element={<ListShelfPreviewer />}
                            />
                            <Route
                              path="shelf-builder"
                              element={<ShelfBuilder />}
                            />
                            <Route
                              path="metrics-viewer"
                              element={<MetricsViewer />}
                            />
                            <Route path="analysis" element={<AnalysisPage />} />
                            <Route
                              path="gpt-context"
                              element={<GptContextPage />}
                            />
                          </Routes>
                        </DashboardLayout>
                      }
                    />
                  </Route>
                  <Route path="/" element={<Navigate to="/login" replace />} />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
              </ClientProvider>
            </AuthProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
