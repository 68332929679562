import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import {
  DEFAULT_SEARCH_LIMITS,
  SearchMode,
  SearchRequest,
  SearchType,
  SLACK_CHANNELS,
  Tag,
} from '../services/types';

interface SearchFormProps {
  onSearch: (request: SearchRequest) => void;
  isLoading: boolean;
  tags: Tag[];
  selectedTags: string[];
  onTagsChange: (tags: string[]) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({
  onSearch,
  isLoading,
  tags,
  selectedTags,
  onTagsChange,
}) => {
  const [query, setQuery] = useState('');
  const [type, setType] = useState<SearchType>('discussion');
  const [mode, setMode] = useState<SearchMode>('llm');
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [limit, setLimit] = useState<number>(DEFAULT_SEARCH_LIMITS.discussion);
  const [isChannelSelectOpen, setIsChannelSelectOpen] = useState(false);

  // 検索タイプに応じたデフォルトの正規表現を取得
  const getDefaultRegexp = (searchType: SearchType) => {
    switch (searchType) {
      case 'discussion':
      case 'analysis':
      case 'meeting':
      case 'slack':
      default:
        return '.*'; // すべてにマッチする正規表現
    }
  };

  // 検索タイプが変更されたときの処理
  const handleTypeChange = (newType: SearchType) => {
    setType(newType);
    setLimit(DEFAULT_SEARCH_LIMITS[newType]); // デフォルトの取得件数を設定
    if (mode === 'regexp') {
      setQuery(getDefaultRegexp(newType));
    }
  };

  // 検索モードが変更されたときの処理
  const handleModeChange = (newMode: SearchMode) => {
    setMode(newMode);
    if (newMode === 'regexp') {
      setQuery(getDefaultRegexp(type));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const request: SearchRequest = {
      query,
      type,
      mode,
      limit,
      ...(type === 'slack' && {
        channels: selectedChannels
          .map(
            (channelId) =>
              SLACK_CHANNELS.find((c) => c.id === channelId)?.name ?? '',
          )
          .filter(Boolean),
      }),
      tag_ids: selectedTags,
    };
    onSearch(request);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 1 }}>
      <Box sx={{ display: 'flex', gap: 1.5, mb: 1.5 }}>
        <TextField
          size="small"
          fullWidth
          label={mode === 'regexp' ? '正規表現' : '検索クエリ'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          sx={{ flex: 1 }}
        />
        <FormControl size="small" sx={{ minWidth: 140 }}>
          <InputLabel>検索タイプ</InputLabel>
          <Select
            value={type}
            label="検索タイプ"
            onChange={(e) => handleTypeChange(e.target.value as SearchType)}
          >
            <MenuItem value="discussion">ディスカッション</MenuItem>
            <MenuItem value="analysis">分析</MenuItem>
            <MenuItem value="meeting">ミーティング</MenuItem>
            <MenuItem value="slack">Slack</MenuItem>
            <MenuItem value="sql_template">SQLテンプレート</MenuItem>
            <MenuItem value="analysis_table">分析テーブル</MenuItem>
            <MenuItem value="analysis_template">分析テンプレート</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 100 }}>
          <InputLabel>モード</InputLabel>
          <Select
            value={mode}
            label="モード"
            onChange={(e) => handleModeChange(e.target.value as SearchMode)}
          >
            <MenuItem value="llm">LLM</MenuItem>
            <MenuItem value="regexp">正規表現</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 90 }}>
          <InputLabel>件数</InputLabel>
          <Select
            value={limit}
            label="件数"
            onChange={(e) => setLimit(Number(e.target.value))}
          >
            <MenuItem value={3}>3件</MenuItem>
            <MenuItem value={5}>5件</MenuItem>
            <MenuItem value={10}>10件</MenuItem>
            <MenuItem value={20}>20件</MenuItem>
            <MenuItem value={50}>50件</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          size="small"
          multiple
          options={tags}
          getOptionLabel={(option) => option.name}
          value={tags.filter((tag) => selectedTags.includes(tag.name))}
          onChange={(_, newValue) => {
            onTagsChange(newValue.map((tag) => tag.name));
          }}
          sx={{ minWidth: 120 }}
          renderInput={(params) => <TextField {...params} label="タグ" />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                label={option.name}
                {...getTagProps({ index })}
                key={option.name}
                sx={{
                  backgroundColor: option.color + '22',
                  color: option.color,
                  '& .MuiChip-deleteIcon': {
                    color: option.color,
                  },
                }}
              />
            ))
          }
        />
      </Box>

      {/* Slackチャンネル選択 */}
      {type === 'slack' && (
        <Box sx={{ mb: 1.5 }}>
          <Autocomplete
            size="small"
            multiple
            open={isChannelSelectOpen}
            onOpen={() => setIsChannelSelectOpen(true)}
            onClose={() => setIsChannelSelectOpen(false)}
            disableCloseOnSelect
            options={SLACK_CHANNELS}
            getOptionLabel={(option) => option.name}
            value={SLACK_CHANNELS.filter((channel) =>
              selectedChannels.includes(channel.id),
            )}
            onChange={(_, newValue) => {
              setSelectedChannels(newValue.map((channel) => channel.id));
              setIsChannelSelectOpen(true);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="検索対象チャンネル"
                placeholder="チャンネルを選択"
                onClick={() => setIsChannelSelectOpen(true)}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size="small"
                  label={option.name}
                  {...getTagProps({ index })}
                  key={option.id}
                  onDelete={() => {
                    const newSelectedChannels = selectedChannels.filter(
                      (id) => id !== option.id,
                    );
                    setSelectedChannels(newSelectedChannels);
                    setIsChannelSelectOpen(true);
                  }}
                />
              ))
            }
          />
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          variant="contained"
          size="small"
          disabled={
            isLoading ||
            !query.trim() ||
            (type === 'slack' && selectedChannels.length === 0)
          }
          startIcon={isLoading && <CircularProgress size={16} />}
        >
          検索
        </Button>
      </Box>
    </Box>
  );
};

export default SearchForm;
