import type {
  AnalysisReport,
  AnalysisTemplate,
  AnalysisVariable,
  AnalyticsTable,
  DeleteAnalysisVariableRequest,
  GenerateSqlFromAnalysisRequest,
  GetAnalysisDetailsRequest,
  GetAnalysisDetailsResponse,
  GetAnalysisVariablesResponse,
  SaveAnalysisVariableRequest,
  SearchDebugInfo,
  SearchHistoryItem,
} from '../pages/analysis/types';
import api from './api';

export interface SearchAnalysisTemplatesResponse {
  templates: AnalysisTemplate[];
  debug?: SearchDebugInfo;
  cost?: number;
}

export interface SearchAnalysisReportsResponse {
  reports: AnalysisReport[];
  debug?: SearchDebugInfo;
  cost?: number;
}

export interface GetAnalyticsTablesResponse {
  tables: AnalyticsTable[];
}

export interface GetSearchHistoryResponse {
  history: SearchHistoryItem[];
}

export interface SearchTemplatesFromAnalysisRequest {
  message_id: string;
  analysis_id: string;
  query: string;
  skip_cache?: boolean;
}

export interface GenerateSqlFromAnalysisResponse {
  sql: string;
  debug?: SearchDebugInfo;
}

export interface UpdateGeneratedSqlRequest {
  message_id: string;
  analysis_id: string;
  sql: string;
}

export interface CheckSqlRequest {
  sql: string;
  variables: { [key: string]: string };
}

export interface CheckSqlResponse {
  is_valid: boolean;
  error_message?: string;
  scan_bytes?: number;
}

export interface FixSqlRequest {
  sql: string;
  error_message?: string;
  fix_request: string;
  message_id: string;
  analysis_id: string;
  template_ids: string[];
}

export interface FixSqlResponse {
  sql: string;
  explanation: string;
  prompt: string;
}

export interface ExecuteSqlRequest {
  message_id: string;
  analysis_id: string;
  sql: string;
  variables: { [key: string]: string };
  skip_cache?: boolean;
}

export interface ExecuteSqlResponse {
  columns: string[];
  rows: Record<string, any>[];
  scan_bytes: number;
  execution_time: number;
  is_cached: boolean;
}

export interface GenerateVisualizationCodeRequest {
  message_id: string;
  analysis_id: string;
  sql: string;
  sql_result: {
    columns: string[];
    rows: Record<string, any>[];
    scan_bytes: number;
    execution_time: number;
    is_cached: boolean;
  };
  purpose: string;
  target_metrics: string[];
  dimensions: string[];
  skip_cache?: boolean;
}

export interface GenerateVisualizationCodeResponse {
  code: string;
  explanation: string;
  library: string;
  prompt?: string;
}

export interface FixVisualizationCodeRequest {
  message_id: string;
  analysis_id: string;
  code: string;
  library: string;
  fix_request: string;
  sql_result: {
    columns: string[];
    rows: Record<string, any>[];
  };
  purpose: string;
  target_metrics: string[];
  dimensions: string[];
}

export interface FixVisualizationCodeResponse {
  code: string;
  explanation: string;
  library: string;
  prompt?: string;
}

export interface UpdateAnalysisVariableRequest {
  message_id: string;
  name: string;
  value: string;
}

export interface UpdateVisualizationCodeRequest {
  message_id: string;
  analysis_id: string;
  code: string;
  library: string;
}

interface GetMessageResponse {
  message: string;
  analysis_options?: Record<string, any>;
}

export interface ModifyAnalysisRequest {
  message_id: string;
  analysis_id: string;
  query: string;
  message?: string;
  analysis_options?: Record<string, any>;
  current_analysis: any;
  other_analyses: any[];
}

export interface ModifyAnalysisResponse {
  analysis: any;
  debug?: {
    prompt?: string;
    response?: string;
    cost?: number;
  };
}

export interface AddAnalysisRequest {
  message_id: string;
  query: string;
  message?: string;
  analysis_options?: Record<string, any>;
  existing_analyses: any[];
}

export interface AddAnalysisResponse {
  analysis: any;
  debug?: {
    prompt?: string;
    response?: string;
    cost?: number;
  };
}

export interface GenerateDiscussionRequest {
  message_id: string;
  analysis_id: string;
  sql_result: {
    columns: string[];
    rows: Record<string, any>[];
    scan_bytes: number;
    execution_time: number;
    is_cached: boolean;
  };
}

export interface GenerateDiscussionResponse {
  discussion: string;
  explanation: string;
  prompt?: string;
}

export interface FixDiscussionRequest {
  message_id: string;
  analysis_id: string;
  discussion: string;
  fix_request: string;
  sql_result: {
    columns: string[];
    rows: Record<string, any>[];
  };
}

export interface FixDiscussionResponse {
  discussion: string;
  explanation: string;
  prompt?: string;
}

export interface UpdateDiscussionRequest {
  message_id: string;
  analysis_id: string;
  discussion: string;
}

export interface GetDiscussionResponse {
  discussion: string;
  explanation: string;
  prompt?: string;
}

export const analysisService = {
  async getOrCreateMessageId(message: string): Promise<string> {
    const response = await api.post<{ message_id: string }>(
      '/analysis/messages',
      { message },
    );
    return response.data.message_id;
  },

  async searchAnalysisTemplates(
    message_id: string,
    model_name?: string,
    skip_cache?: boolean,
  ): Promise<SearchAnalysisTemplatesResponse> {
    const response = await api.post<SearchAnalysisTemplatesResponse>(
      '/analysis/templates/search',
      { message_id, model_name, skip_cache },
    );
    return response.data;
  },

  async searchTemplatesFromAnalysis(
    req: SearchTemplatesFromAnalysisRequest,
  ): Promise<SearchAnalysisTemplatesResponse> {
    const response = await api.post<SearchAnalysisTemplatesResponse>(
      '/analysis/templates/search-from-analysis',
      req,
    );
    return response.data;
  },

  async generateSqlFromAnalysis(
    req: GenerateSqlFromAnalysisRequest,
  ): Promise<GenerateSqlFromAnalysisResponse> {
    const response = await api.post<GenerateSqlFromAnalysisResponse>(
      '/analysis/sql/generate-from-analysis',
      req,
    );
    return response.data;
  },

  async searchAnalysisReports(
    message_id: string,
    model_name?: string,
    skip_cache?: boolean,
  ): Promise<SearchAnalysisReportsResponse> {
    const response = await api.post<SearchAnalysisReportsResponse>(
      '/analysis/reports/search',
      { message_id, model_name, skip_cache },
    );
    return response.data;
  },

  async getAnalyticsTables(): Promise<AnalyticsTable[]> {
    const response =
      await api.get<GetAnalyticsTablesResponse>('/analysis/tables');
    return response.data.tables;
  },

  async getSearchHistory(): Promise<SearchHistoryItem[]> {
    const response = await api.get<GetSearchHistoryResponse>(
      '/analysis/search-history',
    );
    return response.data.history;
  },

  async getAnalysisDetails(
    req: GetAnalysisDetailsRequest,
  ): Promise<GetAnalysisDetailsResponse> {
    const response = await api.post<GetAnalysisDetailsResponse>(
      '/analysis/details',
      req,
    );
    return response.data;
  },

  async getAnalysisVariables(message_id: string): Promise<AnalysisVariable[]> {
    const response = await api.get<GetAnalysisVariablesResponse>(
      `/analysis/variables?message_id=${encodeURIComponent(message_id)}`,
    );
    return response.data.variables;
  },

  async saveAnalysisVariable(
    req: SaveAnalysisVariableRequest,
  ): Promise<AnalysisVariable> {
    const response = await api.post<AnalysisVariable>(
      '/analysis/variables',
      req,
    );
    return response.data;
  },

  async deleteAnalysisVariable(
    req: DeleteAnalysisVariableRequest,
  ): Promise<void> {
    await api.delete('/analysis/variables', { data: req });
  },

  async updateGeneratedSql(req: UpdateGeneratedSqlRequest): Promise<void> {
    await api.put('/analysis/sql/update', req);
  },

  async checkSql(req: CheckSqlRequest): Promise<CheckSqlResponse> {
    const response = await api.post<CheckSqlResponse>(
      '/analysis/sql/check',
      req,
    );
    return response.data;
  },

  async fixSql(req: FixSqlRequest): Promise<FixSqlResponse> {
    const response = await api.post<FixSqlResponse>('/analysis/sql/fix', req);
    return response.data;
  },

  async executeSql(req: ExecuteSqlRequest): Promise<ExecuteSqlResponse> {
    const response = await api.post<ExecuteSqlResponse>(
      '/analysis/sql/execute',
      req,
    );
    return response.data;
  },

  async generateVisualizationCode(
    req: GenerateVisualizationCodeRequest,
  ): Promise<GenerateVisualizationCodeResponse> {
    const response = await api.post<GenerateVisualizationCodeResponse>(
      '/analysis/visualization/generate',
      req,
    );
    return response.data;
  },

  async fixVisualizationCode(
    req: FixVisualizationCodeRequest,
  ): Promise<FixVisualizationCodeResponse> {
    const response = await api.post<FixVisualizationCodeResponse>(
      '/analysis/visualization/fix',
      req,
    );
    return response.data;
  },

  async updateVisualizationCode(
    req: UpdateVisualizationCodeRequest,
  ): Promise<void> {
    await api.put('/analysis/visualization/update', req);
  },

  async updateAnalysisVariable(
    req: UpdateAnalysisVariableRequest,
  ): Promise<AnalysisVariable> {
    const response = await api.put<AnalysisVariable>(
      '/analysis/variables',
      req,
    );
    return response.data;
  },

  async getMessage(message_id: string): Promise<GetMessageResponse> {
    const response = await api.get<GetMessageResponse>(
      `/analysis/messages/${encodeURIComponent(message_id)}`,
    );
    return response.data;
  },

  /**
   * メッセージIDを取得または生成
   */
  async createMessage(
    message: string,
    analysisOptions?: Record<string, any>,
    skipCache: boolean = false,
  ): Promise<string> {
    const response = await api.post<{ message_id: string }>(
      '/analysis/messages',
      {
        message,
        analysis_options: analysisOptions,
        skip_cache: skipCache,
      },
    );
    return response.data.message_id;
  },

  async modifyAnalysis(
    req: ModifyAnalysisRequest,
  ): Promise<ModifyAnalysisResponse> {
    const response = await api.post<ModifyAnalysisResponse>(
      '/analysis/modify',
      req,
    );
    return response.data;
  },

  async addAnalysis(req: AddAnalysisRequest): Promise<AddAnalysisResponse> {
    const response = await api.post<AddAnalysisResponse>('/analysis/add', req);
    return response.data;
  },

  async generateDiscussion(
    req: GenerateDiscussionRequest,
  ): Promise<GenerateDiscussionResponse> {
    const response = await api.post<GenerateDiscussionResponse>(
      '/analysis/discussion/generate',
      req,
    );
    return response.data;
  },

  async fixDiscussion(
    req: FixDiscussionRequest,
  ): Promise<FixDiscussionResponse> {
    const response = await api.post<FixDiscussionResponse>(
      '/analysis/discussion/fix',
      req,
    );
    return response.data;
  },

  async updateDiscussion(req: UpdateDiscussionRequest): Promise<void> {
    await api.put('/analysis/discussion/update', req);
  },

  async getDiscussion(
    message_id: string,
    analysis_id: string,
  ): Promise<GetDiscussionResponse> {
    const response = await api.get<GetDiscussionResponse>(
      `/analysis/discussion?message_id=${encodeURIComponent(message_id)}&analysis_id=${encodeURIComponent(analysis_id)}`,
    );
    return response.data;
  },
};
