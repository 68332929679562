import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CodeIcon from '@mui/icons-material/Code';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useClient } from '../../store/client.store';
import type { AnalysisVariable } from './types';

interface AnalysisVariablesProps {
  message_id: string;
  variables: AnalysisVariable[];
  isLoading: boolean;
  onSave: (name: string, value: string) => void;
  onUpdate: (name: string, value: string) => void;
  onDelete: (name: string) => void;
}

export const AnalysisVariables: React.FC<AnalysisVariablesProps> = ({
  message_id,
  variables,
  isLoading,
  onSave,
  onUpdate,
  onDelete,
}) => {
  if (!message_id) return null;

  const [newVariableName, setNewVariableName] = useState('');
  const [newVariableValue, setNewVariableValue] = useState('');
  const [editingVariable, setEditingVariable] = useState<string | null>(null);
  const [editValue, setEditValue] = useState('');
  const { client } = useClient();
  const [hasInitialized, setHasInitialized] = useState(false);

  // message_idが変わったときにclientがあるかチェック
  useEffect(() => {
    if (isLoading || hasInitialized || !variables) return;

    if (client.selectedClient && message_id) {
      const hasClientVariable = variables.some((v) => v.name === 'client');
      if (!hasClientVariable) {
        const defaultClientId = client.selectedClient.clientId.split('-')[0];
        onSave('client', defaultClientId);
      }
      setHasInitialized(true);
    }
  }, [
    message_id,
    client.selectedClient,
    onSave,
    variables,
    isLoading,
    hasInitialized,
  ]);

  const handleAddVariable = () => {
    if (!newVariableName.trim() || !newVariableValue.trim()) return;
    onSave(newVariableName.trim(), newVariableValue.trim());
    setNewVariableName('');
    setNewVariableValue('');
  };

  const handleDeleteVariable = (name: string) => {
    onDelete(name);
  };

  const handleStartEdit = (variable: AnalysisVariable) => {
    setEditingVariable(variable.name);
    setEditValue(variable.value);
  };

  const handleCancelEdit = () => {
    setEditingVariable(null);
    setEditValue('');
  };

  const handleSaveEdit = (name: string) => {
    if (!editValue.trim()) return;
    onUpdate(name, editValue.trim());
    setEditingVariable(null);
    setEditValue('');
  };

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <CodeIcon sx={{ color: 'primary.main', fontSize: 28 }} />
        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 700 }}>
          Jinja変数の設定
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            size="small"
            label="変数名"
            value={newVariableName}
            onChange={(e) => setNewVariableName(e.target.value)}
            placeholder="例: client"
            disabled={isLoading}
          />
          <TextField
            size="small"
            label="値"
            value={newVariableValue}
            onChange={(e) => setNewVariableValue(e.target.value)}
            placeholder="例: tokyobase"
            disabled={isLoading}
          />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddVariable}
            disabled={
              !newVariableName.trim() || !newVariableValue.trim() || isLoading
            }
          >
            追加
          </Button>
        </Box>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>変数名</TableCell>
                <TableCell>値</TableCell>
                <TableCell align="right">操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variables.map((variable: AnalysisVariable) => (
                <TableRow key={variable.key}>
                  <TableCell>{variable.name}</TableCell>
                  <TableCell>
                    {editingVariable === variable.name ? (
                      <TextField
                        size="small"
                        fullWidth
                        value={editValue}
                        onChange={(e) => setEditValue(e.target.value)}
                      />
                    ) : (
                      variable.value
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                      }}
                    >
                      {editingVariable === variable.name ? (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => handleSaveEdit(variable.name)}
                            color="primary"
                          >
                            <SaveIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={handleCancelEdit}
                            color="default"
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => handleStartEdit(variable)}
                            color="primary"
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteVariable(variable.name)}
                            color="error"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};
