import Editor from '@monaco-editor/react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SaveIcon from '@mui/icons-material/Save';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { analysisService } from '../../services/analysis-service';
import type { AnalysisVariable } from './types';

interface SqlEditorProps {
  sql: string;
  analysisId: string;
  message_id: string;
  templateIds: string[];
  analysis: {
    purpose: string;
    target_metrics: string[];
    dimensions: string[];
  };
  onSave?: () => void;
  onExecutionResult?: (result: {
    columns: string[];
    rows: Record<string, any>[];
    scan_bytes: number;
    execution_time: number;
    is_cached: boolean;
  }) => void;
  variables: AnalysisVariable[];
}

export const formatBytes = (bytes?: number) => {
  if (!bytes) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export const SqlEditor: React.FC<SqlEditorProps> = ({
  sql,
  analysisId,
  message_id,
  templateIds,
  onSave,
  onExecutionResult,
  variables,
}) => {
  const [value, setValue] = useState(sql || '');
  const [isModified, setIsModified] = useState(false);
  const [fixRequest, setFixRequest] = useState('');
  const [lastFixPrompt, setLastFixPrompt] = useState('');
  const [executionResult, setExecutionResult] = useState<{
    columns: string[];
    rows: Record<string, any>[];
    scan_bytes: number;
    execution_time: number;
    is_cached: boolean;
  } | null>(null);

  useEffect(() => {
    setValue(sql || '');
    setIsModified(false);
  }, [sql]);

  const { mutate: saveSql, isPending: isSaving } = useMutation({
    mutationFn: analysisService.updateGeneratedSql,
    onSuccess: () => {
      setIsModified(false);
      onSave?.();
    },
  });

  const {
    mutate: checkSql,
    data: checkResult,
    isPending: isChecking,
  } = useMutation({
    mutationFn: analysisService.checkSql,
  });

  const { mutate: fixSql, isPending: isFixing } = useMutation({
    mutationFn: analysisService.fixSql,
    onSuccess: (data) => {
      setValue(data.sql);
      setIsModified(true);
      setFixRequest('');
      setLastFixPrompt(data.prompt);
    },
  });

  const { mutate: executeSql, isPending: isExecuting } = useMutation({
    mutationFn: analysisService.executeSql,
    onSuccess: (data) => {
      setExecutionResult(data);
    },
  });

  // 初回ロード時に自動チェック
  useEffect(() => {
    if (value && value.trim()) {
      handleCheck();
    }
  }, [value]);

  const handleSave = () => {
    if (!message_id) return;
    saveSql({
      message_id,
      analysis_id: analysisId,
      sql: value,
    });
  };

  const handleCheck = () => {
    // 変数をオブジェクトに変換
    const variableMap = variables.reduce(
      (acc, v) => {
        acc[v.name] = v.value;
        return acc;
      },
      {} as { [key: string]: string },
    );

    checkSql({
      sql: value,
      variables: variableMap,
    });
  };

  const handleFix = (customRequest?: string) => {
    const request = customRequest || fixRequest.trim();
    if (!request) return;
    fixSql({
      sql: value,
      error_message: checkResult?.error_message,
      fix_request: request,
      message_id,
      analysis_id: analysisId,
      template_ids: templateIds,
    });
  };

  const handleCopyPrompt = () => {
    if (lastFixPrompt) {
      navigator.clipboard.writeText(lastFixPrompt);
    }
  };

  const handleExecute = () => {
    try {
      // 変数をオブジェクトに変換
      const variableMap =
        variables?.reduce(
          (acc, v) => {
            acc[v.name] = v.value;
            return acc;
          },
          {} as { [key: string]: string },
        ) || {};

      executeSql({
        message_id,
        analysis_id: analysisId,
        sql: value,
        variables: variableMap,
        skip_cache: true,
      });
    } catch (error) {
      console.error('SQL実行の準備中にエラーが発生しました:', error);
    }
  };

  useEffect(() => {
    if (executionResult && onExecutionResult) {
      onExecutionResult(executionResult);
    }
  }, [executionResult, onExecutionResult]);

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          SQL {isModified && '※'}
        </Typography>
      </Box>
      <Editor
        height="600px"
        defaultLanguage="sql"
        value={value}
        onChange={(newValue) => {
          setValue(newValue || '');
          setIsModified(true);
        }}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          wordWrap: 'on',
          wrappingIndent: 'indent',
        }}
      />
      <Stack
        direction="row"
        spacing={2}
        sx={{ mt: 2, mb: 2, justifyContent: 'flex-end' }}
      >
        <Button
          variant="contained"
          startIcon={<CheckCircleIcon />}
          onClick={handleCheck}
          disabled={isChecking}
          color="primary"
          sx={{ minWidth: '140px', color: 'white' }}
        >
          {isChecking ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1, color: 'white' }} />
              チェック中...
            </>
          ) : (
            'SQLをチェック'
          )}
        </Button>
        <Button
          variant="contained"
          startIcon={<PlayArrowIcon />}
          onClick={handleExecute}
          disabled={isExecuting}
          color="primary"
          sx={{ minWidth: '140px', color: 'white' }}
        >
          {isExecuting ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1, color: 'white' }} />
              実行中...
            </>
          ) : (
            'SQLを実行'
          )}
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          disabled={!isModified || isSaving}
          color="primary"
          sx={{ minWidth: '140px', color: 'white' }}
        >
          {isSaving ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1, color: 'white' }} />
              保存中...
            </>
          ) : (
            'SQLを保存'
          )}
        </Button>
      </Stack>
      {checkResult && (
        <Box sx={{ mt: 2 }}>
          {checkResult.is_valid ? (
            <Alert severity="success">
              SQLは有効です。スキャン量: {formatBytes(checkResult.scan_bytes)}
            </Alert>
          ) : (
            <Alert
              severity="error"
              action={
                <IconButton
                  size="small"
                  onClick={() => handleFix('エラーを修正してください。')}
                  disabled={isFixing}
                  title="エラーを自動修正"
                >
                  {isFixing ? (
                    <CircularProgress size={20} />
                  ) : (
                    <AutoFixHighIcon />
                  )}
                </IconButton>
              }
            >
              <Stack spacing={1}>
                <Typography>SQLエラー: {checkResult.error_message}</Typography>
                <Typography variant="caption" color="text.secondary">
                  修正ボタンをクリックすると自動修正を試みます
                </Typography>
              </Stack>
            </Alert>
          )}
        </Box>
      )}
      <Box sx={{ mt: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <TextField
          fullWidth
          size="small"
          placeholder="カスタムの修正内容を入力してください"
          value={fixRequest}
          onChange={(e) => setFixRequest(e.target.value)}
          disabled={isFixing}
        />
        <IconButton
          onClick={() => handleFix()}
          disabled={!fixRequest.trim() || isFixing}
          color="primary"
          title="SQLを修正"
        >
          {isFixing ? <CircularProgress size={24} /> : <AutoFixHighIcon />}
        </IconButton>
      </Box>
      {lastFixPrompt && (
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>最後の修正プロンプト</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ position: 'relative' }}>
              <Button
                variant="outlined"
                size="small"
                startIcon={<ContentCopyIcon />}
                onClick={handleCopyPrompt}
                sx={{ position: 'absolute', top: 8, right: 8 }}
              >
                コピー
              </Button>
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  backgroundColor: '#f5f5f5',
                  padding: '1rem',
                  borderRadius: '4px',
                  margin: 0,
                  fontSize: '0.875rem',
                  maxHeight: '400px',
                  overflow: 'auto',
                }}
              >
                {lastFixPrompt}
              </pre>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {executionResult && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="info">
            <Stack spacing={1}>
              <Typography>
                実行時間: {executionResult.execution_time.toFixed(2)}秒
              </Typography>
              <Typography>
                スキャン量: {formatBytes(executionResult.scan_bytes)}
              </Typography>
              {executionResult.is_cached && (
                <Typography>※ キャッシュされた結果です</Typography>
              )}
            </Stack>
          </Alert>
          <TableContainer sx={{ mt: 2, maxHeight: 400 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {executionResult.columns.map((column) => (
                    <TableCell key={column}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {executionResult.rows.map((row, i) => (
                  <TableRow key={i}>
                    {executionResult.columns.map((column) => (
                      <TableCell key={column}>
                        {JSON.stringify(row[column])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Paper>
  );
};
