import { Alert, Skeleton, Stack } from '@mui/material';
import React from 'react';
import type { SearchAnalysisReportsResponse } from '../../services/analysis-service';
import { ReportCard } from './ReportCard';
import { SearchDebugInfo } from './SearchDebugInfo';

interface ReportSearchResultsProps {
  data?: SearchAnalysisReportsResponse;
  isLoading: boolean;
  error: unknown;
}

export const ReportSearchResults: React.FC<ReportSearchResultsProps> = ({
  data,
  isLoading,
  error,
}) => {
  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 4 }}>
        レポートの検索中にエラーが発生しました
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <Stack spacing={2}>
        {[...Array(2)].map((_, i) => (
          <Skeleton key={i} variant="rectangular" height={200} />
        ))}
      </Stack>
    );
  }

  if (!data?.reports.length) {
    return (
      <Alert severity="info">該当する分析レポートが見つかりませんでした</Alert>
    );
  }

  return (
    <Stack spacing={2}>
      {data.reports.map((report) => (
        <ReportCard key={report.report_id} report={report} />
      ))}
      <SearchDebugInfo
        prompt={data.debug?.prompt}
        response={data.debug?.response}
        error={data.debug?.error}
      />
    </Stack>
  );
};
