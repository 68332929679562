export interface PromptTemplate {
  id: string;
  name: string;
  template: string;
}

export const PROMPT_TEMPLATES: PromptTemplate[] = [
  {
    id: 'default',
    name: 'プロンプト',
    template: `CONTEXTの内容を参考に、以下の指示に従って回答を作成してください。

# 命令

# 注意事項

# CONTEXT
{{CONTEXT}}
`,
  },
];
